import * as React from "react";
import {memo} from "react";
import {useState} from "react";
import CurrencyText from "../../controls/CurrencyText";
import PercentText from "../../controls/PercentText";
import Qnt from "./Qnt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { TreePicker, Dropdown } from 'rsuite';
import TextareaAutosize from 'react-textarea-autosize';
import List from "../../controls/List";

const OutSubline = memo(function OutSubline({parentLine, item, editing, text, index, outCurrency, amount, vat, analyticsCalced, 
        amountTotal, collapseOut, qnt_multiplicator, checkFailed, options, accuracyList}) 
{
    const [treeOpened, setTreeOpened] = useState(false);

    const onChangeItem = (e) => {
        window.onChangeSublineData(parentLine, item, 'item', e.target.value);
    }

    const onChangeAmount = (val) => {
        console.log('from out amount');
        window.onChangeSublineData(parentLine, item, 'amount', val);
    }

    const onChangeTotal = (val) => {
        if(qnt_multiplicator != 0 )
        {
            let amount = val / qnt_multiplicator;
            console.log('from out total');
            window.onChangeSublineData(parentLine, item, 'amount', amount);
        }
    }

    const onChangeVAT = (val) => {
        window.onChangeSublineData(parentLine, item, 'vat', val);
    }

    const onChangeQnt = (val, sum) => {
        window.onChangeSublineData(parentLine, item, 'qnt_multiplicator', sum);
        window.onChangeSublineData(parentLine, item, 'qnt_formula', val);
    }
    
    const toggleTree = () => {
        setTreeOpened(!treeOpened);
    }

    const onChangeEinv = (v) => {
        if(!(v+'').startsWith('x'))
        {
            let vId = v * 1;
            let invItem = window.window.einvoiceList.filter((x) => x.id == vId);
            if(invItem.length>0)
            {
                window.onChangeSublineData(parentLine, item, 'inv', invItem[0].code);
                window.onChangeSublineData(parentLine, item, 'mdg_estimate_invoices_id', vId);
                setTreeOpened(false);
            }
        }
    }

    const onChangeAmountAccuracy = (val) => {
        window.onChangeSublineData(parentLine, item, 'options', {...options, amount_accuracy: val});
    }

    const onChangeSubtotalAccuracy = (val) => {
        window.onChangeSublineData(parentLine, item, 'options', {...options, subtotal_accuracy: val});
    }

    const inv = item.inv ?? "\u00A0\u00A0\u00A0\u00A0";

    const renderAccAmount = (props, ref) => {
        return (
            <FontAwesomeIcon {...props} ref={ref} icon={faPen} />
        );
      };
      
    return <>
    {!collapseOut && <>
    <td className="out">{item.id}
    {editing &&  <>
        <Dropdown renderToggle={renderAccAmount}>
            <Dropdown.Item>Точность цены
                <List options={accuracyList} value={options.amount_accuracy ?? 2}
                                onChangedItem={(v, n) => {onChangeAmountAccuracy(v)}}/>
            </Dropdown.Item>
            <Dropdown.Item>Точность суммы
                <List options={accuracyList} value={options.subtotal_accuracy ?? 2}
                                 onChangedItem={(v, n) => {onChangeSubtotalAccuracy(v)}}/>
            </Dropdown.Item>
        </Dropdown>
    </>}
    </td>
    <td className="out">
        {editing &&  <>
            <span onClick={toggleTree} className={"tree-picker-toggle" + (checkFailed && (item.mdg_estimate_invoices_id ?? '') == ''  ? " error" : "")}>{inv}</span>
            <TreePicker defaultExpandAll={false} size="xs" cleanable={false} plaintext={true}
             data={window.einvTree ?? []} value={item.mdg_estimate_invoices_id ?? null} 
             menuClassName="tree-menu-class"
             open={treeOpened} onChange={onChangeEinv} />
        </>}
        {!editing && <>{item.inv}</>}
    </td>
    </>}
    <td className="out item-title">
        {editing && <TextareaAutosize value={text} onChange={onChangeItem} className={(checkFailed && (text ?? '') == ''  ? " error" : "")} />}

        {!editing && <>{text}</>}
    </td>
    {!collapseOut && <>
    <td className="out qnt">
        <Qnt item={item.qnt_formula} index={index} editing={editing} onChange={onChangeQnt} />
    </td>
    <td className="out text-end">
        <CurrencyText val={amount} type={(editing ? 'input' : 'text')} accuracy={options.amount_accuracy}
         currency={outCurrency} onChange={onChangeAmount} />
    </td>
    </>}
    <td className="out text-end">
        <CurrencyText val={amountTotal} type={(editing ? 'input' : 'text')}  accuracy={options.subtotal_accuracy}
        onChange={onChangeTotal} currency={outCurrency} />
    </td>
    {!collapseOut && <>
    <td className="out text-end">
        <PercentText val={vat} type={(editing ? 'input' : 'text')} onChange={onChangeVAT} />
    </td>
    <td className="out text-end"><CurrencyText val={item.amountVAT} currency={outCurrency} /></td>
    <td className="out text-end"><CurrencyText val={item.outAK} currency={outCurrency} /></td>
    <td className="out text-end"><CurrencyText val={item.outAKVat} currency={outCurrency} /></td>
    <td className="out">
        <FontAwesomeIcon icon={faComment} />
    </td>
    </>}
    </>
    ;
}
);
export default OutSubline;
