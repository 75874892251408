import * as React from "react";
import {useState, useEffect} from "react";
import DocList from "../../components/list/DocList";
import Http from "../../utils/Http.js";
import i18n from "../../utils/Locale";

export default function ProjectsList(props) {
  const [loadingData, setLoadingData] = useState(false);
    const http = Http.GetInstance();

    const [rows, setRows] = useState([]);
    const fields = [
            {name: 'id', caption: i18n.t('№')}, 
            {name: 'status_name', caption: i18n.t('Статус')},
            {name: 'step_exceeding', caption: i18n.t('В ожидании')},
            {name: 'name', caption: i18n.t('Наименование')},
            {name: 'client_name', caption: i18n.t('Клиент')},
            {name: 'brand_name', caption: i18n.t('Бренд')},
            {name: 'manager_full_name', caption: i18n.t('Менеджер')},
            {name: 'period', caption: i18n.t('Срок действия')},
        ];

    const filters = [
          {name: 'Поиск', type: 'search'},
          {name: 'Все статусы', type: 'list', listId: 'status_name', listName: 'status_name', options: []},
          {name: 'From', type: 'period', fieldName: 'creationdate'}
        ];

    const getProjectsList = () => {
      if(!loadingData)
      {
        setLoadingData(true);
        http.GetProjectsList((resp) => {
          if(resp.data.errorcode !== "00")
          {
            alert("Error!");
          } else {
            let list = resp.data.projectList.list ?? [];
            let r = list.map((item) => {
              let from = new Date(item['startdate']);
              let to = new Date(item['enddate']);
              item['period'] = from.toLocaleDateString()+" - "+to.toLocaleDateString();
              return item;
            });
            setRows(r);
          }
          setLoadingData(false);
        });
      }
    }

    useEffect(() => {
      getProjectsList();
    }, []);
    
    return (<>
        <DocList name={i18n.t('Проекты')} hasRefresh="true" button_name={i18n.t('Новый проект')}
         fields={fields} rows={rows} filters={filters} docPrefixLink={"/projects/"}
         onRefresh={getProjectsList}
         loading={loadingData}
         />
    </>
    );
}