import * as React from "react";
import { NumericFormat } from 'react-number-format';

export default function PercentText(props) {

    const val = props.val;
    const accuracy = props.accuracy ?? 2;
    const itemType = props.type ?? 'text';

    const onChange = (e) => {
        const {formattedValue, value, floatValue} = e;
        if(props.onChange)
        {
            props.onChange(floatValue);
        }
    }
    return <NumericFormat value={val} thousandSeparator="'" displayType={itemType} allowNegative fixedDecimalScale={true} decimalScale={accuracy} suffix={' %'} onValueChange={onChange} className="prc-input" />;
}
