import * as React from "react";
import { memo} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Tooltip, Whisper } from 'rsuite';

const DeleteElementButton = memo(function DeleteElementButton({canDelete, onDelete}) {

    const tooltip = <Tooltip>{"Удаление запрещено, т.к. есть связанные расходы."}</Tooltip>;

    return <Whisper placement="top" trigger={canDelete ? "none" : "hover"} controlId={'control-id-remove-element'} speaker={tooltip}  >
        <FontAwesomeIcon icon={faTrashAlt} onClick={() => {
            if(canDelete)
            {
                onDelete();
            }
            }} />
    </Whisper>;
});

export default DeleteElementButton;
