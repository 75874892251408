import logo from './rms_logo.png';
import './App.scss';
import Header from './components/main/Header.js';
import 'bootstrap/dist/css/bootstrap.css';
import Menu from './components/main/Menu.js';
import Login from './forms/Login';
import NoMatch from './components/NoMatch';
import Test from './components/Test';
import { Routes, Route, useLocation, useNavigate, Location, NavigationType, useNavigationType } from "react-router-dom";
import { React, useEffect, useState } from 'react';
import AgreementsList from './documents/lists/AgreementsList';
import EstimatesList from './documents/lists/EstimatesList';
import ProjectsList from './documents/lists/ProjectsList';
import Project from './documents/documents/Project';
import Estimate from './documents/documents/Estimate';

import { Loader, Modal } from 'rsuite';

import i18n from './utils/Locale';
import Http from "./utils/Http.js";
// Images
import help from './resources/images/info-icon.svg';
import hugs from './resources/images/hugs.svg';
import admin from './resources/images/admin-icon.svg';
import chains from './resources/images/chains-icon.svg';

import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import Substitute from './utils/Substitute.js';
import MainPage from './documents/MainPage.js';

const themes = {
  light: '/css/theme_light.css',
  dark: '/css/theme_dark.css',
};

window.userReplaced = false;

export default function App() {

  const http = Http.GetInstance();
  const navigate = useNavigate();

  const [logged, setLogged] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);
  const [userData, setUserData] = useState({});
  const [menuVisible, setMenuVisible] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [currentLocale, setCurrentLocale] = useState("ru");
  const [reloadMenu, setReloadMenu] = useState(false);
  const [subst, setSubst] = useState(false);
  const [needConfirm, setNeedConfirm] = useState(false);

  const location = useLocation();

  const logOut = () => {
    setLogged(false);
    setReloadMenu(!reloadMenu);
  }

  const onLogin = (username, password) => {
    http.Login(username, password, (resp) => {
      if(resp.data.errorcode !== "00")
      {
        alert("Error!");
      } else {
        window.userReplaced = false;
        setLogged(true);
        loadUserData();
        navigate("/");
      }
    });
  }

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  }
  const changeLocale = (newLocale) => {
    i18n.locale = newLocale;
    setCurrentLocale(newLocale);
    setFirstLoad(true);
  }

  /*
  if(firstLoad && location.pathname != "" && location.pathname != "/")
  {
    let mIndex = -1;
    let subIndex = -1;
    for(var i=0;i<menu.length;i++)
    {
      if(location.pathname.indexOf(menu[i].link)>=0)
      {
        mIndex = i;
      }
      for(var j=0;j<menu[i].children.length;j++)
      {
        if(location.pathname.indexOf(menu[i].children[j].link)>=0)
        {
          mIndex = i;
          subIndex = j;
          break;
        }
      }
    }
    if(mIndex >=0 )
    {
      setFirstLoad(false);
      changeMenu(mIndex, subIndex);
    }
  }
  */

  const loadUserData = () => {
    http.UserInfo( (val) => {
      if(!val || val.data.errorcode !== "00")
      {
        setLogged(false);
      } else {
        setUserData(val.data.userDetails);
        window.user = val.data.userDetails;
        setLogged(true);
      }
      setAuthChecked(true);
    } );
  }

  useEffect(() => {
    i18n.locale = currentLocale;
    window.changed = false;
    http.CheckAuth((resp) => {
      if(resp.data.errorcode === "00")
      {
        loadUserData();
      } else {
        setLogged(false);
        setAuthChecked(true);
      }
    });
  }, []);

  const confirmation = (e) => {
    if(window.changed)
    {
      e.preventDefault();
      e.returnValue = "У вас есть несохраненные данные. Отменить все изменения?";
      setNeedConfirm(true);
      return "У вас есть несохраненные данные. Отменить все изменения?";
    }
}

useEffect(() => {
    const unloadCallback = (event) => {
      if(window.changed)
      {
        event.preventDefault();
        event.returnValue = "";
        return "";
      }
    };
    window.history.pushState(null, document.title, location.href);
    
    window.addEventListener("beforeunload", unloadCallback);
    window.addEventListener("popstate", confirmation);

    // router.subscribe((state) => console.log('new state', state));

    return () => {
        window.addEventListener("popstate", confirmation);
        window.removeEventListener("beforeunload", unloadCallback);
    }
  }, []);

  let substituted = (userData!==undefined && userData.real_user!=null || (window.userReplaced !== undefined && window.userReplaced)) ;

  if(substituted != subst)
  {
    setSubst(substituted);
  }

  useEffect(() => {
    if(subst)
    {
      loadUserData();
    }
  }, [subst]);

  return <>

    {needConfirm && <Modal open={needConfirm} onClose={() => {setNeedConfirm(false);}}>
        <Modal.Header>
          <Modal.Title>Отмена изменений</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        У вас есть несохраненные данные. Отменить все изменения?
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-dark" onClick={() => {
            window.changed = false;
            setNeedConfirm(false);
            }}>Да</button>&nbsp;&nbsp;&nbsp;
          <button type="button" className="btn btn-outline-dark" onClick={() => {setNeedConfirm(false);}}>Нет</button>
        </Modal.Footer>
    </Modal>}

    {!authChecked && <Loader backdrop content={i18n.t("Загрузка")} size="sm" speed="normal" vertical />}
    {(authChecked && !logged) && <Login onLogin={onLogin} isLoggedIn={logged} onLogout={logOut} /> }
    {(authChecked && logged) && 
      <>
    <ThemeSwitcherProvider defaultTheme="light" themeMap={themes}>
      <Header currentLocale={currentLocale} changeLocale={changeLocale} toggleMenuFunction={toggleMenu} onLogOut={logOut} userData={userData} />
      {substituted && <div className="substitution-warning"><div>Вы работаете в режиме замещения!</div></div>}
      <Menu menuVisible={menuVisible} selected={location.pathname} userData={userData} reloadMenu={reloadMenu} substituted={substituted} />
        <div className={"app-main" + (substituted ? " substituted" : "") + (menuVisible ? "" : " nomenu")}>
          <div className='app-main-content'>
            <Routes>
              <Route path="/login" element={<Login isLoggedIn={logged} />} />
              <Route path="/test" element={<Test />} />
              <Route path="/agreements" element={<AgreementsList />} />
              <Route path="/estimates" element={<EstimatesList />} />
              <Route path="/estimates/:id" element={<Estimate />} />
              <Route path="/projects" element={<ProjectsList />} />
              <Route path="/projects/:id" element={<Project />} />
              <Route path="/substitute/:id" element={<Substitute />} />
              <Route path="/info" element={<MainPage />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </div>
        </div>
        {/* <div className="d-flex flex-nowrap app-page">
      </div> */}
      </ThemeSwitcherProvider>
      </>
    }
    </>
}
