
class EstimateAnalytics {
    calcAnalyticsGroup(doc, group)
    {
        group.totalOut = 0;
        group.totalIn = 0;
        group.outVat = 0;
        group.inVat = 0;
        group.outAK = 0;
        group.outAKVat = 0;
        group.outAKRub = 0;
        group.outTotalRub = 0;
        group.costRub = 0;
        group.costVatRub = 0;
        group.reportsWithVatRub = 0;
        let projectMargin = doc.margin ?? 0;
        group.analyticsStatus = "";

        for(var j=0;j<group.subgroups.length;j++)
        {
            this.calcAnalyticsSubgroup(doc, group, group.subgroups[j]);
        }

        /* Analytics */
        group.outWithAK = group.outAKRub + group.outTotalRub;
        if(group.outWithAK == 0)
        {
            group.planPrc = 0;
        } else {
            group.planPrc = 100 * (group.outWithAK - group.totalIn) / (group.outWithAK);
        }
        group.plan = group.outWithAK - group.totalIn;

        if(group.outWithAK == 0)
        {
            group.factPrc = 0;
        } else {
            group.factPrc = 100 * (group.outWithAK - group.costRub) / (group.outWithAK);
        }
        group.fact = group.outWithAK - group.costRub;
        group.left = group.totalIn - group.costRub;
        group.leftVat = group.inVat - group.costVatRub;
        group.costWithVatRub = group.costRub + group.costVatRub;

        if(group.planPrc < 0 || group.costRub>group.inTotalRub)
        {
            group.analyticsStatus = "red";
        } else {
            if(group.planPrc < projectMargin)
            {
            group.analyticsStatus = "yellow";
            }
        }
        /* /Analytics */


        group.outTotalWithVAT = group.totalOut + group.outVat;
        group.inTotalWithVAT = group.totalIn + group.inVat;
    }

    calcAnalyticsSubgroup(doc, group, subgroup)
    {
        subgroup.totalOut = 0;
        subgroup.totalIn = 0;
        subgroup.outVat = 0;
        subgroup.inVat = 0;
        subgroup.outTotalWithVAT = 0;
        subgroup.inTotalWithVAT = 0;
        subgroup.outAK = 0;
        subgroup.outAKVat = 0;
        subgroup.outAKRub = 0;
        subgroup.outTotalRub = 0;

        subgroup.costRub = 0;
        subgroup.costVatRub = 0;
        subgroup.reportsWithVatRub = 0;
        let projectMargin = doc.margin ?? 0;
        subgroup.analyticsStatus = "";

        for(var l=0;l<subgroup.lines.length;l++)
        {
            this.calcAnalyticsLine(doc, group, subgroup, subgroup.lines[l]);
        }
        subgroup.outTotalWithVAT = subgroup.totalOut + subgroup.outVat;
        subgroup.inTotalWithVAT = subgroup.totalIn + subgroup.inVat;

        /* Analytics */
        subgroup.outWithAK = subgroup.outAKRub + subgroup.outTotalRub;
        if(subgroup.outWithAK == 0)
        {
            subgroup.planPrc = 0;
        } else {
            subgroup.planPrc = 100 * (subgroup.outWithAK - subgroup.totalIn) / (subgroup.outWithAK);
        }
        subgroup.plan = subgroup.outWithAK - subgroup.totalIn;

        if(subgroup.outWithAK == 0)
        {
            subgroup.factPrc = 0;
        } else {
            subgroup.factPrc = 100 * (subgroup.outWithAK - subgroup.costRub) / (subgroup.outWithAK);
        }
        subgroup.fact = subgroup.outWithAK - subgroup.costRub;
        subgroup.left = subgroup.totalIn - subgroup.costRub;
        subgroup.leftVat = subgroup.inVat - subgroup.costVatRub;
        subgroup.costWithVatRub = subgroup.costRub + subgroup.costVatRub;
        if(subgroup.planPrc < 0 || subgroup.costRub>subgroup.inTotalRub)
        {
            subgroup.analyticsStatus = "red";
        } else {
            if(subgroup.planPrc < projectMargin)
            {
            subgroup.analyticsStatus = "yellow";
            }
        }
        /* /Analytics */

        group.totalOut += subgroup.totalOut;
        group.outVat += subgroup.outVat;
        group.outAK += subgroup.outAK;
        group.outAKVat += subgroup.outAKVat;
        group.totalIn += subgroup.totalIn;
        group.inVat += subgroup.inVat;
        group.outTotalRub += subgroup.outTotalRub;
        group.outAKRub += subgroup.outAKRub;

        group.costRub += subgroup.costRub;
        group.costVatRub += subgroup.costVatRub;
        group.reportsWithVatRub += subgroup.reportsWithVatRub;
    }

    calcAnalyticsLine(doc, group, subgroup, line)
    {
        line.outTotal = 0;
        line.outVat = 0;
        line.outAK = 0;
        line.outAKVat = 0;
        line.outTotalRub = 0;
        line.outAKRub = 0;
        line.inTotalRub = 0;
        line.inVatRub = 0;
        line.plan = 0;
        line.planPrc = 0;
        line.fact = 0;
        line.factPrc = 0;
        line.left = 0;
        /* TODO: get real costs */
        line.costRub = 0;
        line.costVatRub = 0;
        line.costWithVatRub = 0;
        line.reportsWithVatRub = 0;
        let projectMargin = doc.margin ?? 0;
        line.analyticsStatus = "";

        for(var p=0;p<line.positions.length;p++)
        {
            let position = line.positions[p];
            if(position['out']!=undefined)
            {
                position.out.index = this.outIndex;
                this.outIndex++;

                let newOutTotal = ((position.out.amount ?? 0) * (position.out.qnt_multiplicator ?? 1));
                if(newOutTotal != position.out.amountTotal)
                {
                    position.out.amountTotal = newOutTotal;
                }
                position.out.amountVAT = (position.out.amountTotal * position.out.vat / 100);
                position.out.amountAndVAT = position.out.amountTotal + position.out.amountVat;

                position.out.outAK = position.out.amountTotal * (group.agency_rate ?? 0) / 100;
                position.out.outAKVat = position.out.outAK * (doc.af_tx ?? 0) / 100;

                subgroup.totalOut += position.out.amountTotal;
                subgroup.outVat += position.out.amountVAT;
                subgroup.outAK += position.out.outAK;
                subgroup.outAKVat += position.out.outAKVat;

                subgroup.outAKRub += position.out.outAK * doc.basecurrencyrate;
                subgroup.outTotalRub += position.out.amountTotal * doc.basecurrencyrate;

                line.outTotalRub += position.out.amountTotal * doc.basecurrencyrate;
                line.outAKRub += position.out.outAK * doc.basecurrencyrate;
                line.outTotal += position.out.amountTotal;
                line.outVat += position.out.amountVAT;
                line.outAK += position.out.outAK ;
                line.outAKVat += position.out.outAKVat;
            }
            if(position['in']!=undefined)
            {
                let newInTotal = ((position.in.amount ?? 0) * (position.in.qnt_multiplicator ?? 1));
                if(newInTotal != position.in.amountTotal)
                {
                    position.in.amountTotal = newInTotal;
                }
                position.in.amountVAT = (position.in.amountTotal * position.in.vat / 100);
                position.in.amountAndVAT = position.in.amountTotal + position.in.amountVAT;

                position.in.amountRub = position.in.amount * position.in.rate;
                position.in.amountTotalRub = position.in.amountTotal * position.in.rate;
                position.in.amountVATRub = position.in.amountVAT * position.in.rate;
                position.in.amountAndVATRub = position.in.amountAndVAT * position.in.rate;

                position.in.left = position.in.amountTotalRub - (position.in.rur_cost_amount ?? 0);
                position.in.leftVat = position.in.amountVATRub - ((position.in.rur_cost_amount_with_vat ?? 0) - (position.in.rur_cost_amount ?? 0));

                line.inTotalRub += position.in.amountTotalRub;
                line.inVatRub += position.in.amountVATRub;

                subgroup.totalIn += position.in.amountTotalRub;
                subgroup.inVat += position.in.amountVATRub;
                line.costRub += (position.in.rur_cost_amount ?? 0);
                line.costWithVatRub += (position.in.rur_cost_amount_with_vat ?? 0);
                line.costVatRub += ((position.in.rur_cost_amount_with_vat ?? 0) - (position.in.rur_cost_amount ?? 0));
                line.reportsWithVatRub += (position.in.rur_reported_cost_amount ?? 0);
            }
        }
        /* Analytics */
        line.outWithAK = line.outAKRub + line.outTotalRub;
        if(line.outWithAK == 0)
        {
            line.planPrc = 0;
        } else {
            line.planPrc = 100 * (line.outWithAK - line.inTotalRub) / (line.outWithAK);
        }
        line.plan = line.outWithAK - line.inTotalRub;

        if(line.outWithAK == 0)
        {
            line.factPrc = 0;
        } else {
            line.factPrc = 100 * (line.outWithAK - line.costRub) / (line.outWithAK);
        }
        line.fact = line.outWithAK - line.costRub;
        line.left = line.inTotalRub - line.costRub;
        line.leftVat = line.inVatRub - line.costVatRub;
        if(line.planPrc < 0 || line.costRub>line.inTotalRub)
        {
            line.analyticsStatus = "red";
        } else {
            if(line.planPrc < projectMargin)
            {
                line.analyticsStatus = "yellow";
            }
        }
        /* /Analytics */
        subgroup.costRub += line.costRub;
        subgroup.costVatRub += line.costVatRub;
        subgroup.reportsWithVatRub += line.reportsWithVatRub;
    }

    calcAnalytics(doc, docBody)
    {
        console.log('calc start: '+Date.now());
        this.outIndex = 1;

        doc.outTotal = 0;
        doc.outVat = 0;
        doc.outAK = 0;
        doc.outAKVat = 0;
        doc.inTotal = 0;
        doc.inVat = 0;
        doc.inTotalWithVAT = 0;
        doc.inTotalRub = 0;

        doc.outTotalRub = 0;
        doc.outAKRub = 0;
        doc.planPrc = 0;
        doc.plan = 0;
        doc.costRub = 0;
        doc.inVatRub = 0;
        doc.costVatRub = 0;
        doc.costWithVatRub = 0;
        doc.reportsWithVatRub = 0;

        let projectMargin = doc.margin ?? 0;
        doc.analyticsStatus = "";

        if(docBody.groups != undefined)
        {
            for(var i=0;i<docBody.groups.length;i++)
            {
            let group = docBody.groups[i];
            this.calcAnalyticsGroup(doc, group);

            doc.outTotal += group.totalOut;
            doc.outVat += group.outVat;
            doc.outAK += group.outAK;
            doc.outAKVat += group.outAKVat;
        
            doc.inTotalRub += group.totalIn;
            doc.outTotalRub += group.outTotalRub;
            doc.outAKRub += group.outAKRub;
        
            doc.inTotal += group.totalIn;
            doc.inVat += group.inVat;
            doc.costRub += group.costRub;
            doc.inVatRub += group.inVat;
            doc.costVatRub += group.costVatRub;
            doc.costWithVatRub += group.costWithVatRub;
            doc.reportsWithVatRub += group.reportsWithVatRub;
            }
        }
        doc.inTotalWithVAT = doc.inTotal + doc.inVat;

        doc.outWithAK = doc.outTotalRub + doc.outAKRub;
        if(doc.outWithAK == 0)
        {
            doc.planPrc = 0;
        } else {
            doc.planPrc = 100 * (doc.outWithAK - doc.inTotalRub) / (doc.outWithAK);
        }
        doc.plan = doc.outWithAK - doc.inTotalRub;

        if(doc.outWithAK == 0)
        {
            doc.factPrc = 0;
        } else {
            doc.factPrc = 100 * (doc.outWithAK - doc.costRub) / (doc.outWithAK);
        }
        doc.fact = doc.outWithAK - doc.costRub;

        doc.left = doc.inTotalRub - doc.costRub;
        doc.leftVat = doc.inVatRub - doc.costVatRub;

        if(doc.planPrc < 0)
        {
            doc.analyticsStatus = "red";
        } else {
            if(doc.planPrc < projectMargin) {
                doc.analyticsStatus = "yellow";
            }
        }
        console.log('calc stop: '+Date.now());
    }
}

let estimateAnalytics = new EstimateAnalytics();

export default estimateAnalytics;