import * as React from "react";
import { useId, useState } from "react";
import DatePicker from "react-datepicker";
import { DateRangePicker, CustomProvider } from 'rsuite';
import ru from 'date-fns/locale/ru';
import { registerLocale } from  "react-datepicker";
import DateRangeIcon from '@mui/icons-material/DateRange';
import arrow from "../../resources/images/picker-arrow.svg";
import calendar from "../../resources/images/picker-calendar.svg";
import infoIcon from "../../resources/images/info-icon.svg";

// import "react-datepicker/dist/react-datepicker.css";
import 'rsuite/dist/rsuite.min.css';
import { Image } from "react-bootstrap";
import ruRU from 'rsuite/locales/ru_RU';

export default function Period({value, onChangePeriod, extraClass, caption, info, mandatory,
                                disabled, hasUnder, under, underRed, from, to, hasClear})
{
    const id = useId();
    const spanId = id+'span';
    registerLocale('ru', ru);

    const { allowedRange } = DateRangePicker;

    const onChangePeriodLocal = (v) => {
        if(v!=null)
        {
            if(onChangePeriod)
            {
                onChangePeriod(v);
            }
        } else {
            if(onChangePeriod)
            {
                onChangePeriod([null, null]);
            }
        }
    }

    return <div className={'text-input '+(extraClass ?? '')}>
            <label htmlFor={id} className="form-label input-caption">{caption}
                {info && <img src={infoIcon} alt="" className="mx-1 mb-2" />}
                {mandatory && <span className="text-danger">*</span>}
            </label>
            <CustomProvider locale={ruRU}>
                <DateRangePicker character=" -> " 
                    format="dd.MM.yyyy"
                    isoWeek={true}
                    value={value ?? null}
                    onChange={onChangePeriodLocal}
                    onClean={() => {onChangePeriodLocal(null)}}
                    placement={'bottomEnd'}
                    placeholder={'дд.мм.гггг -> дд.мм.гггг'}
                    disabled={disabled}
                    editable={!disabled}
                    cleanable={((hasClear ?? true) && (value ?? null) != null && value[0]!=null)}
                    shouldDisableDate={allowedRange(from ?? '01.01.1900', to ?? '01.01.3000')}
                />
            </CustomProvider>
            {((hasUnder ?? false) || (under ?? "") != "") &&
                <label htmlFor={id} className={"form-label input-under"+(underRed ? " text-danger": " text-secondary")}>{under}</label>
            }
        </div>;
}
