import * as React from "react";
import { useId, useState } from "react";
import { CustomProvider, DatePicker } from 'rsuite';
import ru from 'date-fns/locale/ru';
import { registerLocale } from  "react-datepicker";
import DateRangeIcon from '@mui/icons-material/DateRange';
import arrow from "../../resources/images/picker-arrow.svg";
import calendar from "../../resources/images/picker-calendar.svg";
import infoIcon from "../../resources/images/info-icon.svg";

// import "react-datepicker/dist/react-datepicker.css";
import 'rsuite/dist/rsuite.min.css';
import { Image } from "react-bootstrap";
import ruRU from 'rsuite/locales/ru_RU';

export default function DateDropDown(props) {
    const id = useId();
    const spanId = id+'span';
    const disabled = props.disabled ?? false;
    // const [dateValue, setDateValue] = useState(props.value ?? null);
    registerLocale('ru', ru);

    const onChange = (v) => {
        if(v!=null)
        {
            // setDateValue(v);
            if(props.onChange)
            {
                props.onChange(v);
            }
        } else {
            // setDateValue(null);
            if(props.onChange)
            {
                props.onChange(null);
            }
        }
    }

    return <div className={'text-input '+(props.extraClass ?? '')}>
            {props.caption!=null && props.caption != "" && <label htmlFor={id} className="form-label input-caption">{props.caption}
                {props.info && <img src={infoIcon} alt="" className="mx-1 mb-2" />}
                {props.mandatory && <span className="text-danger">*</span>}
            </label>}
    
            <CustomProvider locale={ruRU}>
                <DatePicker editable={false} character=" -> " 
                    format="dd.MM.yyyy"
                    isoWeek={true}
                    value={props.value ?? null}
                    onChange={onChange}
                    onClean={() => {onChange(null)}}
                    placement={'bottomEnd'}
                    placeholder={'дд.мм.гггг'}
                    disabled={disabled}
                    cleanable={(props.cleanable ?? true)}
                />
            </CustomProvider>
            {((props.hasUnder ?? false) || (props.under ?? "") != "") &&
                <label htmlFor={id} className={"form-label input-under"+(props.underRed ? " text-danger": " text-secondary")}>{props.under}</label>
            }
        </div>;

/*
    return <DateRangePicker editable={false} character="<a>-</a>" caretAs={calendar} value={[startDate, endDate]} 
                    onChange={(v) => onChangePeriod(v[0], v[1]) }
                    />;

*/

                    /*
    return (
<div className="d-flex input-daterange">
<div className="form-control" style={{height: "38px", display: "flex"}}>
    <DatePicker selected={startDate} placeholder="дд.мм.гггг"
            onChange={(date) => onChangePeriod(date, null)} 
            locale="ru" dateFormat="dd.MM.yyyy" />
    <div className="input-group-addon"><img src={arrow} /></div>
    <DatePicker selected={endDate} placeholder="дд.мм.гггг"
            onChange={(date) => onChangePeriod(null, date)} 
            locale="ru" dateFormat="dd.MM.yyyy" className="dateTo" />
    <img src={calendar} />
</div>
</div>
    );
    */
}

