import * as React from "react";
import {useState, useEffect} from "react";
import i18n from "../../utils/Locale.js";
import Http from "../../utils/Http.js";
import Tabs from "../list/Tabs.js";
import '../../DocumentSigns.scss';

export default function DocumentSignsHistory(props) {
    const items = props.items ?? [];
    const documentId = props.documentId ?? '';
    const http = Http.GetInstance();

    return <div className="d-flex flex-column">
        {items.map((item,i) => {
            let dt = new Date(item.date);
            return <div key={"dochs"+i} className="d-flex flex-row signs-history-row">
                <div>
                    {dt.toLocaleDateString()+" "+dt.toLocaleTimeString()}</div>
                <div className="align-self-stretch">
                    <div className="signs-circle"></div>
                    <div className={i==0 ? "signs-line-first" : "signs-line"}>&nbsp;</div>
                </div>
                <div>{item.typename} / {item.statusname}<br /> <span className="signs-history-signed-user">{item.username}<br />
                {item.description}
                </span></div>
            </div>;
        })}
        </div>;
}
