import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "../../utils/Locale.js";
import Http from "../../utils/Http.js";
import HeaderLines from "../../resources/images/header_lines.svg";
import RMSLogo from "../../resources/images/rms_logo.svg";
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { Dropdown } from 'rsuite';
import exit from '../../resources/images/exit.svg';
import { Image } from "react-bootstrap";

export default function Header({currentLocale, changeLocale, toggleMenuFunction, onLogOut, userData}) {
  let newLocale = currentLocale == "ru" ? "en" : "ru";

  const http = Http.GetInstance();
  const navigate = useNavigate();

  const { switcher, themes, currentTheme, status } = useThemeSwitcher();
  const [darkTheme, setDarkTheme] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  const toggleMenu = () => {
        toggleMenuFunction();
  }
  const onChangeLocale = () => {
      changeLocale(newLocale);
  }

  const changeTheme = () => {
    switcher({ theme: darkTheme ? themes.light : themes.dark });
    setDarkTheme(!darkTheme);
  }

  const onLogout = () => {
    http.ClearSID();
    onLogOut();
    navigate("/login");
  }

  const userName = userData === undefined ? '': userData.full_name;
  const userMail = userData === undefined ? '': userData.email;
  const userAvatar = userData === undefined ? '' : (userData.avatarfile ?? '' != '' ? http.GetBaseUrl()+userData.avatarfile : '' );

  const renderToggle = (p, r) => {
    // return <button type="button" className="btn btn-dark btn-sm btn-mini-secondary" {...p} ref={r}></button>
    return <div className="user-dropdown" {...p} ref={r}></div>
  }

  const dropDownClick = () => {
    setDropDown(!dropDown);
  }

  return (
    <div className="App-header d-flex flex-row">
      <div className="App-header-content me-auto">
        <img src={HeaderLines} alt="" className="App-lines" onClick={toggleMenu}></img>
        <img src={RMSLogo} alt="" className="App-logo"></img>
        {/* <div onClick={onChangeLocale}>{newLocale}</div>
        <div onClick={changeTheme}>&nbsp;&nbsp;&nbsp;Переключить тему</div> */}
      </div>
      <div className="d-flex flex-row align-self-end h-100 align-items-center">
        <div className="user-info d-flex flex-row" onClick={dropDownClick}>
          <div className="user-avatar mt-auto mb-auto">
            {userAvatar!='' && <img src={userAvatar} alt=""></img>}
          </div>
          <div className="user-info d-flex flex-column">
            <div className="user-name">{userName}</div>
            <div className="user-mail">{userMail}</div>
          </div>
        </div>
        <Dropdown renderToggle={renderToggle} placement="bottomEnd" open={dropDown}>
            <Dropdown.Item onClick={onLogout}>
              <div className="d-flex flex-row align-items-center drop-menu-item">
               <Image src={exit}></Image><div>Выход</div>
              </div>
            </Dropdown.Item>
        </Dropdown>
      </div>
    </div>
  );
}