import * as React from "react";
import { useState } from "react";
import List from "../controls/List";
import Period from "../controls/Period";
import TextInput from "../controls/TextInput";
import i18n from "../../utils/Locale";

// images
import filter from "../../resources/images/filter-triangle.svg";
import funnel from "../../resources/images/funnel.svg";

export default function Filters(props) {

    const [activeTab, setActiveTab] = useState(0);
    const [extraFilter, setExtraFilter] = useState(false);
    const filterPlaceholder = i18n.t('Все');

    const hasExtraFilters = props.extraFilters != undefined && props.extraFilters.length>0;
    const changeActiveTab = (tab) => {
        setActiveTab(tab);
        if(props.onTabChanged != null)
        {
            props.onTabChanged(tab);
        }
    }

    const changedText = (e, item) => {
        if(props.onChangeFilter != null)
        {
            props.onChangeFilter(e.target.value, item);
        }
    }

    const changedFilterInput = (value, item) => {
        if(props.onChangeFilter != null)
        {
            props.onChangeFilter(value, item);
        }
    }

    return (
        <>
    <div className="d-flex align-items-center doc-list-filter">
        {props.filters.map((item, i) =>  {
            if(item.type === "search")
            {
                return <TextInput key={"fltr"+i} placeholder={filterPlaceholder} search={true}
                        value={item.value}
                        onChange={(e) => {changedText(e, item)}}
                        />;
            } else {
                if(item.type === "list")
                {
                    return <List options={item.options} placeholder={filterPlaceholder} value={item.value}
                    key={"fltr"+i} lkey={"fltr"+i} onChangedItem={(e) => {changedFilterInput(e, item)}} />
                } else {
                    if(item.type === "period")
                    {
                        return <Period key={"fltr"+i} value={item.value} onChangePeriod={(e) => {changedFilterInput(e, item)}} />
                    } else {
                        return <div  key={"fltr"+i} role="button" className={"p-2 border-bottom"
                        + (activeTab === i ? " active border-danger " : "" ) }
                        onClick={ () => changeActiveTab(i) }>{item.name}</div>
                    }
                    }
                }
            }
            )}
            {hasExtraFilters &&
                <button type="button" 
                    className={props.extraFilterHasValues ? "btn btn-dark btn-sm btn-mini" : "btn btn-dark btn-sm btn-mini-secondary"} onClick={() => { setExtraFilter(!extraFilter) }}>
                </button>
            }
    </div>
    {hasExtraFilters && extraFilter &&
        <div className="d-flex align-items-center extra-filter">
            {props.extraFilters.map( (item, i) => {
                return <div key={'exfilter'+i} className="extra-filter-item-container">
                    {(item.type == 'text' || item.type == 'id') &&
                        <TextInput
                        caption={item.name}
                        placeholder={filterPlaceholder}
                        onChange={(e) => {changedText(e, item)}}
                        value={item.value}
                        />
                    }
                    {item.type == 'list' && <div className="extra-filter-item-list"><List caption={item.name} options={item.options} placeholder={filterPlaceholder} val={item.value} value={item.value} lkey={"exfilter"+i} onChangedItem={(e) => {changedFilterInput(e, item)}} />
                </div>}
            </div>
            } )}
        </div>
    }
    </>
    );
}