import * as React from "react";
import refresh from "../../resources/images/refresh.svg";
import add from "../../resources/images/add-document-icon.svg";

export default function Header(props) {

    const onButtonsClick = (index) => {
        if(props.onButtonsClick)
        {
            props.onButtonsClick(index);
        }
    }

    const onRefresh = () => {
        if(props.onRefresh)
        {
            props.onRefresh();
        }
    }

    return (
        <div className="d-flex align-items-center doc-list-header">
            <div className="doc-list-name">{props.name}</div>
            {props.hasRefresh !== undefined && props.hasRefresh === "true" && 
                    <div className="doc-list-refresh" role="button" onClick={onRefresh}><img src={refresh} /></div>}
            <div className="d-flex ms-auto list-header-buttons">
            {props.button_name !== undefined && props.button_name !== "" && 
                    <div className="ms-auto">
                        <button type="button" className="btn btn-dark btn-sm" disabled={props.button_disabled} onClick={() => {onButtonsClick(0);}}>
                            {props.button_name}
                        </button>
                    </div>}
            {props.buttons !== undefined && props.buttons.map((button, i) => {
                let simple = typeof(button) === 'string';
                let title = simple ? button : button.name;
                let idx = simple ? i : button.type;
                return <div className="ms-auto" key={"listbutton"+i}>
                <button type="button" className="btn btn-dark btn-sm" disabled={props.button_disabled} onClick={() => {onButtonsClick(idx);}}>
                    {title}
                </button>
            </div>
            })}
            </div>
        </div>
    );
}