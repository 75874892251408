import * as React from "react";
import {useState, useRef} from "react";
import SubGroup from "./SubGroup";
import CurrencyText from "../../controls/CurrencyText";
import PercentText from "../../controls/PercentText";
import List from "../../controls/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { Tooltip, Whisper, Modal } from 'rsuite';
import DeleteElementButton from "./DeleteElementButton";

export default function Group(props) {

    const [collapsed, setCollapsed] = useState(props.collapsed ?? false);
    const [askDeleteGroup, setAskDeleteGroup] = useState(false);
    const item = props.item;
    const blockTypes = props.blockTypes;
    const canDeleteSubGroup = item.subgroups.length!=1;

    const confirmRef = useRef();

    const collapseToggle = () => {
        setCollapsed(!collapsed);
    }

    const onChangeData = (field, val) => {
        if(props.onChangeGroupData)
        {
            props.onChangeGroupData(item, field, val);
        }
    }

    const onChangeBlockData = (val, text) => {
        props.onChangeGroupData(item, 'mdg_estimate_blocks_id', val);
        props.onChangeGroupData(item, 'estimate_blocks_name', text);
    }

    const onAddGroup = () => {
        if(props.onAddGroup)
        {
            props.onAddGroup();
        }
    }

    const onAddSubGroup =() => {
        if(props.onAddSubGroup)
        {
            props.onAddSubGroup(item);
        }
    }

    const deleteGroup = () => {
        if(props.onDeleteGroup)
        {
            props.onDeleteGroup(item);
        }
    }

    const tooltipAddGroup = <Tooltip>{"Добавить раздел"}</Tooltip>;
    const tooltipAddSubgroup = <Tooltip>{"Добавить подраздел"}</Tooltip>;

    React.useEffect(() => {
        if(askDeleteGroup)
        {
            confirmRef.current.focus();
        }
    }, [askDeleteGroup]);

    return <>
    <Modal open={askDeleteGroup} onClose={() => {setAskDeleteGroup(false);}}>
        <Modal.Header>
          <Modal.Title>Подтвердить удаление!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Удалить раздел?
        </Modal.Body>
        <Modal.Footer>
            <button type="button" ref={confirmRef} className="btn btn-dark" onClick={() => {
            deleteGroup();
            setAskDeleteGroup(false);
            }}>Удалить</button>&nbsp;&nbsp;&nbsp;
          <button type="button" className="btn btn-outline-dark" onClick={() => {setAskDeleteGroup(false);}}>Отменить</button>
        </Modal.Footer>
    </Modal>
    <tr className={"group " + item.analyticsStatus}>
        <td>
            {props.editing && props.canDelete && <>
                <DeleteElementButton canDelete={(item.costRub ?? 0) == 0} onDelete={() => {
                    setAskDeleteGroup(true);
                }} />&nbsp;
                    </>
            }

            <FontAwesomeIcon icon={ collapsed ? faAngleDown : faAngleUp} onClick={collapseToggle} />
        </td>
        <td colSpan={props.collapseOut ? 1 : 3}>
            {props.editing && <input type="text" defaultValue={item.name} onChange={(e)=>{onChangeData('name', e.target.value)}}></input>}
            {!props.editing && <>{item.name}</>}
        </td>
        {!props.collapseOut && props.estimateType == 2 && <>
            <td>
                {props.editing && <input type="text" className="prc-input" defaultValue={item.agency_rate} onChange={(e)=>{onChangeData('agency_rate', e.target.value)}}></input>}
                {!props.editing && <>{item.agency_rate}</>} %
            </td>
            <td>&nbsp;</td>
        </>}

        {props.estimateType == 2 && <>
            <td className="text-end"><CurrencyText val={item.totalOut} currency={props.outCurrency} /></td>
            {!props.collapseOut && <>
                <td>&nbsp;</td>
                <td className="text-end"><CurrencyText val={item.outVat ?? 0} currency={props.outCurrency} /></td>
                <td className="text-end"><CurrencyText val={item.outAK ?? 0} currency={props.outCurrency} /></td>
                <td className="text-end"><CurrencyText val={item.outAKVat ?? 0} currency={props.outCurrency} /></td>
                <td>&nbsp;</td>
            </>}
        </>}

        {!props.collapseIn && props.estimateType == 2 &&
            <td colSpan="3">
                {props.editing && <List options={blockTypes} value={item.mdg_estimate_blocks_id} text={item.estimate_blocks_name} onChangedItem={onChangeBlockData} />}
                {!props.editing && <>{item.estimate_blocks_name ?? ""}</>}
            </td>
        }
        {props.collapseIn && props.estimateType == 2 &&
            <td colSpan="2">
                {props.editing && <List options={blockTypes} value={item.mdg_estimate_blocks_id} text={item.estimate_blocks_name} onChangedItem={onChangeBlockData} />}
                {!props.editing && <>{item.estimate_blocks_name ?? ""}</>}
            </td>
        }
        {!props.collapseIn && <td colSpan={3}>&nbsp;</td>}
            {props.estimateType == 2 && <td className="text-end"><CurrencyText val={item.totalIn ?? 0} /></td>}
            {props.estimateType != 2 && !props.collapseIn && <td className="text-end"></td>}
        {!props.collapseIn && <>
            <td>&nbsp;</td>
            <td className="text-end"><CurrencyText val={item.inVat ?? 0} /></td>
            <td className="text-end"><CurrencyText val={(item.inVat ?? 0) + (item.totalIn ?? 0)} /></td>
            <td colSpan={2}>&nbsp;</td>
            <td>&nbsp;</td>
        </>}

        {props.estimateType == 2 && <>
            {!props.collapseAnalytics && <>
                <td className="analytics">&nbsp;</td>
                <td className="analytics text-end">
                    <CurrencyText val={item.totalIn} />
                </td>
                <td className="analytics text-end">
                    <PercentText val={item.planPrc} />
                </td>
            </>}
            <td className="analytics text-end">
                <CurrencyText val={item.plan} />
            </td>
            {!props.collapseAnalytics && <>
                <td className="analytics text-end">
                    <PercentText val={item.factPrc} />
                </td>
            </>}
            <td className="analytics text-end">
                <CurrencyText val={item.fact} />
            </td>
            {!props.collapseAnalytics && <>
                <td className="analytics text-end">
                    <CurrencyText val={item.costRub} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.left} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.leftVat} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.costWithVatRub} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.reportsWithVatRub} />
                </td>
            </>}
        </>}

        {props.estimateType != 2 && <>
            {!props.collapseAnalytics && <>
                <td className="analytics">&nbsp;</td>
                <td className="analytics text-end">
                    <CurrencyText val={item.totalIn} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.costRub} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.left} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.leftVat} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.costWithVatRub} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.reportsWithVatRub} />
                </td>
            </>}
        </>}
    </tr>
    {props.editing && item.subgroups.length!=0 && <tr>
        <td colSpan={36} className="buttons">
            <div style={{left: props.leftScroll}}>
                <Whisper placement="top" delayOpen={1500} controlId="control-id-hover" trigger="hover" speaker={tooltipAddSubgroup}>
                    <FontAwesomeIcon icon={ faPlusSquare} className="subgroup-button" onClick={onAddSubGroup} />
                </Whisper>
            </div>
        </td>
    </tr>}
    {!collapsed && item.subgroups.map((subgroup, i) => {
        return <SubGroup leftScroll={props.leftScroll} key={props.index+"sg"+i} item={subgroup} index={props.index+"sg"+i} agency_rate={item.agency_rate} editing={props.editing} onChangeSubGroupData={props.onChangeSubGroupData} canDelete={canDeleteSubGroup} leList={props.leList} onChangeSublineData={props.onChangeSublineData} group={item} onAddLine={props.onAddLine} onAddInSubline={props.onAddInSubline} onAddOutSubline={props.onAddOutSubline} outCurrency={props.outCurrency} estimateType={props.estimateType} analyticsCalced={props.analyticsCalced} collapseIn={props.collapseIn} collapseOut={props.collapseOut} collapseAnalytics={props.collapseAnalytics} onDeleteSubgroup={props.onDeleteSubgroup} onDeleteLine={props.onDeleteLine} canMakeExpense={props.canMakeExpense} checkFailed={props.checkFailed} onDeleteInSubline={props.onDeleteInSubline} onDeleteOutSubline={props.onDeleteOutSubline} />
    })}
    {props.editing && <tr>
        <td colSpan={36} className="buttons">
            <div style={{left: props.leftScroll}}>
                <Whisper placement="top" delayOpen={1500} controlId="control-id-hover" trigger="hover" speaker={tooltipAddGroup}>
                    <FontAwesomeIcon icon={ faPlusSquare} className="group-button" onClick={onAddGroup} />
                </Whisper>
                <Whisper placement="top" delayOpen={1500} controlId="control-id-hover" trigger="hover" speaker={tooltipAddSubgroup}>
                    <FontAwesomeIcon icon={ faPlusSquare} className="subgroup-button" onClick={onAddSubGroup} />
                </Whisper>
            </div>
        </td>
    </tr>}

    </>;
}
