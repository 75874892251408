import React from "react";
import SubMenuItem from "./SubMenuItem";
import CustomLink from "../controls/CustomLink";
import arrow from "../../resources/images/menu-item-arrow.svg";

export default function MenuItem(props){

    const obj = props.item;
    const objIndex = props.index;
    const hasImage = obj.img !== undefined && obj.img !== "";
    const hasSubmenu = obj.children !== undefined && obj.children.length > 0;
    const hasLink = obj.link !== undefined && obj.link !== "";

    return (
        <li className={"nav-item"+(obj.active ? " active" : "")} role="button">
            <div className="menu-item-container">
                {hasImage && <img className="icon" src={obj.img} alt=""></img>}
                {hasLink && 
                    <CustomLink to={obj.link} 
                        className={"nav-link menu-item-text"+(hasImage ? "" : " noicon")}
                        extraAction={() => {props.changeMenu(props.index, -1)}}
                    >
                    {obj.name}
                    </CustomLink>
                }

                {!hasLink && <span className={"nav-link menu-item-text"+(hasImage ? "" : " noicon")}
                        onClick={() => {props.changeMenu(props.index, -1)}}
                        >
                    {obj.name}
                </span>
                }
                {hasSubmenu && <img className="arrow" src={arrow} alt="" onClick={() => {props.changeMenu(props.index, -1)}}></img>}
            </div>
            {hasSubmenu && <div className="submenu">
                {obj.children.map( (item, i) => {
                    return <SubMenuItem name={item.name} link={item.link} key={"m"+objIndex+"s"+i} 
                        changeMenu={props.changeMenu} menuIndex={props.index} submenuIndex={i} active={item.active} />
                } ) }
                </div>}
        </li>
    );
}
