import * as React from "react";
import { NumericFormat } from 'react-number-format';

export default function CurrencyText({val, currency, accuracy, type, symbol, currencyId, onChange}) {

    let cur = currency ?? 'RUR';
    accuracy = accuracy ?? 2;
    const itemType = type ?? 'text';

    if(symbol !== undefined && symbol != null)
    {
        cur = symbol;
    } else {
        if(currencyId != undefined && currencyId != null && window.currencyListHashId != undefined)
        {
            if(window.currencyListHashId[currencyId] != undefined)
            {
                cur = window.currencyListHashId[currencyId].symbol;
            } else {
                cur = '₽';
            }
        } else {
            if(window.currencyListHash != undefined)
            {
                if(window.currencyListHash[cur] != undefined)
                {
                    cur = window.currencyListHash[cur].symbol;
                } else {
                    cur = '₽';
                }
            } else {
                cur = '₽';
            }
        }
    }

    const onChangeValue = (e) => {
        const {formattedValue, value, floatValue} = e;
        if(val != floatValue && onChange)
        {
            onChange(floatValue ?? 0);
        }
    }

    return <NumericFormat value={val} thousandSeparator="'" displayType={itemType} allowNegative fixedDecimalScale={true} decimalScale={accuracy} suffix={' '+cur} onValueChange={onChangeValue}/>;
}
