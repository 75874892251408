import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DocList from "../../components/list/DocList";
import Http from "../../utils/Http.js";
import i18n from "../../utils/Locale";
import { create } from "@mui/material/styles/createTransitions.js";

export default function EstimatesList(props) {
  const [loadingData, setLoadingData] = useState(false);
  const http = Http.GetInstance();
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const fields = [
    { name: "idname", caption: "№", extra: true, extraFilterType: "text" },
    { name: "status_name", caption: i18n.t("Статус") },
    { name: "step_age", caption: "В ожидании" },
    { name: "name", caption: "Наименование", extra: true },
    {
      name: "amount",
      caption: "Сумма",
      displayType: "moneyNoSymbol",
      extra: true,
    },
    { name: "symbol", caption: "Валюта" },
    {
      name: "client_name",
      caption: "Клиент",
      extra: true,
      extraFilterType: "list",
    },
    {
      name: "project_name",
      caption: "Проект",
      extra: true,
      extraFilterType: "list",
    },
    {
      name: "manager_full_name",
      caption: "Ответственный за смету",
      extra: true,
      extraFilterType: "list",
    },
    {
      name: "company_name",
      caption: "Агентство",
      extra: true,
      extraFilterType: "list",
    },
    { name: "year", caption: "Год", extra: true, extraFilterType: "list" },
  ];

  const filters = [
    { name: "Поиск", type: "search" },
    {
      name: "Все статусы",
      type: "list",
      listId: "status_name",
      listName: "status_name",
      options: [],
    },
    { name: "From", type: "period", fieldName: "createdate" },
  ];

  const getEstimatesList = () => {
    if (!loadingData) {
      setLoadingData(true);
      http.GetEstimatesList((resp) => {
        if (resp.data.errorcode !== "00") {
          alert("Error!");
        } else {
          if (resp.data.estimateList.list == null) {
            setRows([]);
          } else {
            setRows(
              resp.data.estimateList.list.map((i) => {
                let iname = (i.type == 1 ? "BU" : "CL") + "-" + i.id;
                let symbol = i.amount_currency;
                if (window.currencyList !== undefined) {
                  let foundSymbol = window.currencyList.find(
                    (i) => i.symbol == symbol
                  );
                  if (foundSymbol != undefined && foundSymbol != null) {
                    symbol = foundSymbol.shortcode;
                  }
                }
                return { ...i, idname: iname, symbol: symbol };
              })
            );
          }
        }
        setLoadingData(false);
      });
    }
  };

  useEffect(() => {
    http.GetEstimateDicts((resp) => {
      if (resp.data.errorcode !== "00") {
        alert("Error!");
      } else {
        window.currencyList = resp.data.currencyList;
        getEstimatesList();
      }
    });
  }, []);

  const addButtonClick = (index) => {
    navigate("/estimates/0?type=" + (index == 0 ? 2 : 1));
  };

  const checkRight = (right) => {
    if(window.user.grants.length == 0)
    {
      return false;
    }
    return window.user.grants.find(a => a.grant_code === right) != null;
  }

  const createButtons = [];

  if(checkRight('ESTIMATE_CREATOR'))
  {
    if(checkRight('ESTIMATE_CREATE_CLIENT'))
    {
      createButtons.push({name: i18n.t("Новая клиентска смета"), type: 0});
    }
    if(checkRight('ESTIMATE_CREATE_BUDGET'))
    {
      createButtons.push({name: i18n.t("Новая бюджетная смета"), type: 1});
    }
  }

  return (
    <>
      <DocList
        name={i18n.t("Cметы")}
        hasRefresh="true"
        buttons={createButtons}
        onButtonsClick={addButtonClick}
        fields={fields}
        rows={rows}
        filters={filters}
        docPrefixLink={"/estimates/"}
        onRefresh={getEstimatesList}
        loading={loadingData}
      />
    </>
  );
}
