import * as React from "react";
import { FileIcon, defaultStyles } from 'react-file-icon';
import {useState} from "react";
import Http from "../../utils/Http";
import '../../DocumentFiles.scss';
import i18n from "../../utils/Locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFile } from '@fortawesome/free-regular-svg-icons';
import { Message, useToaster } from 'rsuite';
import { faFileUpload, faFileDownload, faE } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt, faEye } from '@fortawesome/free-regular-svg-icons';


export default function DocumentFiles({items, documentId, editing, loadFilesCallBack}) {
    const [dragging, setDragging] = useState(false);
    items = items ?? [];
    documentId = documentId ?? '';
    const http = Http.GetInstance();
    
    const toaster = useToaster();

    const showAlert = (type, text, timeout = 5000) => {
        const message = <Message showIcon type={type} closable>{text}</Message>;
        const placement = 'topCenter';
        toaster.push(message, { placement, duration: timeout });
    }

    const loadFiles = () => {
        http.GetFilesList(documentId, (r) => {
            if(r.data.errorcode !== "00")
            {
                showAlert('warning', "Нет доступа к вложенным файлам.");
            } else {
                if(loadFilesCallBack)
                {
                    loadFilesCallBack(r.data.list);
                }
            }
          });
    }

    const uploadFile = (e) => {
        let file2upload = e.target.files[0];
        uploadFileRaw(file2upload);
    }

    const uploadFileRaw = (file2upload) => {
        let reader = new FileReader();
        reader.readAsDataURL(file2upload);
        reader.onloadend = (ev) => {

            let fileData = {
                    file: file2upload,
                    fileData: [reader.result]
            }
            http.UploadFile(documentId, fileData, (response) => {
                loadFiles();
            });
        }
    }

    const deleteFile = (fileId) => {
        http.DeleteFile(documentId, fileId, (response) => {
            loadFiles();
        })
    }
    const downloadFile = (fileId) => {
        http.DownloadFile(documentId, fileId, (response) => {
            if(response == false)
            {
                showAlert('warning', "Нет доступа к вложенным файлам.");
            } else {
                const url = window.URL.createObjectURL(
                    new Blob([response.data]), fileId
                )
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileId);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        })
    }
    const openFile = (fileId, mimeType) => {
        http.OpenFile(documentId, fileId, (response) => {
            if(response == false)
            {
                showAlert('warning', "Нет доступа к вложенным файлам.");
            } else {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: mimeType }), fileId
                )
                const link = document.createElement('a');
                link.href = url;
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        })
    }

    const preventDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const dragEnter = (e) => {
        if(editing) {
            e.dataTransfer.mozCursor = "copy";
            e.dataTransfer.dropEffect = 'copy';
            setDragging(true);
        }
        preventDrag(e);
    }
      
    const dragLeave = (e) => {
        setDragging(false);
        preventDrag(e);
    }

    const dropFile = (e) => {
        if(editing)
        {
            let dt = e.dataTransfer;
            let files = dt.files;
            ([...files]).forEach(uploadFileRaw);
        }
        dragLeave(e);
    }

    return <div className={"drop-area" + (dragging ? " dragging" : "")} onDragEnter={dragEnter} onDragOver={dragEnter} onDragLeave={dragLeave} onDrop={dropFile}>
    <div className="file-icons">
        {editing && <>
        <div className="file-block">
            <label className="file-upload">
                <FontAwesomeIcon icon={faFileUpload} className="file-upload-icon" />
                <div>{i18n.t('Загрузить файл')}</div>
                <input type="file" onChange={uploadFile} />
            </label>
        </div>
        </>}
        {items.map((item, i) => {
            return  <div key={"attache"+i} className={editing ? "editing file-block" : "file-block"}>
                        <div>
                            <div className="file-icon">
                                <FileIcon extension={item.extension} {...defaultStyles[item.extension]} />
                            </div>
                            <div className="file-name" title={item.name}>
                                {item.name}
                            </div>
                        </div>
                        <div className="buttons">
                            <FontAwesomeIcon icon={faEye} className="file-delete-icon" onClick={() => {openFile(item.name, item.mimeType)}} />
                            <FontAwesomeIcon icon={faFileDownload} className="file-delete-icon" onClick={() => {downloadFile(item.name)}} />
                            {editing && 
                            <FontAwesomeIcon icon={faTrashAlt} className="file-delete-icon" onClick={() => {deleteFile(item.name)}} />
                            }
                        </div>
                    </div>;
        })}
        </div>
    </div>
}
