import * as React from "react";
import TextInput from "../controls/TextInput";
import List from "../controls/List";
import DateDropDown from "../controls/DateDropDown";
import Period from "../controls/Period";

export default function DocHeaderItem({item, items, type, rows, disabled, editing, editable, 
                    extraClass, onChange, checkFailed, hasClear}) 
{

    const itemType = type ?? "text";
    const name = item.name ?? Math.floor(Math.random()*1000000);
    const key = "db"+name;
    rows = rows ?? 5;
    disabled = disabled ?? false;

    const itemChanged = (e) => {
        if(onChange)
        {
            onChange(e.target.value, item.name)
        }
    }

    const listItemChanged = (val, label) => {
        if(onChange)
        {
            if(item.name ?? '' != '')
            {
                onChange(val, item.name);
            }
            if(item.label ?? '' != '')
            {
                onChange(label, item.label);
            }
        }
    }

    const dateChanged = (e) => {
        if(onChange)
        {
            onChange(e, item.name)
        }
    }

    const periodChanged = (e) => {
        if(onChange)
        {
            onChange(e, item.name)
        }
    }

    const period = itemType != "period" || item.val == null || item.val[0] == null ? "" : item.val[0].toLocaleDateString() + " - " + item.val[1].toLocaleDateString();
    const dateVal = itemType == "date" && item.val !== undefined && item.val !== null ? item.val.toLocaleDateString() : "";

    const itemDisabled = !(editable ?? true) || disabled;

    const mandatory = item.mandatory ?? false;
    const underText = checkFailed && mandatory && (item.val ?? '') == '' ? "Please provide a valid value" : "";
    const exclamation = underText !== "";
    const underRed = checkFailed && mandatory;

    return (
        <div className={"doc-body-header-line" + (extraClass ?? "")}>
            {editing && (itemType == "text" || itemType == "textarea" || itemType === "number") &&
            <TextInput caption={item.title} disabled={itemDisabled} key={key} value={item.val} type={itemType} onChange={itemChanged} rows={rows} exclamation={exclamation} under={underText} mandatory={mandatory} underRed={underRed} />}

            {editing && itemType == "list" &&
            <List caption={item.title} options={items ?? []} disabled={itemDisabled} key={key} lkey={"l"+key} value={item.val} text={item.text} onChangedItem={listItemChanged} exclamation={exclamation} under={underText} mandatory={mandatory} underRed={underRed}  />}

            {editing && itemType == "date" &&
            <DateDropDown caption={item.title} disabled={itemDisabled} key={key} value={item.val} onChange={dateChanged} extraClass=" d-flex flex-column" exclamation={exclamation} under={underText} mandatory={mandatory} underRed={underRed}  />}

            {editing && itemType == "period" &&
            <Period key={key} caption={item.title} value={item.val} disabled={itemDisabled} onChangePeriod={(e) => {periodChanged(e, item)}}  extraClass=" d-flex flex-column" exclamation={exclamation} under={underText} mandatory={mandatory} underRed={underRed} from={item.from ?? null} to={item.to ?? null} hasClear={hasClear} />
            }
            {!editing && 
            <>
                <label className="text-secondary">{item.title}:</label>
                {itemType === "period" &&
                    <label className="text-body">{period}</label>
                }
                {itemType !== "period" && itemType !== "date" &&
                    <label className="text-body">{itemType === "list" ? item.text : item.val }</label>
                }
                {itemType === "date" &&
                    <label className="text-body">{ dateVal }</label>
                }

            </>}
        </div>
     );
}