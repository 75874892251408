import * as React from "react";
import { useState } from "react";

export default function Tabs(props){

    const activeTab = props.activeTab ?? 0;

    const changeActiveTab = (tab) => {
        if(props.onChangeTab!=null)
        {
            props.onChangeTab(tab);
        }
    }

    return (
    <div className="d-flex align-items-center doc-list-tabs">
        {props.states.map((item, i) =>  {
            return <div key={"dlt"+i} role="button" 
                className={"border-bottom" + (activeTab === i ? " active border-danger border-2" : "" ) }
                onClick={ () => changeActiveTab(i) }>
                    {item}
                </div>}
            )}
            <div key={"dltlast"} className="border-bottom flex-fill">&nbsp;</div>
    </div>
    );
}