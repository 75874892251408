import { React } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import Http from './Http';

export default function Substitute(props) {
    const params= useParams();
    const http = Http.GetInstance();
    const navigate = useNavigate();

    http.PostRequest("userReplacement", {command: "loginAs", itemID: params.id*1}, (resp) => {
        if(resp.data.errorcode !== "00")
        {
            alert("Error! "+resp.data.errortext);
        } else {
            window.userReplaced = true;
            http.SetSID(resp.data.sid);
            navigate("/");
        }
    });

    return <></>;
}