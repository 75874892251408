import * as React from "react";
import {useState} from "react";
import {Link} from "react-router-dom";
import { useParams } from 'react-router';
import i18n from "../../utils/Locale.js";
import { Tooltip, Whisper } from 'rsuite';
import { useSearchParams, useNavigate } from "react-router-dom";
import CustomLink from "../controls/CustomLink.js";
import Http from "../../utils/Http.js";
import Tabs from "../list/Tabs.js";
import { Button } from "react-bootstrap";

export default function DocumentHeader(props) {
    const [firstLoad, setFirstLoad] = useState(true);
    const params= useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const hasCancel = props.hasCancel ?? false;
    const navigate = useNavigate();
    const http = Http.GetInstance();

    let page = 0;
    let tab = 0;
    if(searchParams.has('page'))
    {
        page=searchParams.get('page');
        searchParams.delete('page');
    }
    if(searchParams.has('tab'))
    {
        tab=searchParams.get('tab');
        searchParams.delete('tab');
    }
    if(firstLoad)
    {
        setSearchParams(searchParams);
        setFirstLoad(false);
    }
    const [listPage] = useState(page);
    const [listTab] = useState(tab);

    const states = (props.tabs ?? null) == null ? [i18n.t('Данные'), i18n.t('Файлы'), i18n.t('Подписи')] : props.tabs;

    const currentTab = states.indexOf(props.currentTab ?? states[0]);

    const onTabChanged = (tab) => {
        if(props.onTabChange)
        {
            props.onTabChange(states[tab]);
        }
    };

    const onDeleteDocument = () => {
        if(props.documentid !== "" && props.documentid !== 0)
        {
          http.DeleteDocument(props.documentid, (response) => { navigate(props.listLink);});
        }
      }

    const onEdit = () => {
        if(props.documentid !== "" && props.documentid !== 0)
        {
            http.LockDocument(props.documentid, (resp) => {
                if(resp.data.errorcode === "00")
                {
                    if(props.onEditClicked)
                    {
                        props.onEditClicked(false);
                    }
                } else {
                    props.onEditClicked(true);
                }
            });
        } else {
            props.onEditClicked(true);
        }
    }

    const onCancelEdit = () => {
        if(props.documentid !== undefined && props.documentid !== null && props.documentid !== "" && props.documentid !== 0)
        {
            http.UnlockDocument(props.documentid, (resp) => {
                if(resp.data.errorcode === "00")
                {
                    if(props.onEditClicked)
                    {
                        props.onEditClicked(false);
                    }
                }
            });
        } else {
            props.onEditClicked(false);
        }
    }
    const tooltip = <Tooltip>{(props.lock.status ?? false) ? 'Документ заблокирован пользователем '+props.lock.user : ''}</Tooltip>;
    
    return (
        <>
            <div className="doc-header-link">
                <CustomLink to={props.listLink+"?page="+listPage+"&tab="+listTab} title={props.listName}>{props.listName}</CustomLink> / 
            </div>
            <div className="d-flex doc-header-title-container">
                <div className="doc-header-title text-body">
                    {props.newDoc && <>{props.newDocTitle}</>}
                    {!props.newDoc && <>{props.docTitle} #{((params.id ?? "") == "0" ? "" : params.id)}</>}
                </div>
                {!props.newDoc && <div className="doc-status-cloud text-body">{props.status}</div>}
                <div className="ms-auto doc-header-buttons">
                    {!props.editing && <>
                    {(props.enableEdit ?? true) && <>
                        {props.lock.status &&
                        <Whisper placement="top" trigger="hover" controlId={'control-id-edit'} speaker={tooltip}  >
                            <div style={{display: 'inline-block'}}>
                                <button type="button" 
                                    className={"btn btn-sm btn-outline-dark disabled"}
                                    disabled={true}
                                >
                                    {i18n.t('Редактировать')}
                                </button>
                            </div>
                        </Whisper>
                        }
                        {!props.lock.status &&
                                <button type="button" 
                                    className={"btn btn-sm btn-dark"}
                                    onClick={onEdit}
                                >
                                    {i18n.t('Редактировать')}
                                </button>
                        }


                    </>
                    }
                    {props.enableDelete && <button type="button" className="btn btn-outline-dark btn-sm" onClick={onDeleteDocument}>{i18n.t('Удалить')}</button>}
                    </>
                    }
                    {props.editing && <>
                    <button type="button" className="btn btn-dark btn-sm" disabled={!(props.hasChanges ?? false)} onClick={props.onSaveClicked}>{i18n.t('Сохранить')}</button>
                    {hasCancel && <button type="button" className="btn btn-outline-dark btn-sm" onClick={onCancelEdit}>{i18n.t('Отменить')}</button>}
                    </>
                    }
                    {props.children}
                </div>
            </div>
            <Tabs states={states} activeTab={currentTab} onChangeTab={onTabChanged} />
        </>
    );
}