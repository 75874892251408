import * as React from "react";
import List from "../../controls/List";
import { NumericFormat } from 'react-number-format';

export default function Qnt({editing, item, index, onChange}) {

    const defaultMetrics = 'Шт.'

    const calcMult = (newItem) => {
        let result = newItem.reduce((sm, i) => {
            if(i.value == "")
            {
                return sm;
            } else {
                if(i['selectNames'] == undefined)
                {
                    return sm * (i.value*1);
                }
                return sm * (i.value*1)*i.selectNames.value;
            }
        }, 1);
        return result;
    }

    const changed = (newItem) => {
        let result = calcMult(newItem);
        onChangeItem(newItem, result);
    }

    const changedValue = (idx, value) => {
        const newItem = [...item];
        if(idx == 0 && value == "")
        {
            value = "0";
        }
        newItem[idx].value = value;
        if(idx == newItem.length-1)
        {
            if(value != "")
            {
                newItem.push({value: "", name: "", selectNames: window.defaultQNTItem});
            }
        }
        while(newItem.length > 2 && newItem[newItem.length-2].value == "")
        {
            newItem.splice(newItem.length-1, 1);
        }
        changed(newItem);
    }

    const changedMetrics = (idx, value, name, q) => {
        const newItem = [...item];
        newItem[idx].name = name;
        newItem[idx].selectNames = {...window.metricsListHash[name]};
        changed(newItem);
    }

    const onChangeItem = (newItem, sum) => {
        if(onChange)
        {
            onChange(newItem, sum);
        }
    }

    return <div className="d-flex">
        {!editing && item.length ==0 && <>&nbsp;</>}
        {item.map((q, i) => {
            let name = ((q['selectNames'] ?? "") == "") ? defaultMetrics : (q.selectNames.name ?? "");
            let val = q.value ?? "";
            if(editing)
            {
                return <span className="d-flex" key={index+"q"+i}>
                    <NumericFormat value={val} thousandSeparator="" displayType='input' allowNegative suffix={''} onValueChange={(e) => {
                        const {formattedValue, value, floatValue} = e;
                        changedValue(i, floatValue ?? "");
                    }} className="qnt-input" />

                    <List options={window.metricsList} value={name} text={name} onChangedItem={(v, n) => {changedMetrics(i, v, n, q)}} />
                </span>
            }
            if(val != "")
            {
                return <span key={index+"q"+i}>{q.value} {name}</span>
            }
        })}
    </div>
    ;
}