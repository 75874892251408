import * as React from "react";
import {useState, useEffect} from "react";
import Pagination from 'react-bootstrap/Pagination';
import { NumericFormat } from 'react-number-format';
import {Link, useSearchParams} from "react-router-dom";

import Header from "./Header";
import Tabs from "./Tabs";
import Filters from "./Filters";
import List from "../controls/List";
import i18n from "../../utils/Locale";
import sign from "../../resources/images/sign.svg";
import signDisabled from "../../resources/images/sign-disabled.svg";
import reject from "../../resources/images/reject.svg";
import rejectDisabled from "../../resources/images/reject-disabled.svg";
import emptyBox from "../../resources/images/empty-img-simple.svg";
import { Placeholder, Modal, Message, useToaster, Loader } from 'rsuite';
import Http from "../../utils/Http";
import TextInput from "../controls/TextInput";

export default function DocList(props) {

    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(0);
    const [currentTab, setCurrentTab] = useState(0);
    const [showSignComment, setShowSignComment] = useState(false);
    const [wantSign, setWantSign] = useState(false);
    const [wantAddSign, setWantAddSign] = useState(false);
    const [docToSign, setDocToSign] = useState(0);
    const [docToSignVersion, setDocToSignVersion] = useState(0);
    const [signComment, setSignComment] = useState('');

    const [signingDocs, setSigningDocs] = useState([]);

    const toaster = useToaster();

    const http = Http.GetInstance();

    if(searchParams.has('page'))
    {
        setCurrentPage(searchParams.get('page')*1);
    }
    if(searchParams.has('tab'))
    {
        setCurrentTab(searchParams.get('tab')*1);
    }

    if(searchParams.has('page') || searchParams.has('tab'))
    {
        searchParams.delete('page');
        searchParams.delete('tab');
        setSearchParams(searchParams);
    }

    const [rows2show, setRows2Show] = useState([]);
    const [rows4tab, setRows4Tab] = useState([]);
    const [rowsFiltered, setRowsFiltered] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalPages, setTotalPages] = useState(0);
    const [pages, setPages] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [listFilters, setListFilters] = useState({});
    const [extraFilters, setExtraFilters] = useState({});
    const [sortColumn, setSortColumn] = useState("");
    const [sortColumnType, setSortColumnType] = useState(1);
    const [extraFilterHasValues, setExtraFilterHasValues] = useState(false);
    const [startSign, setStartSign] = useState(false);

    const pageNeighbours = 2;
    const currencyFieldName = 'symbol';

    const states = [i18n.t('На подпись'), i18n.t('Все')];
    const filters = props.filters;

    const fields = props.fields;
    const pageOptions = [{id: 50, name: '50 / page'}, {id: 100, name: '100 / page'}, {id: 500, name: '500 / page'}, {id: 1000, name: '1000 / page'}];

    const getFilterValues = (f) => {
        if(f.type == "list")
        {
            let f_values = [];
            for(var j=0;j<props.rows.length;j++)
            {
                let row = props.rows[j];
                f_values[row[f.listId]] = row[f.listName];
            }

            let f_options = [];
            for(let f_val in f_values)
            {
                f_options.push({id: f_val, name: f_values[f_val]});
            }
            f_options = f_options.sort((a, b) => {return a.name > b.name;});
            let full_options = [{id: '', name: i18n.t('Все')}, ... f_options];
            f.options = full_options;
        }
    }

    const rebuildFilters = () => {
        for(var i=0;i<filters.length;i++)
        {
            let f = filters[i];
            if(f.type == "list")
            {
                getFilterValues(f);
            }
        }
        if(extraFilters!=undefined)
        {
            for(var i=0;i<extraFilters.length;i++)
            {
                let f = extraFilters[i];
                if(f.type == "list")
                {
                    getFilterValues(f);
                }
            }
        }
    }

    rebuildFilters();

    const setPageRows = (p, r) => {
        let pageRows = r.slice(p*rowsPerPage, (p+1)*rowsPerPage);
        setRows2Show(pageRows);
    }

    const pageChanged = (page) => {
        setCurrentPage(page);
        setPageRows(page, rowsFiltered);
    }

    const onFilterChanged = (val, item) => {
        if(item.type === "search")
        {
            setSearchText(val);
            pageChanged(0);
        }
        if(item.type === "list")
        {
            let f = {};
            f[item.listId] = {type: 'list', value: val};
            setListFilters({...listFilters, ...f});
        }
        if(item.type === "period")
        {
            setListFilters({...listFilters, period: val, periodField: item.fieldName});
        }
        if(item.type === "text")
        {
            let f = {};
            f[item.fieldName] = {type: 'text', value: val};
            setListFilters({...listFilters, ...f});
        }
        item.value = val;
    }

    useEffect(() => {
        let exFiltersList = [];
        let fields = props.fields ?? [];
        for(let i in fields)
        {
            let f = fields[i];
            if(f['extra']!=undefined && f.extra)
            {
                let fType = f['extraFilterType'] != undefined ? f.extraFilterType : 'text';
                let extraFilter = {name: f.caption, fieldName: f.name, type: fType};
                if(fType == 'list')
                {
                    extraFilter.listId = f.name;
                    extraFilter.listName = f.name;
                    extraFilter.fieldName = f.name;
                    extraFilter.options = [];
                }
                exFiltersList.push(extraFilter);
            }
        }
        if(exFiltersList.length>0)
        {
            setExtraFilters(exFiltersList);
        } else {
            setExtraFilters(props.extraFilters);
        }
    }, [props.extraFilters, props.fields]);

    useEffect(() => {
        filterData(rows4tab);
    }, [searchText, rows4tab, listFilters, rowsPerPage, sortColumn, sortColumnType]);

    const sortData = (rFiltered) => {
        if(sortColumn!="")
        {
            rFiltered.sort((a,b) => {
                if(a[sortColumn] == b[sortColumn])
                {
                    return 0;
                }
                let ret = -1;
                if(sortColumnType == 1)
                {
                    if((a[sortColumn] > b[sortColumn]))
                    {
                        ret = 1;
                    } else {
                        ret = -1;
                    }
                } else {
                    if((a[sortColumn] > b[sortColumn]))
                    {
                        ret = -11;
                    } else {
                        ret = 1;
                    }
                }
                return ret;

            });
        }
        return rFiltered;
    }

    const filterData = (rFiltered) => {
        let filterKeys = [];
        let periodField = "";
        let periodStart = null;
        let periodEnd = null;

        if(listFilters!=undefined)
        {
            for (let k in listFilters)
            {
                if(k != "periodField")
                {
                    if(k == "period")
                    {
                        periodStart = listFilters["period"][0];
                        periodEnd = listFilters["period"][1];
                        filterKeys.push(k);
                    } else {
                        if(k ?? "" != "")
                        {
                            filterKeys.push(k);
                        }
                    }
                } else {
                    periodField = listFilters[k];
                }
            }
        }
        let hasFilters = filterKeys.length > 0;

        let filterHasValues = false;
        if(searchText ?? '' != '')
        {
            let lowerCaseSearch = searchText.toLocaleLowerCase();
            rFiltered = rFiltered.filter((item) => {
                if(fields != undefined)
                {
                    for(let i in fields)
                    {
                        let fName = fields[i].name;
                        if((item[fName]+"").toLowerCase().includes(lowerCaseSearch))
                        {
                            return item;
                        }
                    }
                }
            });
        }

        rFiltered = rFiltered.filter((item) => {
            let itemIsOk = true;
            if(fields != undefined)
            {
                for(let i in fields)
                {
                    let fName = fields[i].name;
                    if(itemIsOk)
                    {
                        if(hasFilters)
                        {
                            for(let fKey in listFilters)
                            {
                                if(fKey == "period")
                                {
                                    let itemDate = new Date(item[periodField]);
                                    if((periodStart == null || itemDate>=periodStart) && 
                                    (periodEnd == null || itemDate<=periodEnd))
                                    {
                                        itemIsOk = true;
                                    } else {
                                        itemIsOk = false;
                                    }
                                } else {
                                    if(listFilters[fKey] != undefined && listFilters[fKey] != null && listFilters[fKey].value != "")
                                    {
                                        let isExtraFilter = false;
                                        extraFilters.forEach(e => { if(e.fieldName == fKey) { isExtraFilter = true; }});

                                        filterHasValues = isExtraFilter;
                                        if(listFilters[fKey].type == 'text')
                                        {
                                            let val = listFilters[fKey].value;
                                            // if(fName == 'id' || fName == 'idname')
                                            if(fName == 'id')
                                            {
                                                itemIsOk = item[fKey]+"" == val;
                                            } else {
                                                itemIsOk = (item[fKey]+"").toLowerCase().indexOf(val.toLowerCase()) >=0;
                                            }
                                            if(!itemIsOk)
                                            {
                                                let valAr = [];
                                                if(val.includes(','))
                                                {
                                                    valAr = val.split(',');
                                                } else {
                                                    if(val.includes(' '))
                                                    {
                                                        valAr = val.split(' ');
                                                    }
                                                }
                                                if(valAr.length>1)
                                                {
                                                    for(let vAi in valAr)
                                                    {
                                                        let vA = valAr[vAi];
                                                        if(fName == 'id' || fName == 'idname')
                                                        {
                                                            if(item[fKey]+"" == vA)
                                                            {
                                                                itemIsOk = true;
                                                            }
                                                        } else {
                                                            if((item[fKey]+"").includes(vA))
                                                            {
                                                                itemIsOk = true;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        } else {
                                            itemIsOk = listFilters[fKey].value == item[fKey];
                                        }
                                    }
                                }
                                if(!itemIsOk)
                                {
                                    break;
                                }
                            }
                        }
                        if(!itemIsOk)
                        {
                            break;
                        }
                    }
                }
                if(itemIsOk)
                {
                    return item;
                }
            }
        });
        setExtraFilterHasValues(filterHasValues);
        rFiltered = sortData(rFiltered);
        setPaginagion(rFiltered);
    }

    const onTabChanged = (tab) => {
        let rFiltered = props.rows;
        if(tab == 0)
        {
            rFiltered = props.rows.filter((item, index) => {
                if(item.approver == 1)
                {
                    return item;
                }
            });
        }
        setCurrentTab(tab);
        setRows4Tab(rFiltered);
        filterData(rFiltered);
    }

    const prevPage = () => { if(currentPage>0) { 
        pageChanged(currentPage-1);
    } };
    const nextPage = () => { if(currentPage<totalPages-1) {
        pageChanged(currentPage+1);
    } };


    const setPaginagion = (r) => {
        let p = [];
        let totalP = Math.ceil(r.length / rowsPerPage);
        for(var i=0;i<totalP;i++)
        {
            p.push(i);
        }
        setPages(p);
        setTotalPages(totalP);
        setRowsFiltered(r);
        setPageRows(0, r);
    }
    useEffect(() => {onTabChanged(currentTab)}, [props.rows]);

    const getDocWord = (cnt) => {
        if(cnt == 1 || (cnt>20 && cnt%10 == 1))
        {
            return cnt + ' '+i18n.t('документ');
        }
        if((cnt>=2 && cnt<=4) || (cnt>20 && (cnt%10>= 2 && cnt%10<=4 ) ))
        {
            return cnt + ' '+i18n.t('документа');
        }
        return cnt + ' '+i18n.t('документов');
    }

    const onRefresh = () => {
        if(props.onRefresh)
        {
            props.onRefresh();
        }
    }

    let loading = props.loading ?? false;

    const showAlert = (type, text, timeout = 5000) => {
        const message = <Message showIcon type={type} closable>{text}</Message>;
        const placement = 'topCenter';
        toaster.push(message, { placement, duration: timeout });
    }
    
    const signDocument = (id, version) => {
        setWantAddSign(false);
        setWantSign(true);
        setShowSignComment(true);
        setDocToSign(id);
    }

    const rejectDocument = (id) => {
        setWantAddSign(false);
        setWantSign(false);
        setShowSignComment(true);
        setDocToSign(id);
    }

    const afterSign = (r) => {
        let idx = signingDocs.indexOf(docToSign);
        if(idx>=0)
        {
            let newSignList = JSON.parse(JSON.stringify(signingDocs));
            newSignList.splice(idx, 1);
            setSigningDocs(newSignList);
        }

        if(r.data.errorcode === "00")
        {
            showAlert("success", "Ваша подпись принята");
            props.onRefresh();
        } else {
            showAlert("error", "Ошибка "+(wantSign ? "подписания" : "отклонения")+"! "+r.data.errortext);
        }
    }

    useEffect(() => {
        if(startSign)
        {
            setShowSignComment(false);
            setStartSign(false);
            http.DocumentSign(docToSign, wantSign, wantAddSign, null, signComment, docToSignVersion, afterSign);
        }
    }, [startSign]);

    const doSignOrReject = () => {

        if(!wantSign && signComment == "")
        {
            showAlert("warning", "Для отклонения необходимо ввести комментарий!");
        } else {
            let newSignList = JSON.parse(JSON.stringify(signingDocs));
            newSignList.push(docToSign);
            setSigningDocs(newSignList);    
            setStartSign(true);
        }
    }

    return (
        <>
        {showSignComment && <Modal open={showSignComment} onClose={() => {setShowSignComment(false);}}>
            <Modal.Header>
            <Modal.Title>Добавление комментария к {wantSign ? "подписанию" : "отклонению"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextInput value={signComment} onChange={(e) => {setSignComment(e.target.value)}}></TextInput>
            </Modal.Body>
            <Modal.Footer>
            <button type="button" className="btn btn-dark" onClick={() => {
                doSignOrReject();
            }}>Продолжить</button>&nbsp;&nbsp;&nbsp;
            <button type="button" className="btn btn-outline-dark" onClick={() => {setShowSignComment(false);}}>Закрыть</button>
            </Modal.Footer>
        </Modal>}
            <Header name={props.name} hasRefresh={props.hasRefresh} button_name={props.button_name} buttons={props.buttons} button_disabled={false} onButtonsClick={props.onButtonsClick} onRefresh={onRefresh} loading={loading} />
            <Tabs states={states} onChangeTab={onTabChanged} activeTab={currentTab} />
            {loading &&
            <>
                <Placeholder.Graph style={{ marginTop: 20 }} height={30} active />
                <Placeholder.Grid style={{ marginTop: 30 }} rows={5} columns={6} rowHeight={14} rowMargin={25} active />
            </>
            }
            {!loading && <>
            <Filters filters={filters} onChangeFilter={onFilterChanged} extraFilters={extraFilters} extraFilterHasValues={extraFilterHasValues} />
                <div style={{overflowY: 'hidden', overflowX: 'auto'}} className={'docs-list-container'}>
                    <table className={rows2show.length > 0 ? "table docs-list border-bottom" : "table docs-list"}>
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                {fields.map((item, i) => {
                                    let dType = '';
                                    let alignClass = '';
                                    if(item['displayType']!=undefined && item['displayType']!=null)
                                    {
                                        dType = item['displayType'];
                                    }
                                    if(dType == 'money' || dType == 'moneyNoSymbol')
                                    {
                                        alignClass = ' text-end';
                                    }
                                    
                                    return <th scope="col" key={"th"+i} className={"text-secondary fw-normal docs-list-header-col text-nowrap " + (sortColumn == item.name ? (sortColumnType > 0 ? "sorted" : "sorted-desc") : "unsorted") + alignClass}
                                    onClick={() => {
                                        if(sortColumn == item.name)
                                        {
                                            setSortColumnType(-sortColumnType);
                                        } else {
                                            setSortColumn(item.name);
                                        }
                                    }} >
                                        {item.caption}
                                    </th>
                                } )}
                            </tr>
                        </thead>
                        <tbody>
                            {rows2show.map((r, i) => {
                                const canSign = r.approver > 0 && r.steptype!=1;
                                const canReject = r.approver > 0 && r.steptype!=2;

                                return <tr key={"tr"+i}>
                                    <td className="border-bottom-0 text-body actions-list-colummn">
                                        <div>
                                            {signingDocs.indexOf(r.documentid)>=0 && <Loader size="xs" style={{ marginTop: -3}}/>}
                                            {signingDocs.indexOf(r.documentid)<0 && <img src={canSign ? sign : signDisabled} className={canSign ? "enabled-action" : ""} onClick={ () => {
                                                if(canSign)
                                                {
                                                    setSignComment('');
                                                    signDocument(r.documentid);
                                                    setDocToSignVersion(r.version);
                                                }
                                            } }></img>}
                                            {signingDocs.indexOf(r.documentid)<0 && <img src={canReject > 0 ? reject : rejectDisabled} className={canReject ? "enabled-action" : ""} onClick={ () => {
                                                if(canReject)
                                                {
                                                    setSignComment('');
                                                    rejectDocument(r.documentid);
                                                    setDocToSignVersion(r.version);
                                                }
                                            } }></img>}
                                        </div>
                                    </td>
                                    {fields.map( (f, j) => {
                                        let dType = '';
                                        if(f['displayType']!=undefined && f['displayType']!=null)
                                        {
                                            dType = f['displayType'];
                                        }
                                        if(dType == 'money' || dType == 'moneyNoSymbol')
                                        {
                                            return <td className="border-bottom-0 text-body float-end" key={"tr"+i+"td"+j}><NumericFormat value={r[f.name]} thousandSeparator="'" displayType="text" decimalScale={2} suffix={dType == 'moneyNoSymbol' ? '' : ' '+r[currencyFieldName]} /></td>
                                        } else {
                                            if(f.name == 'id' || f.name == 'idname')
                                            {
                                                return <td className="border-bottom-0 text-body" key={"tr"+i+"td"+j}><Link to={(props.docPrefixLink ?? '/') +r['id']+"?page="+currentPage+"&tab="+currentTab} className="nounderline">{r[f.name]}</Link></td>
                                            } else {
                                                return <td className="border-bottom-0 text-body" key={"tr"+i+"td"+j}>{r[f.name]}</td>
                                            }
                                        }
                                    } )}
                                </tr>
                            } )}
                        </tbody>
                    </table>
                </div>
            {rows2show.length > 0 &&
            <div className="ms-auto paging-footer">
                <div className="text-secondary">
                {i18n.t('Всего')} {getDocWord(rowsFiltered.length)}
                </div>
                <Pagination className="ms-auto">
                    <Pagination.First onClick={() => {pageChanged(0)}} />
                    <Pagination.Prev onClick={prevPage} />
                    {pages.map( (i) => {
                        if(i>0 && i == currentPage - pageNeighbours - 1)
                        {
                            return  <Pagination.Ellipsis key={"pelb"} />;
                        }
                        if(i<pages.length-1 && i == currentPage + pageNeighbours +1)
                        {
                            return  <Pagination.Ellipsis key={"pela"} />;
                        }
                        if(i == 0 || (i >= currentPage - pageNeighbours && i <= currentPage + pageNeighbours) || i == pages.length-1)
                        {
                            return <Pagination.Item key={"dlp"+i} {...( i === currentPage ? {active: 'active'} : {}) } onClick={() => { pageChanged(i)}} >{i+1}</Pagination.Item>
                        }
                    } )}

                    <Pagination.Next onClick={nextPage} />
                    <Pagination.Last onClick={() => {pageChanged(totalPages-1)}} />
                </Pagination>
                <List options={pageOptions} placeholder={"50 / page"} val={rowsPerPage}
                    key={"pgs"} lkey={"lpgs"} onChangedItem={(e) => {setRowsPerPage(e)}}
                    menuPlacement="top" />
            </div>
            }
            {rows2show.length == 0 && <div className="d-flex flex-column ms-auto me-auto mt-auto mb-auto empty-list">
                <div className="mt-auto mb-auto ms-auto me-auto d-flex flex-column">
                    <img className="ms-auto me-auto" src={emptyBox}></img>
                    <span>{i18n.t('Список пуст')}</span>
                </div>
                </div>}
            </>}
        </>
    );
}