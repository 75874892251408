import * as React from "react";
import {useState, useEffect} from "react";
import Http from "../../utils/Http.js";
import i18n from "../../utils/Locale.js";
import { useParams } from 'react-router';
import DocumentHeader from "../../components/document/DocumentHeader.js";
import DocHeaderItem from "../../components/document/DocHeaderItem.js";
import DocumentFiles from "../../components/document/DocumentFiles.js";
import DocumentSigns from "../../components/document/DocumentSigns.js";
import '../../Projects.scss';

export default function Project(props) {
  const [loadingData, setLoadingData] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [loadingSigns, setLoadingSigns] = useState(true);
  const [loadingSignsHistory, setLoadingSignsHistory] = useState(true);
  const [savingData, setSavingData] = useState(false);
  const [editing, setEditing] = useState(false);
  const [changed, setChanged] = useState(false);
  const http = Http.GetInstance();
  const params= useParams();
  const [doc, setDoc] = useState({});
  const [docCompanies, setDocCompanies] = useState([]);
  const [docFiles, setDocFiles] = useState([]);
  const [docSigns, setDocSigns] = useState([]);
  const [docSignsHistory, setDocSignsHistory] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const [clients, setClients] = useState([]);
  const [clientsLoaded, setClientsLoaded] = useState(false);
  const [brands, setBrands] = useState([]);
  const [brandsLoaded, setBrandsLoaded] = useState(false);
  const [period, setPeriod] = useState(null);

  const onEditClicked = () => {
    if(editing)
    {
      setChanged(false);
    }
    setEditing(!editing);
  }

  const onSaveClicked = () => {
    setSavingData(true);
    let postData = {
      project: {
          data: { project_companies: docCompanies },
          options: doc,
          files: docFiles,
        }
      };
    http.SaveProject(postData, (resp) => {
      if(resp.data.errorcode !== "00")
      {
        alert("Error!" + resp.data.errortext);
      } else {
        /*
        setDoc(resp.data.project.project.options);
        setDocCompanies(resp.data.project.project.data.project_companies);
        */
        setSavingData(false);
      }
    });
    // saveProject
  }

  const getProjectData = () => {
    if(!loadingData)
    {
      setLoadingData(true);
      setLoadingFiles(true);
      setLoadingSigns(true);
      setLoadingSignsHistory(true);
      http.GetProjectData(params.id, (resp) => {
        if(resp.data.errorcode !== "00")
        {
          alert("Error! "+resp.data.errortext);
        } else {
          let docId = resp.data.project.project.options.documentid;
          let startDate = resp.data.project.project.options.startdate;
          let endDate = resp.data.project.project.options.enddate;
          setDoc(resp.data.project.project.options);
          if(startDate!=null && endDate!=null)
          {
            setPeriod([new Date(startDate), new Date(endDate)]);
          }
          setDocCompanies(resp.data.project.project.data.project_companies);
          http.GetFilesList(docId, (r) => {
            if(r.data.errorcode !== "00")
            {
              alert("Error! "+r.data.errortext);
            } else {
              setDocFiles(r.data.list);
            }
            setLoadingFiles(false);
          });
          //documentHistory
          // approvalStage
          http.GetSigns(docId, (r) => {
            if(r.data.errorcode !== "00")
            {
              alert("Error! "+r.data.errortext);
            } else {
              setDocSigns(r.data.approvalStage);
            }
            setLoadingSigns(false);
          });
          http.GetSignsHistory(docId, (r) => {
            if(r.data.errorcode !== "00")
            {
              alert("Error! "+r.data.errortext);
            } else {
              setDocSignsHistory(r.data.documentHistory);
            }
            setLoadingSignsHistory(false);
          });
        }
        setLoadingData(false);
      });
    }
  }

  const loadFilesCallBack = (list) => {
    setDocFiles(list);
  }

  useEffect(() => {
    getProjectData();
    http.GetDict("clientsList", (resp) => {
      if(resp.data.errorcode !== "00")
      {
        alert("Error!");
      } else {
        setClientsLoaded(true);
        setClients(resp.data.clientsList);
      }
    });
    http.GetDict("brandsList", (resp) => {
      if(resp.data.errorcode !== "00")
      {
        alert("Error!");
      } else {
        setBrandsLoaded(true);
        setBrands(resp.data.brandsList);
      }
    });
  }, []);

  const onChangeItem = (val, item, arrayItem) => {
    let newDoc = doc;
    if(item == "period")
    {
      newDoc["startdate"] = val[0].toISOString();
      newDoc["enddate"] = val[1].toISOString();
      setPeriod(val);
    } else {
      newDoc[item] = val;
    }
    if(!changed)
    {
      setChanged(true);
    }
    setDoc(newDoc);
  }

  const nameItem = {title: i18n.t("Наименование"), val: doc.name, name: 'name'};
  const managerItem = {title: i18n.t("Менеджер проекта"), val: doc.managerid, name: 'managerid', label: 'manager_full_name', text: doc.manager_full_name};
  const clientItem = {title: i18n.t("Клиент"), val: doc.clientid, name: 'clientid', label: 'client_name', text: doc.client_name};
  const brandItem = {title: i18n.t("Бренд"), val: doc.brandid, name: 'brandid', label: 'brand_name', text: doc.brand_name};
  const periodItem = {title: i18n.t("Срок действия"), val: period, name: 'period'};
  const closeDateItem = {title: i18n.t("Закрытие проекта"), val: doc.closedate, name: 'closedate'};
  const commentsItem = {title: i18n.t("Комментарий"), val: doc.description, name: 'description'};

  const tabChanged = (tab) => {
    if(currentTab!=tab)
    {
      setCurrentTab(tab);
    }
  }

  useEffect(() => {
    //
  },
  [currentTab]);

  let innerData = <></>;
  switch(currentTab)
  {
    case 0:
      innerData =   <>
      <div className="doc-body-header">
      <div className="d-flex flex-column">
        <div className="d-flex flex-grow-1">
          <DocHeaderItem item={nameItem} editing={editing} onChange={onChangeItem} />
        </div>
        <div className="d-flex justify-content-between">
          <DocHeaderItem item={managerItem} type="list" editing={editing} onChange={onChangeItem} />
          <DocHeaderItem item={clientItem} items={clients} type="list" editing={editing} onChange={onChangeItem} />
          <DocHeaderItem item={brandItem} items={brands} type="list" editing={editing} onChange={onChangeItem} />
        </div>
        <div className="d-flex justify-content-between">
          <div className="doc-body-header-line">
            <div>&nbsp;</div>
          </div>
          <DocHeaderItem item={periodItem} type="period" editing={editing} onChange={onChangeItem} />
          <DocHeaderItem item={closeDateItem} type="date" editing={editing} onChange={onChangeItem} />
        </div>
        <div className="d-flex">
          <DocHeaderItem item={commentsItem} editing={editing} onChange={onChangeItem} />
        </div>
      </div>
    </div>

    <div className="doc-body-body">
      {docCompanies.map((item, i) => {
        return <div key={"pb"+i} className="d-flex flex-column">
          <div className="d-flex company-name">
            <span>{item.company_name}</span>
          </div>
          <div className="d-flex justify-content-between">
            <DocHeaderItem item={{title: i18n.t("Норма прибыли"), name: "margin", val: item.margin}} editing={editing} onChange={onChangeItem} />
            <DocHeaderItem item={{title: i18n.t("Руководитель агентства"), name: "PROJECT_MANAGER_COMPANY", val: item.PROJECT_MANAGER_COMPANY}} editable={false} editing={editing} />
            <DocHeaderItem item={{title: i18n.t("Аккаунт-директор"), name: "PROJECT_ACCOUNT_DIRECTOR", val: item.PROJECT_ACCOUNT_DIRECTOR}} editable={false} editing={editing} />
          </div>
          <div className="d-flex">
            <DocHeaderItem item={{title: i18n.t("Проектная группа"), name: "PROJECT_TEAM_COMPANY", val: item.PROJECT_TEAM_COMPANY}} editable={false} editing={editing} />
          </div>
        </div>
      })}
    </div>
    </>;
      break;
    case 1:
      innerData = <>
      <DocumentFiles items={docFiles} documentId={doc.documentid} editing={editing} loadFilesCallBack={loadFilesCallBack} />
      </>;
      break;
    case 2:
      innerData = <>
      <DocumentSigns activeSigns={docSigns} historySigns={docSignsHistory} documentId={doc.documentid} editing={editing} />
      </>;
      break;
  }
  return (<>
    <DocumentHeader onTabChange={tabChanged} editing={editing} hasChanges={changed} listName={i18n.t('Проекты')} listLink="/projects" docTitle={i18n.t('Проект')} onEditClicked={onEditClicked} onSaveClicked={onSaveClicked} status={doc.status_name} currentTab={currentTab} />
    {innerData}
  </>
  );
}