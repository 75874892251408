import * as React from "react";
import { useState } from "react";
import '../Login.scss';

export default function Login({isLoggedIn, onLogin, onLogout}) {

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const loggedIn = isLoggedIn ?? false;

    return <>
        {!loggedIn && <div className="login">
        <div className="logo-back"></div>
        <div className="login-rights-reserved">© 2018-2023 All Rights Reserved.</div>
        <div className="login-modal">
            <input type="text" className="login-login" placeholder="Логин или e-mail" value={login} onChange={(e) => { setLogin(e.target.value) } } />

            <input type="password" className="login-login login-password" placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value) } } />
            <button className="btn btn-lg btn-primary login-button btn-block " type="button" onClick={() => {onLogin(login, password)}}>Вход</button>
            <div className="login-restore">Восстановить пароль</div>
        </div>
    </div>}
    </>;
}
