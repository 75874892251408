const defaultQNTItem = {id: 14, code: 796, name: "Шт.", value: 1, active: 1, is_default_estimate_position: 1};
const defaultQNT = [{id: 0, name: "", value: 1, active: 1, selectNames: defaultQNTItem}, { id: 1, name: "", value: "", active: 1}];

window.defaultQNTItem = defaultQNTItem;

class EstimateStructureEvents {

    onAddGroup(doc, docBody) {
        if(docBody.groups == undefined || docBody.groups == null)
        {
          docBody.groups = [];
        }
        docBody.groups.push({active: 1, estimateid: doc.id, id: null, name:'', agency_rate: 0, subgroups:[this.newDefaultSubgroup(doc, null)]});
        this.onChangeEvent();
      }

    newDefaultSubgroup(doc, group) {
      return JSON.parse(JSON.stringify({active: 1, groupid: group == null ? null : group.id, id: null, queue: group == null ? 0 : group.subgroups.length, name:'', agency_rate: 0, lines:[this.newDefaultLine(doc, null)]}));
    }
    
    onAddSubGroup(doc, docBody, group) {
        group.subgroups.push(this.newDefaultSubgroup(doc, null));
        this.onChangeEvent();
    }

    newDefaultLine(doc, subgroup) {
      return JSON.parse(JSON.stringify({active: 1, queue: subgroup == null ? 0 : subgroup.lines.length, name:'', agency_rate: 0, positions:[{queue: 0, in: { id: null, onedit: 0, currencyid: window.defaultCurrencyId, rate: 1, item: '', qnt_multiplicator: 1, amount: 0, vat: 20, qnt_formula: this.newDefaultQnt(), justCreated: true, options:{amount_accuracy:2, subtotal_accuracy: 2} }, out: { id: null, onedit: 0, currencyid: window.defaultCurrencyId, rate: 1, item: '', qnt_multiplicator: 1, amount: 0, vat: (doc.default_vat ?? 20), qnt_formula: this.newDefaultQnt(), justCreated: true, options:{amount_accuracy:2, subtotal_accuracy: 2} }}]}));
    }

    newDefaultQnt() {
        return JSON.parse(JSON.stringify(defaultQNT));
    }

    onAddLine(doc, docBody, subgroup, line) {
        let new_line = this.newDefaultLine(doc, subgroup);
        if(subgroup['id']!=undefined && subgroup.id!=null)
        {
          new_line.sub_group_id = subgroup.id;
        }
        let last_line = true;
        if(line == null)
        {
          subgroup.lines.push(new_line);
        } else {
          for(var i=0;i<subgroup.lines.length;i++)
          {
            let l = subgroup.lines[i];
            if(l == line)
            {
              if(i+1<subgroup.lines.length)
              {
                new_line.queue = i;
                subgroup.lines.splice(i+1, 0, new_line);
                for(var j=i+1;j<subgroup.lines.length;j++)
                {
                  subgroup.lines[j].queue = j;
                }
                last_line = false;
              } else {
                subgroup.lines.push(new_line);
              }
              break;
            }
          }
        }
        let newDocBody = docBody;
        if(!last_line)
        {
            newDocBody = JSON.parse(JSON.stringify(docBody));
        }
        this.onChangeEvent(newDocBody, true);
      }
    
      onAddInSubline(doc, docBody, line){
        let new_subline = { onedit: 0, currencyid: window.defaultCurrencyId, rate: 1, item: '', qnt_multiplicator: 1, amount: 0, vat: 20, qnt_formula: this.newDefaultQnt(), options:{amount_accuracy:2, subtotal_accuracy: 2} };
        let outCnt = 0, inCnt = 0;
        for(var i=0;i<line.positions.length;i++)
        {
          if(line.positions[i]['out'] !== undefined)
          {
            outCnt++;
          }
          if(line.positions[i]['in'] !== undefined)
          {
            inCnt++;
          }
        }
        if(inCnt == outCnt && outCnt == 1)
        {
          line.positions.push({queue: line.positions.length, in: JSON.parse(JSON.stringify(line.positions[0].in)) });
          line.positions.push({queue: line.positions.length, in: new_subline });
          delete line.positions[0].in;
          this.onChangeEvent(docBody, true);
      } else {
          if(outCnt == 1)
          {
            line.positions.push({queue: line.positions.length, in: new_subline });
            this.onChangeEvent(docBody, true);
          }
        }
      }
    
      onAddOutSubline(doc, docBody, line) {
        let new_subline = { onedit: 0, currencyid: 72, rate: 1, item: '', qnt_multiplicator: 1, amount: 0, vat: (doc.default_vat ?? 20), qnt_formula: this.newDefaultQnt(), options:{amount_accuracy:2, subtotal_accuracy: 2} };
        let outCnt = 0, inCnt = 0;
        for(var i=0;i<line.positions.length;i++)
        {
          if(line.positions[i]['out'] !== undefined)
          {
            outCnt++;
          }
          if(line.positions[i]['in'] !== undefined)
          {
            inCnt++;
          }
        }
        if(inCnt == outCnt && outCnt == 1)
        {
          line.positions.push({queue: line.positions.length, out: JSON.parse(JSON.stringify(line.positions[0].out)) });
          line.positions.push({queue: line.positions.length, out: new_subline });
          delete line.positions[0].out;
          this.onChangeEvent(docBody, true);
        } else {
          if(inCnt == 1)
          {
            line.positions.push({queue: line.positions.length, out: new_subline });
            this.onChangeEvent(docBody, true);
          }
        }
      }
    
    // Delete elements from estimate

    removeElementFromArray (array, item) {
        let index = array.indexOf(item);
        if (index !== -1) {
          array.splice(index, 1);
        }
        this.onChangeEvent(null, true);
      }

      onDeleteGroup (docBody, recycleBin, group) {
        if(group["id"] !== undefined && group.id !== "" && group.id !== 0 && group.id !== "0")
        {
          if(recycleBin["groups"] === undefined)
          {
            recycleBin["groups"] = [];
          }
          recycleBin.groups.push(group);
        }
        this.removeElementFromArray(docBody.groups, group);
      }
    
      onDeleteSubgroup(docBody, recycleBin, group, subgroup) {
        if(subgroup["id"] !== undefined && subgroup.id !== "" && subgroup.id !== 0 && subgroup.id !== "0")
        {
          if(recycleBin["subgroups"] === undefined)
          {
            recycleBin["subgroups"] = [];
          }
          recycleBin.subgroups.push(subgroup);
        }
        this.removeElementFromArray(group.subgroups, subgroup);
      }

      onDeleteLine (docBody, recycleBin, subgroup, line) {
        if(line["id"] !== undefined && line.id !== "" && line.id !== 0 && line.id !== "0")
        {
          if(recycleBin["lines"] === undefined)
          {
            recycleBin["lines"] = [];
          }
          recycleBin.lines.push(line);
        }
        this.removeElementFromArray(subgroup.lines, line);
      }

      onDeleteInSubline (docBody, recycleBin, subgroup, line, queue) {
        let pos = line.positions[queue];
        if(pos["id"] !== undefined && pos.id !== "" && pos.id !== 0 && pos.id !== "0")
        {
          if(recycleBin["insublines"] === undefined)
          {
            recycleBin["insublines"] = [];
          }
          recycleBin.insublines.push(pos);
        }
        this.removeElementFromArray(line.positions, pos);
        if(line.positions.length == 2)
        {
          line.positions[0].in = line.positions[1].in;
          this.removeElementFromArray(line.positions, line.positions[1]);
        }
      }

      onDeleteOutSubline (docBody, recycleBin, subgroup, line, queue) {
        let pos = line.positions[queue];
        if(pos["id"] !== undefined && pos.id !== "" && pos.id !== 0 && pos.id !== "0")
        {
          if(recycleBin["outsublines"] === undefined)
          {
            recycleBin["outsublines"] = [];
          }
          recycleBin.outsublines.push(pos);
        }
        this.removeElementFromArray(line.positions, pos);
        if(line.positions.length == 2)
        {
          line.positions[0].out = line.positions[1].out;
          this.removeElementFromArray(line.positions, line.positions[1]);
        }
      }
  }

let estimateStructureEvents = new EstimateStructureEvents();

export default estimateStructureEvents;