import axios from "axios";

export default class Http {

    static _instance = null;

    constructor() {
        this.baseUrl = process.env.REACT_APP_BACK_URL;
        this.urlWebSocket = process.env.REACT_APP_WS_URL;
        axios.defaults.withCredentials = true;
        this.sid = localStorage.getItem("sid");
        this.webSocketReceivers = [];
        this.StartWebSocket();
    }

    static GetInstance()
    {
        if(this._instance == null)
        {
            this._instance = new Http();
        }
        return this._instance;
    }

    StartWebSocket()
    {
        this.ws = new WebSocket(this.urlWebSocket);
        this.ws.onerror = function(e) {
            console.error(`Ошибка WS: ${e}`);
        };
        this.ws.onopen = function(e) {
            console.log("Сообщение WS: соединение открыто", e);
        };
        const t = this;
        this.ws.onmessage = function(e) {
            console.log("Received: "+e.data);
            t.webSocketReceivers.forEach((r) => {
                r.func(JSON.parse(e.data));
            });
        };
    }

    getUID() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }
    
    setUIDForItem(item, force) {
        let UIDName = "UID";
        if(item[UIDName] === undefined || force === true) {
            item[UIDName] = this.getUID();
        }
    }
    
    SetReceiver(func) {
        let newReceiver = {
            func: func
        };
        this.setUIDForItem(newReceiver);
        this.webSocketReceivers.push(newReceiver);
        return newReceiver.UID;
    };

    UnsetReceiver(UID) {
        this.webSocketReceivers = this.webSocketReceivers.filter((r) => r.UID !== UID);
    };


    GetBaseUrl()
    {
        return this.baseUrl;
    }

    GetFileUrl()
    {
        return this.GetBaseUrl()+'/backend/api/api-gateway.php?attachement';
    }

    SetSID(newSid)
    {
        this.sid = newSid;
        localStorage.setItem("sid", newSid);
    }

    ClearSID()
    {
        this.sid = '';
        localStorage.removeItem("sid");
    }

    Login(username, pass, callBack)
    {
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?auth", {
            command: "auth", login: username, password: pass
        }).then((response) => {
            if(response.data.errorcode === "00")
            {
                this.SetSID(response.data.sessionid);
            }
           callBack(response); 
        });
    }

    UserInfo(callBack)
    {
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?auth", {
            command: "getUserInfoBySid", sid: this.sid
        }).then((response) => {
            callBack(response); 
        }).catch(() => {
            callBack(false);
        });
    }

    CheckAuth(callBack)
    {
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?auth", {
            command: "checkAuth", sid: this.sid
        }).then((response) => {
            callBack(response); 
        }).catch(() => {
            callBack(false);
        });
    }

    GetBaseList(urlPart, postCommand, callBack)
    {
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?"+urlPart, {
            command: postCommand,
            sid: this.sid,
        }).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    GetBaseDocument(urlPart, postCommand, idName, id, callBack)
    {
        let postData = {
            command: postCommand,
            sid: this.sid
        };
        postData[idName] = id;
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?"+urlPart, postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    GetEstimateExcel(urlPart, postCommand, idName, id, callBack)
    {
        let postData = {
            command: postCommand,
            sid: this.sid
        };
        postData[idName] = id;
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?"+urlPart, postData,
        {
            responseType: "arraybuffer"
        }).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    PostRequest(uri, data, callBack, opts = null)
    {
        data.sid = this.sid;
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?"+uri, {...data}, opts).then((response) => {
            callBack(response); 
        }).catch(() => {
            callBack(false);
        });
    }

    GetDict(postCommand, callBack)
    {
        let postData = {
            command: postCommand,
            sid: this.sid
        };
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?mdg", postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    GetFilesList(id, callBack)
    {
        let postData = {
            command: "getList",
            documentid: id,
            sid: this.sid
        };
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?attachement", postData).then((response) => {
            if((response["status"] ?? 500) === 200 && response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        }).catch((error) => {
            callBack({data: {errorcode: error.message, errortext: error.message}});
        });
    }

    UploadFile(documentId, file, callBack)
    {
        let formData = new FormData();

        for(let key in file) {
            formData.append(key, file[key]);
        }
        formData.append("sid", this.sid);

        axios.post(this.baseUrl + "/backend/api/api-gateway.php?attachement=&upload="+documentId+"&sid="+this.sid, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    DownloadFile(documentId, fileName, callBack)
    {
        this.PostRequest("attachement", 
            {command: 'getFile', documentid: documentId, filename: fileName, sid: this.sid }, 
                    callBack, { responseType: "arraybuffer" });
    }

    OpenFile(documentId, fileName, callBack)
    {
        this.PostRequest("attachement", 
            {command: 'getFile', documentid: documentId, filename: fileName, open: true, sid: this.sid }, 
                callBack, { responseType: "arraybuffer" });
    }

    DeleteFile(documentId, fileName, callBack)
    {
        this.PostRequest("attachement", 
            {command: 'deleteFile', documentid: documentId, filename: fileName, sid: this.sid }, 
        callBack);
    }

    GetSigns(id, callBack)
    {
        let postData = {
            command: "getApprovalStage",
            documentid: id,
            sid: this.sid
        };
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?document", postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    GetSignsHistory(id, callBack)
    {
        let postData = {
            command: "getHistory",
            documentid: id,
            sid: this.sid
        };
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?document", postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    GetDocumentData(id, callBack)
    {
        let postData = {
            group: [
                    {command: 'getApproversList',documentid: id,},
                    {command: 'getDocumentDetails',documentid: id,},
                    {command: 'getHistory',documentid: id,},
                    {command: 'checkChangeStatusBeforeSave',documentid: id,},
                    {command: 'isUserApprover',documentid: id, userid: window.user.userid},
                    {command: 'enableDelete',documentid: id, userid: window.user.userid}
                ],
            sid: this.sid,
        };
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?document&group=1", postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    GetDocumentRights(id, callBack)
    {
        let postData = {
            group: [
                    {command: 'getOptionsUserAccess',documentid: id,}
                ],
            sid: this.sid,
        };
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?ecm&group=1", postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    GetUser4Redirect(callBack)
    {
        let postData = {
            command: 'getUserListByGrantCode',
            grantcode: "APPROVER",
            sid: this.sid,
        };
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?user", postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    SaveDocument(document, postCommand, postData, callBack)
    {
        postData['command'] = postCommand;
        postData['sid'] = this.sid;
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?" + document, postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    DeleteDocument(id, callBack)
    {
        let postData = {};
        postData['command'] = "deleteDocument";
        postData['sid'] = this.sid;
        postData['documentid'] = id;
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?document", postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    LockDocument(id, callBack)
    {
        let postData = {
            command: 'lockDocument',
            documentid: id,
            sid: this.sid,
        };
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?document", postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response);
        });
    }

    UnlockDocument(id, callBack)
    {
        let postData = {
            command: 'unlockDocument',
            documentid: id,
            sid: this.sid,
        };
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?document", postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    IsLockDocument(id, callBack)
    {
        let postData = {
            command: 'isLockedDocument',
            documentid: id,
            sid: this.sid,
        };
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?document", postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    GetDocumentVersion(id, callBack)
    {
        let postData = {
            command: 'getVersion',documentid: id,
            sid: this.sid,
        };
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?document", postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    DocumentSignEnableWarning(id, callBack)
    {
        let postData = {
            command: 'enableSignWarning',
            documentid: id,
            sid: this.sid,
        };
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?document", postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    DocumentSign(id, approve, adduser, adduserId, comment, version, callBack)
    {
        let postData = {
            command: adduser ? 'addApprover' : (approve ? 'doApprove' : 'doReject'),
            documentid: id,
            comment: comment,
            message: comment,
            userid: adduserId,
            version: version,
            sid: this.sid,
        };
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?document", postData).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }
    /* Agreements */
    GetAgreementsList(callBack)
    {
        this.GetBaseList("agreement", "getAgreementList", callBack);
    }
    /* /Agreements */

    /* Estimates */
    GetEstimatesList(callBack)
    {
        this.GetBaseList("estimate", "getEstimateList", callBack);
    }
    GetEstimateData(id, callBack)
    {
        this.GetBaseDocument("estimate", "getFullEstimateById", "estimateid", id, callBack);
    }

    async GetEstimateDicts(callBack)
    {
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?mdg=&group=1", {
            sid: this.sid,
            group: [
                { command: "metricsList" },
                { command: "einvoiceList" },
                { command: "yearsList" },
                { command: "getCurrencyActualList" },
                { command: "companyListActive" },
                { command: "leList"}
            ]
        }).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }

    async GetEstimateManagersList(callBack)
    {
        this.PostRequest('user', {command: 'getUserListByGrantCode', grantcode: 'ESTIMATE'}, callBack);
    }

    async GetEstimateProjectsList(callBack)
    {
        this.PostRequest('project', {command: 'getProjectListForEstimate'}, callBack);
    }

    SaveEstimate(data, callBack)
    {
        this.SaveDocument("estimate", "saveEstimate", data, callBack);
    }

    async GetEstimateBlocksList(callBack)
    {
        this.PostRequest('estimate&group=1', {group:[{command:"getAllBlock"},{command:"getBlockTree"}]}, callBack);
    }

    async GetInSublineExpenses(id, callBack)
    {
        axios.post(this.baseUrl + "/backend/api/api-gateway.php?estimate", {
            sid: this.sid,
            command: "getExpensesByLineInposition",
            id: id,
            isReport: 0,
        }).then((response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.sessionid!==undefined)
                {
                    this.SetSID(response.data.sessionid);
                }
            }
           callBack(response); 
        });
    }
    /* /Estimates */

    /* Projects */
    GetProjectsList(callBack)
    {
        this.GetBaseList("project", "getProjectList", callBack);
    }

    GetProjectData(id, callBack)
    {
        this.GetBaseDocument("project", "getFullProjectById", "projectid", id, callBack);
    }

    SaveProject(data, callBack)
    {
        this.SaveDocument("project", "saveProject", data, callBack);
    }
    /* /Projects */

}