import * as React from "react";
import {memo} from "react";
import {useState} from "react";
import CurrencyText from "../../controls/CurrencyText";
import Qnt from "./Qnt";
import List from "../../controls/List";
import PercentText from "../../controls/PercentText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { TreePicker, Dropdown } from 'rsuite';
import TextareaAutosize from 'react-textarea-autosize';
import DateDropDown from "../../controls/DateDropDown";
import Http from "../../../utils/Http.js";
import TextInput from "../../controls/TextInput.js";
import {Link} from "react-router-dom";
import { Modal } from 'rsuite';

const InSubline = memo(function InSubline({parentLine, item, editing, text, index, leList, amount, vat, 
                line, analyticsCalced, amountTotal, collapseIn, collapseOut, collapseAnalytics, qnt_multiplicator,
                canMakeExpense, inCnt, estimateType, checkFailed, options, accuracyList})
{
    const [treeOpened, setTreeOpened] = useState(false);
    const [showExpenseData, setShowExpenseData] = useState(false);
    const [expenseData, setExpenseData] = useState([]);
    const [expenseDataFiltered, setExpenseDataFiltered] = useState([]);
    const [expSearchValue, setExpSearchValue] = useState("");

    const http = Http.GetInstance();

    const onChangeItem = (e) => {
        window.onChangeSublineData(parentLine, item, 'item', e.target.value);
    }

    const onChangeAmount = (val) => {
        console.log('from in amount');
        window.onChangeSublineData(parentLine, item, 'amount', val);
    }

    const onChangeTotal = (val) => {
        if(qnt_multiplicator != 0 )
        {
            let amount = val / qnt_multiplicator;
            console.log('from in total');
            window.onChangeSublineData(parentLine, item, 'amount', amount);
        }
    }

    const onChangeVAT = (val) => {
        window.onChangeSublineData(parentLine, item, 'vat', val);
    }

    const onChangeQnt = (val, sum) => {
        window.onChangeSublineData(parentLine, item, 'qnt_multiplicator', sum);
        window.onChangeSublineData(parentLine, item, 'qnt_formula', val);
    }

    const onChangePaymentDate = (e) => {
        let vAr = e.toISOString().slice(0, 10).split('-');
        let v = vAr[2]+"."+vAr[1]+"."+vAr[0];
        window.onChangeSublineData(parentLine, item, 'paymentdate', v);
    }

    const onChangeRate = (val) => {
        window.onChangeSublineData(parentLine, item, 'rate', val);
    }

    const onChangeCurrency = (val) => {
        window.onChangeSublineData(parentLine, item, 'currencyid', val);
        window.onChangeSublineData(parentLine, item, 'rate', window.currencyListHashId[val].rate);
    }

    const onChangeAmountAccuracy = (val) => {
        window.onChangeSublineData(parentLine, item, 'options', {...options, amount_accuracy: val});
    }

    const onChangeSubtotalAccuracy = (val) => {
        window.onChangeSublineData(parentLine, item, 'options', {...options, subtotal_accuracy: val});
    }

    const toggleTree = () => {
        setTreeOpened(!treeOpened);
    }

    const onChangeEinv = (v) => {
        if(!(v+'').startsWith('x'))
        {
            let vId = v * 1;
            let invItem = window.window.einvoiceList.filter((x) => x.id == vId);
            if(invItem.length>0)
            {
                window.onChangeSublineData(parentLine, item, 'inv', invItem[0].code);
                window.onChangeSublineData(parentLine, item, 'mdg_estimate_invoices_id', vId);
                setTreeOpened(false);
            }
        }
    }

    const viewExpenses = () => {
        http.GetInSublineExpenses(item.id, (response) => {
            if(response.data.errorcode === "00")
            {
                setExpenseData(response.data.expenseList);
                setExpenseDataFiltered(response.data.expenseList);
                setShowExpenseData(true);
            }
        })
    }

    let paymentdate = null;
    if(item.paymentdate !== undefined && item.paymentdate !== null)
    {
        if(typeof(item.paymentdate) == "string")
        {
            let dateElements = item.paymentdate.split('.');
            paymentdate = new Date(dateElements[2]+"-"+dateElements[1]+"-"+dateElements[0]);
        } else {
            paymentdate = item.paymentdate;
        }
    }

    const onChangeLE = (val, label) => {
        window.onChangeSublineData(parentLine, item, 'le_id', val);
        window.onChangeSublineData(parentLine, item, 'le_name', label);
    }

    const expListColumns = ["№", "Тип", "Получатель", "Сумма с НДС", "НДС", "Создатель"];
    const expListColumnsFields = ["id", "type", "agent", "total_and_vat", "vat", "creator_full_name"];

    const searchList = (v) => {
        let filtered = expenseData.filter(i => {
            for(let j=0;j<expListColumnsFields.length;j++)
            {
                if((i[expListColumnsFields[j]]+"").indexOf(v)>=0)
                {
                    return true;
                }
            }
            return false;
        });
        setExpenseDataFiltered(filtered);
        setExpSearchValue(v);
    }

    const notreported = ((inCnt > 1 && item.rur_cost_amount_with_vat!=item.rur_reported_cost_amount) || (inCnt == 1 && line.costWithVatRub!=line.reportsWithVatRub)) ? " notreported" : "";

    const inv = item.inv ?? "\u00A0\u00A0\u00A0\u00A0";

    const renderAccAmount = (props, ref) => {
        return (
            <FontAwesomeIcon {...props} ref={ref} icon={faPen} />
        );
      };

    const currencyLabel = (((window.currencyListHashId ?? [])[item.currencyid ?? 72]) ?? {name: ''}).name;

    return <>
    <Modal open={showExpenseData} size='md' onClose={() => {setShowExpenseData(false);}}>
        <Modal.Header>
          <Modal.Title>Расходы по строке сметы</Modal.Title>
          <TextInput placeholder={"Все"} search={true}
                        value={expSearchValue}
                        onChange={(e) => {searchList(e.target.value)}} />
        </Modal.Header>
        <Modal.Body>
            <table className="table docs-list border-bottom">
                <thead>
                    <tr>
                        {expListColumns.map((el, elIdx) =>{
                            return <th key={"isl_exp_head_"+elIdx} className="text-secondary fw-normal docs-list-header-col text-nowrap">
                                {el}
                            </th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                {expenseDataFiltered.map((exp, expIdx) => {
                return <tr key={"isl_exp_"+expIdx}>
                    {expListColumnsFields.map((expItem, expItemIdx) => {
                        return <td key={"isl_exp_"+expIdx+"_d_"+expItemIdx} className="text-body">
                            {(expItem == "total_and_vat" || expItem == "vat") &&
                                <CurrencyText val={exp[expItem]} type={'text'} symbol={exp.symbol} />
                            }
                            {!(expItem == "total_and_vat" || expItem == "vat") && expItemIdx !=0 &&
                                <>{(expItem == "type" ? (exp[expItem] == "expense" ? "Расход" : "Отчет") : exp[expItem])}</>
                            }
                            {!(expItem == "total_and_vat" || expItem == "vat") && expItemIdx==0 &&
                                <Link className="nounderline" to={"/exp/"+exp[expItem]}>{exp[expItem]}</Link>
                            }
                        </td>
                    })}
                    </tr>;
                })}

                </tbody>
            </table>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => {setShowExpenseData(false);}}>Закрыть</button>
        </Modal.Footer>
    </Modal>
    <td className="in">{item.id}
    {editing &&  <>
        <Dropdown renderToggle={renderAccAmount}>
            <Dropdown.Item>Точность цены
                <List options={accuracyList} value={options.amount_accuracy ?? 2}
                                onChangedItem={(v, n) => {onChangeAmountAccuracy(v)}}/>
            </Dropdown.Item>
            <Dropdown.Item>Точность суммы
                <List options={accuracyList} value={options.subtotal_accuracy ?? 2}
                                 onChangedItem={(v, n) => {onChangeSubtotalAccuracy(v)}}/>
            </Dropdown.Item>
            <Dropdown.Item>Валюта
                <List options={window.currencyList} text={currencyLabel} value={item.currencyid}
                 onChangedItem={(v, n) => {onChangeCurrency(v)}}/>
            </Dropdown.Item>
            <Dropdown.Item>Курс
                <input className="qnt-input" type="numeric" inputMode="numeric" value={item.rate} onChange={(e) => {onChangeRate(e.target.value)}} />
            </Dropdown.Item>
        </Dropdown>
    </>}
    </td>
    {!collapseIn && <>
    <td className="in">&nbsp;</td>
    <td className="in">
        {editing &&  <>
            <span onClick={toggleTree}  className={"tree-picker-toggle" + (checkFailed && (item.mdg_estimate_invoices_id ?? '') == ''  ? " error" : "")}>{inv}</span>
            <TreePicker defaultExpandAll={false} size="xs" cleanable={false} plaintext={true}
             data={window.einvTree} value={item.mdg_estimate_invoices_id} menuClassName="tree-menu-class" open={treeOpened} onChange={onChangeEinv} />
        </>}
        {!editing && <>{item.inv}</>}
    </td>
    </>}
    <td className="in">
        {editing && <TextareaAutosize value={text} onChange={onChangeItem} className={(checkFailed && (text ?? '') == ''  ? " error" : "")} />}
        {!editing && <>{text}</>}
    </td>
    {!collapseIn && <>
    <td className="in qnt">
        <Qnt item={item.qnt_formula} index={index} editing={editing} onChange={onChangeQnt} />
    </td>
    <td className="in text-end">
        <CurrencyText val={amount} type={(editing ? 'input' : 'text')} onChange={onChangeAmount}
            currencyId={item.currencyid} accuracy={options.amount_accuracy ?? 2}  />
    </td>
    </>}
    <td className="in text-end">
        <CurrencyText val={amountTotal} type={(editing ? 'input' : 'text')} onChange={onChangeTotal} 
                currencyId={item.currencyid} accuracy={options.subtotal_accuracy ?? 2} />
    </td>
    {!collapseIn && <>
    <td className="in text-end">
        <PercentText val={vat} type={(editing ? 'input' : 'text')} onChange={onChangeVAT} />
    </td>
    <td className="in text-end"><CurrencyText val={item.amountVAT} currencyId={item.currencyid}/></td>
    <td className="in text-end"><CurrencyText val={item.amountAndVAT} currencyId={item.currencyid} /></td>
    <td className="in">
        {editing && <DateDropDown caption="" value={paymentdate} onChange={onChangePaymentDate} extraClass={" d-flex flex-column"+(checkFailed && (item.paymentdate ?? '') == ''  ? " error" : "")} cleanable={false} />}
        {!editing && <>{item.paymentdate ?? ""}</>}
    </td>
    <td className="in">
        {editing && <List options={leList} value={item.le_id} text={item.le_name} error={(checkFailed && (item.le_name ?? '') == '')} onChangedItem={onChangeLE} />}
        {!editing && <>{item.le_name ?? ""}</>}
    </td>
    <td className="in">
        <FontAwesomeIcon icon={faComment} />
        &nbsp;
        {canMakeExpense && <span className="rqbutton" title="Создать новый расход по строке">+RQ</span>}
    </td>
    </>}

    {inCnt == 1 && <>
        {!analyticsCalced && <td className="analytics" colSpan={collapseAnalytics ? 2 : 11}></td>}
        {analyticsCalced && <>
            {estimateType == 2  && <>
                {!collapseAnalytics && <>
                <td className="analytics text-end">
                    <CurrencyText val={item.amountRub} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.amountTotalRub} />
                </td>
                <td className="analytics text-end">
                    <PercentText val={line.planPrc} />
                </td>
                </>}
                <td className="analytics text-end">
                    <CurrencyText val={line.plan} />
                </td>
                {!collapseAnalytics && <>
                <td className="analytics text-end">
                    <PercentText val={line.factPrc} />
                </td>
                </>}
                <td className="analytics text-end">
                    <CurrencyText val={line.fact} />
                </td>
                {!collapseAnalytics && <>
                <td className="analytics text-end">
                    <CurrencyText val={(line.costRub ?? 0)} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={line.left} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={line.leftVat} />
                </td>
                <td className={"analytics text-end" + notreported}>
                    {line.costWithVatRub == 0 && <CurrencyText val={line.costWithVatRub} />}
                    {line.costWithVatRub != 0 && <div onClick={viewExpenses} className="insubline-expenses"><CurrencyText val={line.costWithVatRub} /></div>}
                </td>
                <td className={"analytics text-end" + notreported}>
                    <CurrencyText val={line.reportsWithVatRub} />
                </td>
                </>}
            </>}


            {estimateType != 2 && !collapseAnalytics && <>
                <td className="analytics text-end">
                    <CurrencyText val={item.amountRub} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.amountTotalRub} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={line.costRub} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={line.left} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={line.leftVat} />
                </td>
                <td className={"analytics text-end" + notreported}>
                    {line.costWithVatRub == 0 && <CurrencyText val={line.costWithVatRub} />}
                    {line.costWithVatRub != 0 && <div onClick={viewExpenses} className="insubline-expenses"><CurrencyText val={line.costWithVatRub} /></div>}
                </td>
                <td className={"analytics text-end" + notreported}>
                    <CurrencyText val={line.reportsWithVatRub} />
                </td>
            </>}

        </>}
    </>}

    {inCnt > 1 && <>
        {!analyticsCalced && <td className="analytics" colSpan={collapseAnalytics ? 2 : 11}></td>}
        {analyticsCalced && <>
            {!collapseAnalytics && <>
            <td className="analytics text-end">
                <CurrencyText val={item.amountRub} />
            </td>
            <td className="analytics text-end">
                <CurrencyText val={item.amountTotalRub} />
            </td>
            <td className="analytics text-end"></td>
            </>}
            <td className="analytics text-end"></td>
            {!collapseAnalytics && <td className="analytics text-end"></td>}
            <td className="analytics text-end"></td>
            {!collapseAnalytics && <>
            <td className="analytics text-end">
                <CurrencyText val={(item.rur_cost_amount ?? 0)} />
            </td>
            <td className="analytics text-end">
                <CurrencyText val={item.left} />
            </td>
            <td className="analytics text-end">
                <CurrencyText val={item.leftVat} />
            </td>
            <td className={"analytics text-end" + notreported}>
                {(item.rur_cost_amount_with_vat ?? 0) == 0 && <CurrencyText val={(item.rur_cost_amount_with_vat ?? 0)} />}
                {(item.rur_cost_amount_with_vat ?? 0) != 0 && <div onClick={viewExpenses} className="insubline-expenses"><CurrencyText val={(item.rur_cost_amount_with_vat ?? 0)} /></div>}
            </td>
            <td className={"analytics text-end" + notreported}>
                <CurrencyText val={(item.rur_reported_cost_amount ?? 0)} />
            </td>
            </>}
        </>}

    </>}
    </>
    ;
});

export default InSubline;
