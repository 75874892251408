import { React, useEffect, useState } from 'react';
import MenuItem from "./MenuItem";

import help from '../../resources/images/info-icon.svg';
import hugs from '../../resources/images/hugs.svg';
import admin from '../../resources/images/admin-icon.svg';
import chains from '../../resources/images/chains-icon.svg';

import i18n from "../../utils/Locale.js";
import Http from "../../utils/Http.js";

export default function Menu({menuVisible, userData, changeMenu, reloadMenu, onReloaded, substituted}) {

    const http = Http.GetInstance();

    const [loading, setLoading] = useState(true);
    const [menuItems, setMenuItems] = useState([]);
    const [substitutionList, setSubstitutionList] = useState([]);

    const loadSubstitutions = () => {
        http.PostRequest("userReplacement", {command: "list", all: false}, (response) => {
            if(response.data.errorcode === "00")
            {
                if(response.data.result == null)
                {
                    setSubstitutionList([]);
                } else {
                    setSubstitutionList(response.data.result);
                }
            } else {
                alert(response.data.error);
            }
          })
    }

    useEffect(() => {
        loadSubstitutions();
    }, [reloadMenu]);

    useEffect(() => {
        let items = [];
        const notOnlyEstimates = false;

        if(notOnlyEstimates)
        {
            items.push({name: i18n.t("Справка"), link: "/info", img: help });
        }

        if(userData!==undefined && userData.real_user==null && substitutionList.length>0 && notOnlyEstimates)
        {
            let substitutions = substitutionList.map((i, idx) => {
                return {name: i.whomname, link: "/substitute/"+i.id};
            })
            items.push({name: i18n.t("Заместитель"), img: hugs, children: substitutions });
        }
        if(isRoot() && notOnlyEstimates)
        {
            items.push({name: "Журналы", img: admin, link: '/logs'});
        }

        if((isRoot() || hasRight('USERS') || hasRight('MDG') || hasRight('ALTERNATE_LIST')) && notOnlyEstimates)
        {
            let adminSubmenu = [];
            if(isRoot() || hasRight('USERS'))
            {
                adminSubmenu.push({name: 'Пользователи', link: "/users"});
            }
            if(isRoot() || hasRight('ALTERNATE_LIST'))
            {
                adminSubmenu.push({name: 'Журнал замещений', link: "/alternate_list"});
            }
            if(isRoot() || hasRight('MDG'))
            {
                adminSubmenu.push({name: 'Подразделения', link: "/companies"});
                adminSubmenu.push({name: 'Должности', link: "/positions"});
                adminSubmenu.push({name: 'Константы', link: "/globals"});
                adminSubmenu.push({name: 'Шаблоны', link: "/globals_long"});
                adminSubmenu.push({name: 'Единицы измерений', link: "/metrics"});
                adminSubmenu.push({name: 'Валюты', link: "/currency"});
            }

            if(isRoot() || hasRight('USERS'))
            {
                adminSubmenu.push({name: 'Роли', link: "/grants"});
            }

            if(isRoot() || hasRight('MDG'))
            {
                adminSubmenu.push({name: 'Финансовые года', link: "/fy"});
                adminSubmenu.push({name: 'Департаменты', link: "/deps"});
                adminSubmenu.push({name: 'Группы планов счетов', link: "/blocks"});
                adminSubmenu.push({name: 'Планы счетов', link: "/invoices"});
                adminSubmenu.push({name: 'Внутренние юрлица', link: "/les"});
                adminSubmenu.push({name: 'Справочник типов контрагентов', link: "/orgtypes"});
                adminSubmenu.push({name: 'Справочник ставок НДС', link: "/vats"});
                adminSubmenu.push({name: 'Календарь платежей', link: "/calendar"});
                adminSubmenu.push({name: 'Справка', link: "/information"});
            }
            items.push({name: "Администрирование", img: admin, children: adminSubmenu});
        }

        if(isRoot() && notOnlyEstimates)
        {
            items.push({name: "Настройка согласованиий и грантов", img: admin, children: [
                {name: 'Согласования', link: "/ecm"},
                {name: 'Гранты', link: "/grants-control"},
                {name: 'Контроль функций', link: "/callback-func-control"},
                {name: 'Групповая обработка', link: "/change-step"},
                {name: 'Не вышедшие из подписания', link: "/not-leave-signing"},
                {name: 'Дубликаты заявок 1С', link: "/approval-dublicates"},
                {name: 'Устранение дублей контрагентов', link: "/unduplicate-contractor"},
            ]});
        }
        let financeItems = [];
        if((isRoot() || hasRight('PROJECT')) && notOnlyEstimates)
        {
            financeItems.push({name: 'Проекты', link: '/projects'})
        }
        if(isRoot() || hasRight('ESTIMATE'))
        {
            financeItems.push({name: 'Сметы', link: '/estimates'})
        }
        if((isRoot() || hasRight('EXPENSE')) && notOnlyEstimates)
        {
            financeItems.push({name: 'Расходы', link: '/expenses'})
        }
        if((isRoot() || hasRight('EXPENSEREPORT')) && notOnlyEstimates)
        {
            financeItems.push({name: 'Отчеты по расходам', link: '/expensereports'})
        }
        if((isRoot() || hasRight('PARTNER')) && notOnlyEstimates)
        {
            financeItems.push({name: 'Контрагенты', link: '/partners'})
        }
        if((isRoot() || hasRight('AGREEMENT')) && notOnlyEstimates)
        {
            financeItems.push({name: 'Договоры', link: '/agreements'})
        }
        if((isRoot() || hasRight('CONTRACT')) && notOnlyEstimates)
        {
            financeItems.push({name: 'Контракты', link: '/contracts'})
        }
        if((isRoot() || hasRight('ICOEXPENSE')) && notOnlyEstimates)
        {
            financeItems.push({name: 'Интеркампани', link: '/icoexpenses'})
        }
        if((isRoot() || hasRight('REQUISITES')) && notOnlyEstimates)
        {
            financeItems.push({name: 'Реквизиты', link: '/requisites'})
        }
        items.push({name: "Финансы", img: admin, children: financeItems});

        if((isRoot() || hasRight('MDG') || hasRight('MDG2')) && notOnlyEstimates)
        {
            items.push({name: "Мастер данные", img: admin, children: [
                {name: 'Клиенты', link: '/clients'},
                {name: 'Бренды', link: '/brands'},
            ]});
        }

        if((isRoot() || hasRight('MDG') || hasRight('REPORT_PL') || hasRight('REPORT_PL_MY_COMPANY') || hasRight('REPORT_EXPENSES_DETAIL') || hasRight('REPORT_EXPENSES_DETAIL_MY_COMPANY') || hasRight('REPORT_STATUS_ALL') || hasRight('REPORT_STATUS_MY_COMPANY')) && notOnlyEstimates)
        {
            let reportItems = [];

            if(isRoot() || hasRight('REPORT_PL') || hasRight('REPORT_PL_MY_COMPANY'))
            {
                reportItems.push({name: 'PL', link: '/plreport'});
            }
            if(isRoot() || hasRight('REPORT_EXPENSES_DETAIL') || hasRight('REPORT_EXPENSES_DETAIL_MY_COMPANY'))
            {
                reportItems.push({name: 'Детализация расходов', link: '/expenses-report'});
            }
            if(isRoot() || hasRight('REPORT_STATUS_ALL') || hasRight('REPORT_STATUS_MY_COMPANY'))
            {
                reportItems.push({name: 'STATUS', link: '/status-report'});
            }
    
            items.push({name: "Отчеты", img: admin, children: reportItems});
        }

        setMenuItems(items);
        setLoading(false);
    }, [userData, substitutionList]);

    const onChangeMenu = (menuIndex, submenuIndex) => {
        const newMenu = [...menuItems];
        newMenu.forEach((el, i) => {
          if(i === menuIndex && submenuIndex < 0)
          {
            el.active = !el.active;
          } else if(i === menuIndex) {
            el.active = true;
          }
          if(el.children !== undefined)
          {
            el.children.forEach( (subEl, subI) => {
              subEl.active = el.active && subI === submenuIndex;
            } )
          }
        });
        setMenuItems(newMenu);
    }

    const hasRight = (right) => { return userData !==undefined && userData['grants']!==undefined && 
        userData.grants.filter((i) => { return i.grant_code == right }).length>0;};

    const isRoot = () => { return userData !==undefined && userData['grants']!==undefined && 
                userData.grants.filter((i) => { return i.grant_code == 'ROOT' }).length>0;};

    return <>
        {loading && <></>}
        {!loading && <div className={"d-flex flex-column flex-shrink-0 menu-border menu "+(menuVisible ? "show" : "hide") + (substituted ? " substituted" : "")}>
            <ul className={"nav nav-pills flex-column mb-auto"}>
                {menuItems.map((item, i) => {
                        return <MenuItem item={item} key={"menu"+i} index={i} changeMenu={onChangeMenu} />
                    })
                }
            </ul>
        </div>}
    </>;
}