import * as React from "react";
import {useState, useEffect} from "react";
import i18n from "../../utils/Locale.js";
import Http from "../../utils/Http";
import Tabs from "../list/Tabs.js";
import TextInput from '../controls/TextInput.js';
import DocumentSignsActive from "./DocumentSignsActive.js";
import DocumentSignsHistory from "./DocumentSignsHistory.js";

import sign from "../../resources/images/sign.svg";
import signDisabled from "../../resources/images/sign-disabled.svg";
import reject from "../../resources/images/reject.svg";
import rejectDisabled from "../../resources/images/reject-disabled.svg";
import redirect from "../../resources/images/redirect.svg";
import redirectDisabled from "../../resources/images/redirect-disabled.svg";

import { Tooltip, Whisper, Modal, Message, useToaster } from 'rsuite';
import '../../DocumentSigns.scss';
import List from "../controls/List.js";

export default function DocumentSigns(props) {
    const items = props.items ?? [];
    const documentId = props.documentId ?? '';
    const http = Http.GetInstance();
    const [currentTab, setCurrentTab] = useState(0);
    const [showRedirectList, setShowRedirectList] = useState(false);
    const [wantSign, setWantSign] = useState(false);
    const [wantAddSign, setWantAddSign] = useState(false);
    const [signComment, setSignComment] = useState('');
    const [redirectUser, setRedirectUser] = useState(0);
    const [doAction, setDoAction] = useState(false);
    const [redirectUserName, setRedirectUserName] = useState("");

    const tooltipDelay = 1000;
    const toaster = useToaster();

    const onTabChanged = (tab) => {
        if(currentTab!=tab)
        {
            setCurrentTab(tab);
        }
    };

    // approverUserList
    // ng-disabled="$ctrl.isEditMode || !$ctrl.ECM.isApprover || $ctrl.ECM.answerType == 1"

    const states = [i18n.t('Цепочка подписей'), i18n.t('История подписаний')];

    const tooltip = <Tooltip>Недоступно в режиме редактирования документа!</Tooltip>;

    const showAlert = (type, text, timeout = 5000) => {
        const message = <Message showIcon type={type} closable>{text}</Message>;
        const placement = 'topCenter';
        toaster.push(message, { placement, duration: timeout });
    }

    const signDocument = (id, version) => {
        setWantAddSign(false);
        setWantSign(true);
        setDoAction(true);
    }

    const rejectDocument = (id) => {
        setWantAddSign(false);
        setWantSign(false);
        setDoAction(true);
    }

    const redirectDocument = (id) => {
        setWantAddSign(true);
        setWantSign(false);
        setShowRedirectList(true);
    }

    const getActionString = () => {
        if(wantAddSign)
        {
            return "перевода документа";
        }
        if(wantSign)
        {
            return "подписания"
        }
        return "отклонения";
    }
    const afterSign = (r) => {
        if(r.data.errorcode === "00")
        {
            if(props.onStartSign)
            {
                props.onStartSign(false);
            }
            props.onRefresh();
        } else {
            showAlert("error", "Ошибка "+getActionString()+"! "+r.data.errortext);
        }
    }

    const doSignOrReject = () => {
        console.log('wantSign: '+wantSign);
        if(!wantSign && signComment == "")
        {
            showAlert("warning", "Для "+getActionString()+" необходимо ввести комментарий!");
        } else {
            setShowRedirectList(false);
            if(props.onStartSign)
            {
                props.onStartSign(true);
            }
            http.DocumentSign(documentId, wantSign, wantAddSign, redirectUser, signComment, props.version, afterSign);
        }
        setDoAction(false);
    }

    useEffect(() => {
        if(doAction)
        {
            doSignOrReject();
        }
    }, [doAction]);

    return <div>

    {showRedirectList && <Modal open={showRedirectList} onClose={() => {setShowRedirectList(false);}}>
            <Modal.Header>
            <Modal.Title>Выберите пользователя</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{height: "30vh"}}>
                <List options={props.user4Redirect} value={redirectUser} text={redirectUserName} onChangedItem={(id, label, i) => {
                    setRedirectUser(id);
                    setRedirectUserName(label);
                    }}></List>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <button type="button" className="btn btn-dark" onClick={() => {
                setDoAction(true);
            }}>Продолжить</button>&nbsp;&nbsp;&nbsp;
            <button type="button" className="btn btn-outline-dark" onClick={() => {setShowRedirectList(false);}}>Закрыть</button>
            </Modal.Footer>
        </Modal>}
        <Whisper placement="top" trigger={(props.editing ?? false) ? "hover" : "none"} controlId={'control-id-edit'} speaker={tooltip}  >
            <div className="signs-active-row d-flex flex-row">
                <Whisper placement="top" delayOpen={tooltipDelay} speaker={<Tooltip>Подписать</Tooltip>}>
                <div className={"action sign"+(!props.editing && props.canSign ? "": " disabled")} onClick={ () => {
                    if(!props.editing && props.canSign)
                    {
                        signDocument();
                    }
                } } />
                </Whisper>

                <Whisper placement="top" delayOpen={tooltipDelay} speaker={<Tooltip>Отклонить</Tooltip>}>
                <div className={"action reject"+(!props.editing && props.canReject ? "": " disabled")} onClick={ () => {
                    if(!props.editing && props.canReject)
                    {
                        rejectDocument();
                    }
                } } />
                </Whisper>

                <Whisper placement="top" delayOpen={tooltipDelay} speaker={<Tooltip>Перенаправить</Tooltip>}>
                <div className={"action redirect"+(!props.editing && props.canRedirect ? "": " disabled")} onClick={ () => {
                    if(!props.editing && props.canRedirect)
                    {
                        if(!wantSign && signComment == "")
                        {
                            showAlert("warning", "Для перевода документа необходимо ввести комментарий!");
                        } else {
                            redirectDocument();
                        }
                    }
                } } />
                </Whisper>

                <TextInput value={signComment} disabled={(props.editing ?? false)} placeholder={i18n.t('Комментарий')} onChange={(e) => {setSignComment(e.target.value);}} />
            </div>
        </Whisper>
        <Tabs states={states} onChangeTab={onTabChanged} activeTab={currentTab} />
        {currentTab == 0 && <DocumentSignsActive items={props.activeSigns} />}
        {currentTab == 1 && <DocumentSignsHistory items={props.historySigns} />}
    </div>;
}
