import * as React from "react";
import {useState, useEffect} from "react";
import Http from "../../utils/Http.js";
import i18n from "../../utils/Locale.js";
import { useParams } from 'react-router';
import { useSearchParams, useNavigate } from "react-router-dom";
import { Loader, Progress } from 'rsuite';
import { Modal, Message, useToaster, Dropdown } from 'rsuite';
import DocumentHeader from "../../components/document/DocumentHeader.js";
import DocHeaderItem from "../../components/document/DocHeaderItem.js";
import DocumentFiles from "../../components/document/DocumentFiles.js";
import DocumentSigns from "../../components/document/DocumentSigns.js";
import Group from "../../components/document/estimate/Group.js";
import EstimateTableHeader from "../../components/document/estimate/EstimateTableHeader.js";
import CurrencyText from "../../components/controls/CurrencyText.js";
import estimateAnalytics from "../../components/document/estimate/EstimateAnalytics.js";
import estimateStructureEvents from "../../components/document/estimate/EstimateStructureEvents.js";
import PercentText from "../../components/controls/PercentText.js";
import '../../Estimates.scss';
import { faI, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

window.months = [{id: 1, name: 'январь'}, {id: 2, name: 'февраль'}, {id: 3, name: 'март'}, {id: 4, name: 'апрель'}, {id: 5, name: 'май'}, {id: 6, name: 'июнь'}, {id: 7, name: 'июль'}, {id: 8, name: 'август'}, {id: 9, name: 'сентябрь'}, {id: 10, name: 'октябрь'}, {id: 11, name: 'ноябрь'}, {id: 12, name: 'декабрь'}];

window.defaultCurrencyId = 72;

export default function Estimate(props) {
  const [loadingData, setLoadingData] = useState(false);
  const [docLocked, setDocLocked] = useState({status: false, user: ''});
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingBodyData, setLoadingBodyData] = useState(true);
  const [collapseOut, setCollapseOut] = useState(false);
  const [collapseIn, setCollapseIn] = useState(false);
  const [collapseAnalytics, setCollapseAnalytics] = useState(false);
  const [recycleBin, setRecycleBin] = useState({});
  const [exportMenuCollapsed, setExportMenuCollapsed] = useState(true);
  const [einvoiceList, setEinvoiceList] = useState([]);

  const [blockTypes, setBlockTypes] = useState([]);
  const [invoiceTypeTree, setInvoiceTypeTree] = useState([]);

  const navigate = useNavigate();

  const [dicts, setDicts] = useState({});

  const [loadingFiles, setLoadingFiles] = useState(true);
  const [loadingSigns, setLoadingSigns] = useState(true);
  const [loadingSignsHistory, setLoadingSignsHistory] = useState(true);
  const [savingData, setSavingData] = useState(false);
  const [editing, setEditing] = useState(false);
  const [changed, setChanged] = useState(false);
  const [changeCounter, setChangeCounter] = useState(0);
  const [doc, setDoc] = useState({id: 0});
  const [docBody, setDocBody] = useState({});
  const [docDetails, setDocDetails] = useState({});
  const [docCompanies, setDocCompanies] = useState([]);
  const [docFiles, setDocFiles] = useState([]);
  const [docSigns, setDocSigns] = useState([]);
  const [docSignsHistory, setDocSignsHistory] = useState([]);
  const [docRights, setDocRights] = useState({});
  const [docGrants, setDocGrants] = useState([]);
  const [leftScroll, setLeftScroll] = useState(0);
  const [docVersion, setDocVersion] = useState("");

  const [currentTab, setCurrentTab] = useState(i18n.t('Данные'));
  const [analytics, setAnalytics] = useState({});

  const [managers, setManagers] = useState([]);
  const [projects, setProjects] = useState([]);

  const [clients, setClients] = useState([]);
  const [clientsLoaded, setClientsLoaded] = useState(false);
  const [brands, setBrands] = useState([]);
  const [brandsLoaded, setBrandsLoaded] = useState(false);
  const [period, setPeriod] = useState(null);

  const [zoomLevel, setZoomLevel] = useState(3);
  const [pined, setPined] = useState(false);
  const [analyticsCalced, setAnalyticsCalced] = useState(false);

  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [saveCheckFailed, setSaveCheckFailed] = useState(false);

  const [canSign, setCanSign] = useState(false);
  const [canReject, setCanReject] = useState(false);
  const [canRedirect, setCanRedirect] = useState(false);
  const [user4Redirect, setUser4Redirect] = useState([]);
  const [treeIsReady, setTreeIsReady] = useState(false);
  const [startSign, setStartSign] = useState(false);

  const [unlockHover, setUnlockHover] = useState(false);

  const [projectChanged, setProjectChanged] = useState(false);

  const toaster = useToaster();
  const http = Http.GetInstance();
  const params= useParams();

  let outIndex = 1;

  const progressColor = "rgb(0, 162, 138)";

  const onEditClicked = (locked) => {
    if(editing)
    {
      if(changed)
      {
        setShowConfirmCancel(true);
      } else {
        window.changed = false;
        setChanged(false);
        setEditing(!editing);
        if(doc.id === 0)
        {
          navigate("/estimates");
        }
      }
    } else {
      if(locked)
      {
        checkLock();
      } else {
        setEditing(!editing);
      }
    }
  }

  const showAlert = (type, text, timeout = 5000) => {
    const message = <Message showIcon type={type} closable>{text}</Message>;
    const placement = 'topCenter';
    toaster.push(message, { placement, duration: timeout });
  }
  const onSaveClicked = () => {
    if(docDetails.changeStatusBeforeSave)
    {
      setShowConfirmSave(true);
    } else {
      save();
    }
  }

  const checkFields = ["name", "projectid", "year", "month"];
  const checkBeforeSave = () => {
    for(let i=0;i<checkFields.length;i++)
    {
      if((doc[checkFields[i]] ?? "") === "")
      {
        return false;
      }
    }
    if(docBody === null || docBody.groups.length === 0)
    {
      return false;
    }
    for(let i=0;i<docBody.groups.length;i++)
    {
      let group = docBody.groups[i];
      for(let j=0;j<group.subgroups.length;j++)
      {
        let subgroup = group.subgroups[j];
        for(let k=0;k<subgroup.lines.length;k++)
        {
          let line = subgroup.lines[k];
          for(let l=0;l<line.positions.length;l++)
          {
            let position = line.positions[l];
            // out - item && invoice_id
            if(position['out'] !== undefined)
            {
              if((position.out.item ?? '') === '')
              {
                return false;
              }
              if((position.out.mdg_estimate_invoices_id ?? '') === '')
              {
                return false;
              }
            }

            // in - item && invoice_id
            if(position['in'] !== undefined)
            {
              if((position.in.item ?? '') === '')
              {
                return false;
              }
              if((position.in.mdg_estimate_invoices_id ?? '') === '')
              {
                return false;
              }
              if((position.in.paymentdate ?? '') === '')
              {
                return false;
              }
              if((position.in.le_id ?? '') === '')
              {
                return false;
              }
            }
          }
        }
      }
    }
    return true;
  }
  const save = () => {
    if(!checkBeforeSave())
    {
      setSaveCheckFailed(true);
    } else {
      setSaveCheckFailed(false);
      setSavingData(true);
      let postData = {
        estimate: {
            options: {...doc, version: docDetails.version },
            data: { ...docBody },
            files: docFiles,
            doctypeid: 3,
            analytics: analytics,
            reciclebin: recycleBin,
          }
        };
      http.SaveEstimate(postData, (resp) => {
        if(resp.data.errorcode !== "00")
        {
          showAlert("error", "Ошибка сохранения сметы! "+resp.data.errortext);
        } else {
          showAlert("success", "Смета сохранена");
          let newId = resp.data.estimateid;
          if(doc.id === 0)
          {
            navigate('/estimates/'+newId);
          } else {
            setDoc({...doc, id: resp.data.estimateid, documentid: resp.data.documentid})
            setSavingData(false);
            setEditing(false);
            loadData();
          }
        }
      });
    }
  }

  const getDocVersion = (docId = 0) => {
    if(docId === 0)
    {
      docId = doc.documentid;
    }
    http.GetDocumentVersion(docId, (r) => {
      if(r.data.errorcode !== "00")
      {
        showAlert("error", "Ошибка! Не удалось получить версию сметы! "+r.data.errortext);
      } else {
        setDocVersion(r.data.version);
      }    
    });
  }

  const checkLock =(d) => {
    http.IsLockDocument(d ?? doc.documentid, (r) => {
      if(r.data.errorcode === "00")
      {
        if(r.data.isLockedDocument.docIsLocked)
        {
          setDocLocked({status: true, user: r.data.isLockedDocument.docLockedFullName, userId: r.data.isLockedDocument.docLockedUserID});
        } else {
          setDocLocked({satus: false});
        }
      }
    });
  }

  const loadData = () => {
    if(params.id*1 === 0)
    {
      if(!searchParams.has('type'))
      {
        navigate("/estimates");
      } else {
        let docType = searchParams.get('type');
        docRights['ALL'] = 2;
        setDocRights({ALL: 2});
        let documentId = 'tmp_'+Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        setDoc({id: 0, documentid: documentId, type: docType, managerid: window.user.userid, manager_full_name: window.user.full_name, 
              creationdate: (new Date()), default_vat: 20, af_tx: 20,
              basecurrency: 72, shortcode: 'RUR', basecurrencyrate: 1,
            });
        if(window.einvoiceList !== undefined)
        {
          makeTree(window.einvoiceList, docType);
        }
        onAddGroup();

        setEditing(true);
        searchParams.delete('type');
        setSearchParams(searchParams);
        setLoadingData(false);
        setLoadingBodyData(false);
        setLoadingFiles(false);
        setLoadingSigns(false);
        setLoadingSignsHistory(false);
        window.changed = false;
      }
    } else {
      if(!loadingData)
      {
        setLoadingData(true);
        setLoadingFiles(true);
        setLoadingSigns(true);
        setLoadingSignsHistory(true);
        setLoadingBodyData(true);
        http.GetEstimateData(params.id*1, (resp) => {
          if(resp.data.errorcode !== "00")
          {
            showAlert("error", "Ошибка! Не удалось получить данные сметы! "+resp.data.errortext);
          } else {
            let docId = resp.data.estimate.options.documentid;
            let docData = resp.data.estimate.options;
            let monthName = docData.month ?? 12;
            for(var i=0;i<window.months.length;i++)
            {
              if(window.months[i].id == monthName)
              {
                monthName = window.months[i].name;
                break;
              }
            }
            docData.monthName = monthName;
            docData.id = params.id*1;
            if(window.einvoiceList !== undefined)
            {
              makeTree(window.einvoiceList, docData.type);
            }
            let startDate = docData.begindate;
            let endDate = docData.enddate;
            docData.period = [new Date(startDate), new Date(endDate)];
            if(docData.creationdate !== undefined && docData.creationdate !== null)
            {
              docData.creationdate = new Date(docData.creationdate);
            }

            let cb = (docData.client_name ?? '')+' / '+(docData.brand_name ?? '');
            if(cb === ' / ')
            {
              cb = '';
            }
            docData.client_brand = cb;

            setDoc(docData);
            setDocBody(resp.data.estimate.data);
            setLoadingBodyData(false);
            getDocVersion(docId);
            checkLock(docId);
            http.GetDocumentRights(docId, (r) => {
              if(r.data.errorcode !== "00")
              {
                showAlert("error", "Ошибка! Не удалось получить права! "+r.data.errortext);
              } else {
                setDocRights(r.data.DocumentAccess);
                setDocGrants(r.data.DocumentGrants);
              }
            })
            http.GetSigns(docId, (r) => {
              if(r.data.errorcode !== "00")
              {
                showAlert("error", "Ошибка! Не удалось получить подписи! "+r.data.errortext);
              } else {
                setDocSigns(r.data.approvalStage);
              }
              setLoadingSigns(false);
            });
            http.GetSignsHistory(docId, (r) => {
              if(r.data.errorcode !== "00")
              {
                showAlert("error", "Ошибка! Не удалось получить историю подписей! "+r.data.errortext);
              } else {
                setDocSignsHistory(r.data.documentHistory);
              }
              setLoadingSignsHistory(false);
            });

            http.GetUser4Redirect((r) => {
              if(r.data.errorcode !== "00")
              {
                showAlert("error", "Ошибка! Не удалось получить список пользователей! "+r.data.errortext);
              } else {
                setUser4Redirect(r.data.users.map((u) => { return {id: u.userid, name: u.full_name};}));
              }
            });

            http.GetDocumentData(docId, (r) => {
              if(r.data.errorcode !== "00")
              {
                showAlert("error", "Ошибка! Не удалось получить справочники! "+r.data.errortext);
              } else {
                setDocDetails({...r.data.documentDetail, enableDelete: r.data.enableDelete, changeStatusBeforeSave: (r.data['changeStatusBeforeSave'] ?? 0) === 1});

                setCanSign(r.data.isApprover && r.data.answerType != 1);
                setCanReject(r.data.isApprover && r.data.answerType != 2);
                setCanRedirect(r.data.isApprover && r.data.answerType != 1);
              }
            });
          }
          window.changed = false;
          setLoadingData(false);
        });
      }
    }
  }

  const setAgencyList = (project) => {
    for(let i=0;i<projects.length;i++)
    {
      if(projects[i].id === project)
      {
        dicts.projectAgencies = projects[i].companies;
        break;
      }
    }
  }

  useEffect(() => {
    if(docRights["ALL"] !== undefined && doc['documentid'] !== undefined && canSee('ATTACHEMENTS'))
    {
      http.GetFilesList(doc.documentid, (r) => {
        if(r.data.errorcode !== "00")
        {
          showAlert("warning", "Ошибка! Не удалось получить файлы! "+r.data.errortext);
        } else {
          setDocFiles(r.data.list);
        }
        setLoadingFiles(false);
      });
    }
  }, [docRights]);

  const loadFilesCallBack = (list) => {
    setDocFiles(list);
  }

  const makeTree = (l, docType) => {
    let lNew = l.filter((item) => item.type==docType && item.active==1);
    let tree = [];
    lNew.forEach((i) => {
      let treeIdx = tree.map(e => e.label).indexOf(i.parentname);
      if(treeIdx < 0)
      {
        tree.push({value: 'x'+i.parentid, label: i.parentname, children: []});
        treeIdx = tree.length-1;
      }
      tree[treeIdx].children.push({value: i.id, label: i.code+' - '+i.name})
    });
    window.einvTree = tree;
    setTreeIsReady(true);
  }

  useEffect(() => {
      if(doc!==undefined && einvoiceList.length>0 && doc.type !== undefined && doc.type !== 0 && window.einvTree === undefined)
      {
        makeTree(window.einvoiceList, doc.type);
      }
    }, [doc, einvoiceList]);

  useEffect(() => {
    loadData();
    http.GetEstimateDicts((resp) => {
      if(resp.data.errorcode !== "00")
      {
        showAlert("error", "Ошибка! Не удалось получить справочники сметы! "+resp.data.errortext);
      } else {
        window.einvoiceList = resp.data.einvoiceList;
        setEinvoiceList(resp.data.einvoiceList);
        if(doc.type !== undefined && doc.type !== 0)
        {
          makeTree(window.einvoiceList, doc.type);
        }
        let dictsData = {
          metricsList: resp.data.metricsList,
          einvoiceList: resp.data.einvoiceList,
          // yearsList: resp.data.yearsList.map(i => {
          //   return {...i, name: i.year, id: i.year*1};
          // }),
          yearsList: resp.data.yearsList.map(i => {
            return {...i, name: i.year, id: i.id};
          }),
          currencyList: resp.data.currencyList.map(i => {return {...i, name: i.shortcode}}),
          companyList: resp.data.companyList,
          leList: resp.data.leList,
          projectAgencies: [],
        };
        let currencyHash = {};
        let currencyHashId = {};
        let currencyList = resp.data.currencyList.map(i => {
          return {...i, name: i.shortcode}
        });
        window.currencyList = currencyList;
        currencyList.map(i => {
          currencyHash[i.shortcode] = i;
          currencyHashId[i.id] = i;
        });
        window.currencyListHash = currencyHash;
        window.currencyListHashId = currencyHashId;
        let metricsListHash = {};
        resp.data.metricsList.map(i => {
          metricsListHash[i.name] = i;
        });
        window.metricsList = resp.data.metricsList;
        window.metricsListHash = metricsListHash;

        setDicts({...dicts, ...dictsData});
      }
    });
    http.GetEstimateManagersList((resp) => {
      if(resp.data.errorcode !== "00")
      {
        showAlert("error", "Ошибка! Не удалось получить список менеджеров! "+resp.data.errortext);
      } else {
        setManagers(resp.data.users.map((item)=>{return {id: item.userid, name: item.full_name}}));
      }});
    http.GetEstimateProjectsList((resp) => {
        if(resp.data.errorcode !== "00")
        {
          showAlert("error", "Ошибка! Не удалось получить список проектов! "+resp.data.errortext);
        } else {
          setProjects(resp.data.projectList);
        }});
    http.GetEstimateBlocksList((resp) => {
      if(resp.data.errorcode !== "00")
      {
        showAlert("error", "Ошибка! Не удалось получить список блоков! "+resp.data.errortext);
      } else {
        setBlockTypes(resp.data.blockTypes);
        setInvoiceTypeTree(resp.data.invoiceTypeTree);
      }});
    }, []);

  const onChangeItem = (val, item, arrayItem) => {
    if(item === "period")
    {
      if(val.length>0 && val[0] != null)
      {
        doc["begindate"] = val[0].toISOString();
        doc["enddate"] = val[1].toISOString();
        doc["period"] = [new Date(doc.begindate), new Date(doc.enddate)];
      } else {
        doc["begindate"] = null;
        doc["enddate"] = null;
        doc["period"] = [null, null];
      }
    } else {
      if(item === "projectid")
      {
        setAgencyList(val);

        if(dicts.projectAgencies.length>0)
        {
          doc.companyid = dicts.projectAgencies[0].id;
          doc.company_name  = dicts.projectAgencies[0].name;
        }

        for(var i=0;i<projects.length;i++)
        {
          if(projects[i].id == val)
          {
            let project = projects[i];
            doc['client_brand'] = project.client_name + ' / ' + project.brand_name;
            // if(((doc['period'] ?? '') == '') || ((doc['begindate'] ?? '') == ''))
            {
              doc.begindate = project.startdate+'T01:01:01.001Z';
              doc.enddate = project.enddate+'T01:01:01.001Z';
              doc["period"] = [new Date(doc.begindate), new Date(doc.enddate)];
            }
            break;
          }
        }
        setProjectChanged(!projectChanged);
      }
      if((item == "af_tx" || item == "default_vat") && val == "")
      {
        val = 0;
      }
      if(item == 'year')
      {
        val = val*1;
      }
      if(item == 'basecurrency')
      {
        doc['basecurrencyrate'] = window.currencyListHashId[val].rate*1;
        val = val*1;
      }
      // doc[item] = val;
      let newDoc = {...doc};
      newDoc[item] = val;
      setDoc(newDoc);
    }
    if(!changed)
    {
      window.changed = true;
      setChanged(true);
    }
    // setChangeCounter(changeCounter+1);
  }

  useEffect(() => {
    if((doc['projectid'] ?? 0) != 0)
    {
      setAgencyList(doc['projectid']);
    }
  },
  [dicts, doc]);

  const nameItem = {title: i18n.t("Наименование"), val: doc.name, name: 'name', mandatory: true};
  const managerItem = {title: i18n.t("Ответственный"), val: doc.managerid, name: 'managerid', label: 'manager_full_name', text: doc.manager_full_name};
  const projectItem = {title: i18n.t("Проект"), val: doc.projectid, name: 'projectid', label: 'project_name', text: doc.project_name, mandatory: true};
  const periodItem = {title: i18n.t("Срок действия"), val: doc.period, name: 'period', from: doc.begindate, to: doc.enddate};
  const clientBrandItem = {title: i18n.t("Клиент / Бренд"), label: 'client_brand', val: doc.client_brand, name: 'client_brand', text: doc.client_brand};
  const dateItem = {title: i18n.t("Дата"), val: doc.creationdate, name: 'creationdate'};

  const companyItem = {title: i18n.t("Агентство сметы"), val: doc.companyid, name: 'companyid', label: 'company_name', text: doc.company_name};

  const currencyItem = {title: i18n.t("Валюта"), val: doc.basecurrency, name: 'basecurrency', label: 'shortcode', text: doc.shortcode};

  const quoteItem = {title: i18n.t("Курс (рублей за единицу)"), val: doc.basecurrencyrate, name: 'basecurrencyrate'};

  // const yearItem = {title: i18n.t("Отчетный год"), val: doc.year, name: 'year', label: 'year', text: doc.year, mandatory: true};
  const yearItem = {title: i18n.t("Отчетный год"), val: doc.periodid, name: 'periodid', label: 'year', text: doc.year, mandatory: true};
  const monthItem = {title: i18n.t("Отчетный месяц"), val: doc.month, name: 'month', label: 'monthName', text: doc.monthName, mandatory: true};

  const vatItem = {title: i18n.t("НДС"), val: doc.default_vat, name: 'default_vat'};
  const vatAItem = {title: i18n.t("НДС АК"), val: doc.af_tx, name: 'af_tx'};
  const descriptionItem = {title: i18n.t("Комментарий"), val: doc.description, name: 'description'};

  const brandItem = {title: i18n.t("Бренд"), val: doc.brandid, name: 'brandid', label: 'brand_name', text: doc.brand_name};
  const commentsItem = {title: i18n.t("Комментарий"), val: doc.description, name: 'description'};

  const tabChanged = (tab) => {
    if(currentTab!=tab)
    {
      setCurrentTab(tab);
    }
  }

  useEffect(() => {
    //
  },
  [currentTab]);

  const changeZoom = (inc) => {
    if(zoomLevel + inc < 1)
    {
      setZoomLevel(5);
    } else {
      if(zoomLevel + inc > 5)
      {
        setZoomLevel(1);
      } else {
        setZoomLevel(zoomLevel+inc);
      }
    }
  }

  const togglePin = () => {
    setPined(!pined);
  }

  const onChangeEvent = (newDocBody = null, needRecalc = false) => {
    if(editing)
    {
      window.changed = true;
      setChanged(true);
    }
    setChangeCounter(changeCounter+1);
    if(newDocBody!=null)
    {
      setDocBody(newDocBody);
    }
    if(needRecalc)
    {
      calcAnalytics();
    }
  }

  estimateStructureEvents.onChangeEvent = onChangeEvent;

  const onAddGroup = () => {
    estimateStructureEvents.onAddGroup(doc, docBody);
  }
  const onAddSubGroup = (group) => {
    estimateStructureEvents.onAddSubGroup(doc, docBody, group);
  }
  const onAddLine = (subgroup, line) => {
    estimateStructureEvents.onAddLine(doc, docBody, subgroup, line);
  }
  const onAddInSubline = (line) => {
    estimateStructureEvents.onAddInSubline(doc, docBody, line);
  }

  const onAddOutSubline = (line) => {
    estimateStructureEvents.onAddOutSubline(doc, docBody, line);
  }

  const onDeleteGroup = (group) => {
    estimateStructureEvents.onDeleteGroup(docBody, recycleBin, group);
  }

  const onDeleteSubgroup = (group, subgroup) => {
    estimateStructureEvents.onDeleteSubgroup(docBody, recycleBin, group, subgroup);
  }

  const onDeleteLine = (subgroup, line) => {
    let checked = true;

    if((line['id'] ?? 0) !== 0)
    {
      line.positions.map((it) => {
        if(it['in'] !== undefined && (it.in.rur_cost_amount ?? 0) !== 0)
        {
          checked = false;
        }
      })
    }
    if(checked)
    {
      estimateStructureEvents.onDeleteLine(docBody, recycleBin, subgroup, line);
    }
  }

  const onDeleteInSubline = (subgroup, line, queue) => {
    let pos = line.positions[queue];
    let checked = true;
    if((pos["id"] ?? 0) !== 0)
    {
      if((pos.rur_cost_amount ?? 0) !== 0)
      {
        checked = false;
        showAlert("error", "Удаление запрещено, т.к. есть связанные расходы.");
      }
    }
    if(checked)
    {
      estimateStructureEvents.onDeleteInSubline(docBody, recycleBin, subgroup, line, queue);
    }
  }
  const onDeleteOutSubline = (subgroup, line, queue) => {
    estimateStructureEvents.onDeleteOutSubline(docBody, recycleBin, subgroup, line, queue);
  }

  const checkCanDeleteLine = (line) => {
    let checked = true;
    if((line['id'] ?? 0) !== 0)
    {
      line.positions.map((it) => {
        if(it['in'] !== undefined && (it.in.rur_cost_amount ?? 0) !== 0)
        {
          checked = false;
        }
      })
    }
    return checked;
  }

  const onChangeGroupData = (group, field, val) => {
    group[field] = val;
    onChangeEvent(docBody, field==='agency_rate');
  }

  const onChangeSubGroupData = (subgroup, field, val) => {
    subgroup[field] = val;
    onChangeEvent();
  }

  const onChangeSublineData = (parentLine, subline, field, val) => {
    subline[field] = val;
    if(parentLine.positions.length === 1)
    {
      if(field === 'item' || field === 'amount' || field === 'qnt_multiplicator' || field === 'qnt_formula' ||
        field === 'mdg_estimate_invoices_id' || field === 'inv')
      {
        subline['justCreated'] = false;
        // if(field == 'total')
        // {
        //   if((subline['qnt_multiplicator'] ?? 0) != 0 )
        //   {
        //       let amount = val / subline['qnt_multiplicator'];
        //       subline['amount'] = amount;
        //   }  
        // }
        if(parentLine.positions[0].in === subline && (parentLine.positions[0].out['justCreated'] ?? false))
        {
          if(field === 'qnt_formula')
          {
            parentLine.positions[0].out[field] = JSON.parse(JSON.stringify(val));
          } else {
            parentLine.positions[0].out[field] = val;
          }
        }
        if(parentLine.positions[0].out === subline && (parentLine.positions[0].in['justCreated'] ?? false))
        {
          if(field === 'qnt_formula')
          {
            parentLine.positions[0].in[field] = JSON.parse(JSON.stringify(val));
          } else {
            parentLine.positions[0].in[field] = val;
          }
        }
      }
    }
    onChangeEvent(docBody, field!=='item');
  }

  window.onChangeSublineData = onChangeSublineData;

  const calcProjectMargin = () => {
    let projectId = doc.projectid ?? 0;
    let companyId = doc.companyid ?? 0;
    if(projects != undefined && doc.projectid != undefined)
    {
      for(var i=0;i<projects.length;i++)
      {
        if(projects[i].id === projectId)
        {
          let companies = projects[i].companies;
          for(var j=0;j<companies.length;j++)
          {
            if(companies[j].id === companyId)
            {
              doc.margin = companies[j].margin * 1;
              break;
            }
          }
          break;
        }
      }
    }
  }

  useEffect(() =>{
    calcProjectMargin();
  }, [doc, projects, projectChanged]);

  const calcAnalytics = () => {
    if(docBody!=null)
    {
      estimateAnalytics.calcAnalytics(doc, docBody);
      setDoc(doc);
    }
    setAnalyticsCalced(true);
  }

  useEffect(() => {
    if(!loadingBodyData)
    {
      calcAnalytics();
      setChangeCounter(changeCounter+1);
    }
  }, [loadingBodyData]);

  const checkRight = (right) => {
    if(docRights.length === 0)
    {
      return 0;
    }
    let all = docRights['ALL'] ?? 0;
    if(docRights[right] != undefined)
    {
      return docRights[right];
    }
    return all;
  }

  const canEdit = (right) => {
    return checkRight(right) === 2;
  }


  const canSee = (right) => {
    return checkRight(right) > 0;
  }

  const tableScrolled = (e) => {
    setLeftScroll(e.currentTarget.scrollLeft);
  }

  let tabs = [i18n.t('Данные')];
  if(canSee('ATTACHEMENTS'))
  {
    tabs.push(i18n.t('Файлы'));
  }
  tabs.push(i18n.t('Подписи'));
  const enableEdit = (currentTab === i18n.t('Данные') && canEdit('ALL')) || (currentTab === i18n.t('Файлы') && canEdit('ATTACHEMENTS'));

  const getSVGURI = (faIcon, color) => {
    let icon = faIcon.icon;
   return `data:image/svg+xml;base64,${btoa(
    `<svg data-prefix="${faIcon['prefix']}" data-icon="${faIcon['iconName']}"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${icon[0]} ${icon[1]}">
      <path fill="${color || "currentColor"}" d="${icon[4]}"></path>
    </svg>`)}`;
  };

  const unlockImage = unlockHover ? getSVGURI(faUnlock, '#DC3545') : getSVGURI(faUnlock, '#000000');

  let innerData = <></>;
  switch(currentTab)
  {
    case i18n.t('Данные'):
      innerData =   <>
      {!loadingData && <>
      <div className="doc-body-header">
      <div className="d-flex flex-column">
        <div className="d-flex">
          {canSee('ALL') && <DocHeaderItem checkFailed={saveCheckFailed} item={nameItem} editing={editing} disabled={!canEdit('ALL')} onChange={onChangeItem} />}
          {canSee('manager') && <DocHeaderItem checkFailed={saveCheckFailed} item={managerItem} items={managers} disabled={!canEdit('manager')} type="list" editing={editing} onChange={onChangeItem} />}
        </div>
        <div className="d-flex justify-content-between">
          {canSee('project') && <DocHeaderItem checkFailed={saveCheckFailed} item={projectItem} disabled={!canEdit('project')} items={projects} type="list" editing={editing} onChange={onChangeItem} />}
          {canSee('period') && <DocHeaderItem checkFailed={saveCheckFailed} item={periodItem} disabled={!canEdit('period') || (doc['projectid'] ?? '') === ''} type="period" editing={editing} onChange={onChangeItem} hasClear={false} />}
        </div>
        <div className="d-flex justify-content-between">
          {canSee('ALL') && <DocHeaderItem checkFailed={saveCheckFailed} item={clientBrandItem} disabled={!canEdit('ALL')} editing={editing} editable={false} />}
          {canSee('ALL') && <DocHeaderItem checkFailed={saveCheckFailed} item={dateItem} type="date" editing={editing} disabled={true} onChange={onChangeItem} />}
        </div>
        <div className="d-flex justify-content-between">
          {canSee('company') && <DocHeaderItem checkFailed={saveCheckFailed} item={companyItem} disabled={!canEdit('company')} items={dicts.projectAgencies} type="list" editing={editing} onChange={onChangeItem} />}
          {!editing && canSee('currency') &&
          <DocHeaderItem checkFailed={saveCheckFailed} item={currencyItem} disabled={!canEdit('currency')} type="list" editing={editing} onChange={onChangeItem} />
          }
          {editing && <div className="doc-body-header-line d-flex flex-row">
            {canSee('currency') && <DocHeaderItem checkFailed={saveCheckFailed} item={currencyItem} disabled={!canEdit('currency')} items={dicts.currencyList} type="list" editing={editing} onChange={onChangeItem} />}
            {canSee('currency_rate') && <DocHeaderItem checkFailed={saveCheckFailed} item={quoteItem} disabled={!canEdit('currency_rate')} type="number" editing={editing} onChange={onChangeItem} />}
          </div>
          }
        </div>

        <div className="d-flex justify-content-between" style={{marginTop: (editing ? -16 : 0)}}>
            {canSee('fin_year') && <DocHeaderItem checkFailed={saveCheckFailed} item={yearItem} disabled={!canEdit('fin_year')} items={dicts.yearsList} type="list" editing={editing} onChange={onChangeItem} />}
          {!editing && canSee('currency_rate') && <DocHeaderItem checkFailed={saveCheckFailed} item={quoteItem} disabled={!canEdit('currency_rate')} editing={false} onChange={onChangeItem} />}
          {editing && <>
            {canSee('fin_month') && <DocHeaderItem checkFailed={saveCheckFailed} item={monthItem} disabled={!canEdit('fin_month')} items={window.months} type="list" editing={editing} onChange={onChangeItem} />}
            {canSee('ALL') && <DocHeaderItem checkFailed={saveCheckFailed} item={vatItem} disabled={!canEdit('ALL')} type="number" editing={editing} onChange={onChangeItem} extraClass={""}/>}
            {canSee('agency_fee_vat') && <DocHeaderItem checkFailed={saveCheckFailed} item={vatAItem} disabled={!canEdit('agency_fee_vat')} type="number" editing={editing} onChange={onChangeItem} />}
          </>}
        </div>

        {!editing && <>
        <div className="d-flex justify-content-between">
          {canSee('fin_month') && <DocHeaderItem checkFailed={saveCheckFailed} item={monthItem} disabled={!canEdit('fin_month')} type="list" editing={editing} onChange={onChangeItem} />}
          {canSee('ALL') && <DocHeaderItem checkFailed={saveCheckFailed} item={vatItem} disabled={!canEdit('ALL')} type="number" editing={editing} onChange={onChangeItem} />}
        </div>

        <div className="d-flex justify-content-between">
        <div className="doc-body-header-line">
            <div>&nbsp;</div>
          </div>
          {canSee('agency_fee_vat') && <DocHeaderItem checkFailed={saveCheckFailed} item={vatAItem} disabled={!canEdit('agency_fee_vat')} type="number" editing={editing} onChange={onChangeItem} />}
        </div>
        </>}
        <div className="d-flex justify-content-between">
          <DocHeaderItem checkFailed={saveCheckFailed} item={descriptionItem} type="textarea" rows="2" editing={editing} onChange={onChangeItem} />
        </div>

      </div>
    </div>
    </>}
    {loadingData &&
      <div className="mt-auto mb-auto ms-auto me-auto d-flex flex-column">
        <Loader content={i18n.t("Загрузка")} size="sm" speed="normal" vertical />
      </div>
    }
    {!loadingData && loadingBodyData &&
      <div className="mt-auto mb-auto ms-auto me-auto d-flex flex-column">
        <Loader content={i18n.t("Загрузка")} size="sm" speed="normal" vertical />
      </div>
    }
    {!loadingBodyData && treeIsReady && canSee('table_data') && <div className="doc-body-body">
      <div className={"estimate-container"+(pined ? " fixed-table" : "")} onScroll={tableScrolled}>
        <table className={"table zoom-"+zoomLevel} style={{width: "max-content"}}>

          <EstimateTableHeader onZoom={changeZoom} onTogglePin={togglePin} pin={pined} estimateType={doc.type}
          project={doc.projectid} projectList={projects} company={doc.companyid}
          collapseIn={collapseIn} collapseOut={collapseOut} collapseAnalytics={collapseAnalytics}
          onCollapseIn={setCollapseIn} onCollapseOut={setCollapseOut} onCollapseAnalytics={setCollapseAnalytics} />

          <tbody>

        {docBody.groups !== undefined && docBody.groups.map((item, i) => {
          return <Group key={"grp"+i} item={item} index={"xgrp"+i} editing={editing && canEdit('table_data')} onChangeGroupData={onChangeGroupData} onChangeSubGroupData={onChangeSubGroupData} canDelete={docBody.groups.length!==1} leList={dicts.leList} onAddGroup={onAddGroup} onAddSubGroup={onAddSubGroup} onAddLine={onAddLine} onAddInSubline={onAddInSubline} onAddOutSubline={onAddOutSubline} onChangeSublineData={onChangeSublineData} outCurrency={doc.shortcode} estimateType={doc.type} analyticsCalced={analyticsCalced} collapseIn={collapseIn} collapseOut={collapseOut} collapseAnalytics={collapseAnalytics} onDeleteGroup={onDeleteGroup} onDeleteSubgroup={onDeleteSubgroup} onDeleteLine={onDeleteLine} onDeleteInSubline={onDeleteInSubline} onDeleteOutSubline={onDeleteOutSubline} blockTypes={blockTypes} leftScroll={leftScroll} canMakeExpense={(doc.forexpense ?? 0) === 1} checkFailed={saveCheckFailed} />
        })}
            </tbody>

            {doc.type === 2 &&
            <tfoot>
              <tr>
                <td className="total pt" colSpan={(collapseOut ? 1 : 2)}>Итого</td>
                <td colSpan={(collapseOut ? 1 : 4)} className="text-end pt">Выручка без НДС:</td>
                <td className="pt text-end total-data-top"><CurrencyText val={doc.outTotal} currency={doc.shortcode} /></td>
                <td colSpan={(14 - (collapseOut ? 5 : 0) - (collapseIn ? 5 : 0) - (collapseAnalytics ? 1 : 0) )}></td>
                {!collapseIn && <>
                 {collapseAnalytics && <td></td>}
                  <td className="pt text-end bottom-data"><CurrencyText val={doc.inTotalWithVAT} currencyId={window.defaultCurrencyId} /></td>
                  <td colSpan={(collapseAnalytics ? 3 : 4)}></td>
                </>
                }
                {!collapseAnalytics && <>
                <td className="pt text-end bottom-data"><CurrencyText val={doc.inTotal} currencyId={window.defaultCurrencyId} /></td>
                <td className="pt text-end bottom-data">
                  <PercentText val={doc.planPrc} type='text' />
                </td>
                </>}
                <td className="pt text-end bottom-data"><CurrencyText val={doc.plan} currencyId={window.defaultCurrencyId} /></td>
                {!collapseAnalytics && <>
                <td className="pt text-end bottom-data">
                  <PercentText val={doc.factPrc} type='text' />
                </td>
                </>}
                <td className="pt text-end bottom-data"><CurrencyText val={doc.fact} currencyId={window.defaultCurrencyId} /></td>
                {!collapseAnalytics && <>
                <td className="pt text-end bottom-data"><CurrencyText val={doc.costRub} currencyId={window.defaultCurrencyId} /></td>
                <td className="pt text-end bottom-data"><CurrencyText val={doc.left} currencyId={window.defaultCurrencyId} /></td>
                <td className="pt text-end bottom-data"><CurrencyText val={doc.leftVat} currencyId={window.defaultCurrencyId} /></td>
                <td className="pt text-end bottom-data"><CurrencyText val={doc.costWithVatRub} currencyId={window.defaultCurrencyId} /></td>
                <td className="pt text-end bottom-data"><CurrencyText val={doc.reportsWithVatRub} currencyId={window.defaultCurrencyId} /></td>
                </>}
              </tr>
              <tr>
                <td colSpan={(collapseOut ? 2 : 6)} className="text-end">НДС:</td>
                <td className="text-end total-data"><CurrencyText val={doc.outVat} currency={doc.shortcode} /></td>
              </tr>

              <tr>
                <td colSpan={7}>&nbsp;</td>
              </tr>

              <tr>
                <td colSpan={(collapseOut ? 2 : 6)} className="text-end">АК без НДС:</td>
                <td className="text-end total-data"><CurrencyText val={doc.outAK} currency={doc.shortcode} /></td>
              </tr>
              <tr>
                <td colSpan={(collapseOut ? 2 : 6)} className="text-end">НДС:</td>
                <td className="text-end total-data"><CurrencyText val={doc.outAKVat} currency={doc.shortcode} /></td>
              </tr>

              <tr>
                <td colSpan={7}>&nbsp;</td>
              </tr>

              <tr>
                <td colSpan={(collapseOut ? 2 : 6)} className="text-end">Итого с АК без НДС:</td>
                <td className="text-end total-data"><CurrencyText val={(doc.outTotal + doc.outAK)} currency={doc.shortcode} /></td>
              </tr>
              <tr>
                <td colSpan={(collapseOut ? 2 : 6)} className="text-end">НДС:</td>
                <td className="text-end total-data"><CurrencyText val={(doc.outVat + doc.outAKVat)} currency={doc.shortcode} /></td>
              </tr>

              <tr>
                <td colSpan={7}>&nbsp;</td>
              </tr>

              <tr>
                <td colSpan={(collapseOut ? 2 : 6)} className="text-end">Всего с НДС:</td>
                <td className="text-end total-data"><CurrencyText val={(doc.outTotal + doc.outAK + doc.outVat + doc.outAKVat)} currency={doc.shortcode} /></td>
              </tr>
              <tr>
                <td colSpan={7}>&nbsp;</td>
              </tr>

            </tfoot>}

            {doc.type !== 2 &&
            <tfoot>
              <tr>
                <td className="total pt" colSpan={(collapseIn ? 3 : 10)}>Итого</td>
                <td className="pt text-end bottom-data"><CurrencyText val={doc.inTotalWithVAT} currencyId={window.defaultCurrencyId} /></td>
                {!collapseAnalytics && <>
                  {!collapseIn && <td colSpan={4}></td>}
                <td className="pt text-end bottom-data"><CurrencyText val={doc.inTotal} currencyId={window.defaultCurrencyId} /></td>
                <td className="pt text-end bottom-data"><CurrencyText val={doc.costRub} currencyId={window.defaultCurrencyId} /></td>
                <td className="pt text-end bottom-data"><CurrencyText val={doc.left} currencyId={window.defaultCurrencyId} /></td>
                <td className="pt text-end bottom-data"><CurrencyText val={doc.leftVat} currencyId={window.defaultCurrencyId} /></td>
                <td className="pt text-end bottom-data"><CurrencyText val={doc.costWithVatRub} currencyId={window.defaultCurrencyId} /></td>
                <td className="pt text-end bottom-data"><CurrencyText val={doc.reportsWithVatRub} currencyId={window.defaultCurrencyId} /></td>

                </>}
              </tr>
            </tfoot>}

      </table>

      </div>
    </div>
    }
    </>;
      break;
    case i18n.t('Файлы'):
      innerData = <>
      <DocumentFiles items={docFiles} documentId={doc.documentid} editing={enableEdit && editing} loadFilesCallBack={loadFilesCallBack} />
      </>;
      break;
    case i18n.t('Подписи'):
      innerData = <>
      <DocumentSigns activeSigns={docSigns} historySigns={docSignsHistory}
      documentId={doc.documentid} editing={editing}
      version={doc.version} canSign={canSign} canReject={canReject} canRedirect={canRedirect} 
      user4Redirect={user4Redirect} onRefresh={() => {loadData();}} onStartSign={setStartSign}
      />
      </>;
      break;
    default:
      break;
  }

  const exportExcel = () => {
    http.GetEstimateExcel("estimate", "exportExcel", "estimateid", doc.id+"", (response) => {
      const link = document.createElement("a");
      const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
      link.href = objectUrl;
      link.download =  doc.id+'.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setTimeout(() => window.URL.revokeObjectURL(objectUrl), 5000);
    });
  }

  const renderToggle = (p, r) => {
    return <button type="button" className="btn btn-dark btn-sm btn-mini-secondary" {...p} ref={r}></button>
  }

  const percent = startSign ? 50 : (
  (dicts == {} ? 0 : 15) + (loadingData ? 0 : 20) + ((loadingBodyData && canSee('table_data')) ? 0 : 35) + (loadingSigns ? 0 : 10) + ((loadingFiles && canSee('ATTACHEMENTS')) ? 0 : 10) + (loadingSignsHistory ? 0 : 10));

  return <>
    {percent < 100 && <Progress.Line percent={percent} strokeColor={progressColor} status={null} showInfo={false} strokeWidth={5} />}
    <Modal open={showConfirmCancel} onClose={() => {setShowConfirmCancel(false);}}>
        <Modal.Header>
          <Modal.Title>Отмена изменений</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        У вас есть несохраненные данные. Отменить все изменения?
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-dark" onClick={() => {
            window.changed = false;
            setChanged(false);
            setShowConfirmCancel(false);
            setEditing(false);
            if(doc.id === 0)
            {
              navigate("/estimates");
            } else {    
              loadData();
            }
            }}>Да</button>&nbsp;&nbsp;&nbsp;
          <button type="button" className="btn btn-outline-dark" onClick={() => {setShowConfirmCancel(false);}}>Нет</button>
        </Modal.Footer>
    </Modal>

    <Modal open={showConfirmSave} onClose={() => {setShowConfirmSave(false);}}>
        <Modal.Header>
          <Modal.Title>Смена статуса документа</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        После сохранения документ сменит статус!
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-dark" onClick={() => {
            save();
            setShowConfirmSave(false);
            }}>Да</button>&nbsp;&nbsp;&nbsp;
          <button type="button" className="btn btn-outline-dark" onClick={() => {setShowConfirmSave(false);}}>Нет</button>
        </Modal.Footer>
    </Modal>

    <DocumentHeader tabs={tabs} onTabChange={tabChanged} editing={editing} hasChanges={changed} listName={i18n.t('Сметы')} listLink="/estimates" docTitle={i18n.t('Смета')} onEditClicked={onEditClicked} onSaveClicked={onSaveClicked} status={(docDetails ?? {}).statusname} hasCancel={true} enableDelete={((docDetails ?? {enableDelete: false}).enableDelete) && canEdit('DELETABLE')} documentid={doc.documentid} enableEdit={enableEdit} currentTab={currentTab} 
    newDoc={(doc!==undefined && doc['id'] !==undefined && doc.id === 0)}
    newDocTitle='Новая смета'
    lock={docLocked}
    >
      {!editing && docLocked['status'] !== undefined && docLocked.status && docLocked.userId === window.user.userid &&
      <button type="button" className="btn btn-dark btn-sm btn-mini-secondary" style={{backgroundImage: 'url('+unlockImage+')', padding: 6, backgroundOrigin: "content-box"}}
      onMouseEnter={() => {setUnlockHover(true)}}
      onMouseLeave={() => {setUnlockHover(false)}}
      onClick={() => {
        http.UnlockDocument(doc.documentid, (resp) => {
          if(resp.data.errorcode === "00")
          {
            checkLock();
          }
      });
      }}
      />}
      
      {canSee("PRINTABLE") && <Dropdown renderToggle={renderToggle} placement="bottomEnd">
            <Dropdown.Item>PDF</Dropdown.Item>
            <Dropdown.Item onClick={exportExcel}>Excel</Dropdown.Item>
      </Dropdown>}
    </DocumentHeader>
    {innerData}
  </>;
}