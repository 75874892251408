import * as React from "react";
import {useState, useEffect} from "react";
import i18n from "../../utils/Locale.js";
import Http from "../../utils/Http.js";
import Tabs from "../list/Tabs.js";

export default function DocumentSignsActive(props) {
    const items = props.items ?? [];
    const documentId = props.documentId ?? '';
    const http = Http.GetInstance();

    return <table className="table border-bottom">
        <thead>
            <tr>
                <th className="text-secondary fw-normal">Роль</th>
                <th className="text-secondary fw-normal">Сотрудник</th>
                <th className="text-secondary fw-normal">Подпись</th>
                <th className="text-secondary fw-normal">Дата</th>
            </tr>
        </thead>
        <tbody>
        {items.map((item,i) => {
            return <tr key={"docas"+i}>
                <td>{(item.rolename ?? item.grantname)}</td>
                <td>
                {(item.names ?? [item.esafull_name]).map((it, j) => {
                    return <span key={"docas"+i+"u"+j}>{it}<br /></span>;
                })}
                </td>
                <td>{item.eaafull_name}</td>
                <td>{item.eaaanswer_data}</td>
            </tr>;
        })}
        </tbody>
        </table>;
}
