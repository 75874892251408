import * as React from "react";
import {useState, useEffect} from "react";
import DocList from "../../components/list/DocList";
import Http from "../../utils/Http.js";
import i18n from "../../utils/Locale";

export default function AgreementsList(props) {
  const [loadingData, setLoadingData] = useState(false);
  const http = Http.GetInstance();

    const [rows, setRows] = useState([]);
    const fields = [
            {name: 'id', caption: i18n.t('№'), extra: true, extraFilterType: 'id'}, 
            {name: 'status_name', caption: i18n.t('Статус')},
            {name: 'description', caption: i18n.t('Наименование'), extra: true},
            {name: 'clients_name', caption: i18n.t('Контрагент'), extra: true, extraFilterType: 'list'},
            {name: 'creator_full_name', caption: i18n.t('Инициатор'), extra: true, extraFilterType: 'list'},
        ];

    const filters = [
          {name: 'Поиск', type: 'search'},
          {name: 'Все статусы', type: 'list', listId: 'status_name', listName: 'status_name', options: []},
          {name: 'From', type: 'period', fieldName: 'creationdate'}
        ];
  
    const getAgreementList = () => {
      if(!loadingData)
      {
        setLoadingData(true);
        http.GetAgreementsList((resp) => {
          if(resp.data.errorcode !== "00")
          {
            alert("Error!");
          } else {
            setRows(resp.data.agreementList.list);
          }
          setLoadingData(false);
        });
      }
    }

    useEffect(() => {
      getAgreementList();
    }, []);
    
    return (<>
        <DocList name={i18n.t("Договоры")} hasRefresh="true" button_name={i18n.t("Новый договор")}
         fields={fields} rows={rows} filters={filters} loading={loadingData} docPrefixLink={"/agreement/"}
         onRefresh={getAgreementList}
         />
    </>
    );
}