import * as React from "react";
import { useState, useId, useEffect } from "react";

import Select from 'react-select';
import infoIcon from "../../resources/images/info-icon.svg";
import i18n from "../../utils/Locale";
import exclamationIcon from "../../resources/images/exclamation-icon.svg";

export default function List(props) {
  const id = useId();

  const cValue = {label: 
    (props.text ?? '') == '' ? (
    (props.value ?? '') == '' ? i18n.t('Все') : props.value) : props.text, value: props.value ?? ''};

  const options = props.options ?? [];
  const items = options.map((item, i) => {
        return {label: item.name, value: item.id }
    });

    const optionHeight = '21px';

    const changedItem = (e) => {
      if(props.onChangedItem != null)
      {
        props.onChangedItem(e.value, e.label, e);
      }
    }

    const itemDisabled = props.disabled ?? false;

    const style = {
        control: (base, state) => ({
          ...base,
          border: state.isFocused ? "1px solid #212529" : "1px solid #ced4da",
          boxShadow: state.isFocused ? 0 : 0,
          height: '31px',
          minHeight: '31px',
          "&:hover": {
            border: state.isFocused ? "1px solid #212529" : "1px solid #ced4da"
          }
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          height: '31px',
          padding: '0 6px'
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: '0px',
        }),        
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: '31px',
        }),

        option: (base, { data, isDisabled, isFocused, isSelected }) => ({
              ...base,
              backgroundColor: isSelected ? "#6C757D" : (isFocused ?  '#E9ECEF': "#FFFFFF"),
              color: isSelected ? '#FFF' : "#212529",
              cursor: isDisabled ? 'not-allowed' : 'default',
              height: optionHeight,
              lineHeight: optionHeight,
              padding: '0px 0px 0px 8px',
              isDisabled: itemDisabled,
          }),
        dropdownIndicator:  (base, state) => ({
        ...base,
        color: '#999999 !important',
        }),
        menuList: (base, state) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
        }),
        menu: (base, state) => ({
            ...base,
            marginTop: 0,
        }),
    };

    const errorClass = props.error ?? false;
    const menuClassName = props.menuPlacement ?? 'bottom' == 'top' ? 'extra-select-top' : ((errorClass ? "error " : "") + 'extra-select');
    const hasRealUnder = ((props.hasUnder ?? false) || (props.under ?? "") != "");

    if(props.caption)
    {
      return (
        <>
        <div>
            <label className="form-label input-caption">{props.caption}
            {props.info && <img src={infoIcon} alt="" className="mx-1 mb-2" />}
            {props.mandatory && <span className="text-danger"> *</span>}
            </label>
        <Select id={id} options={items} key={"l"+props.lkey} placeholder={props.placeholder}
          onChange={(opt) => {changedItem(opt);}}
          components={{ IndicatorSeparator: () => null }}
          styles={style}
          value={cValue}
          menuPlacement={props.menuPlacement}
          classNamePrefix={menuClassName}
          isDisabled={itemDisabled}
          className={"extra-select" + (props.exclamation ? " exclamation" : "")}
        />
          {hasRealUnder &&
            <label htmlFor={id} className={"form-label input-under"+(props.underRed ? " text-danger": " text-secondary")}>{props.under}</label>
          }
        </div>
        </>
      );
    }

    return (
        <Select options={items} key={"l"+props.lkey} placeholder={props.placeholder}
          onChange={(opt) => {changedItem(opt);}}
          components={{ IndicatorSeparator: () => null }}
          styles={style}
          value={cValue}
          menuPlacement={props.menuPlacement}
          classNamePrefix={menuClassName}
          isDisabled={itemDisabled}
          className="extra-select"
        />
    );

}