import * as React from "react";
import { Tooltip, Whisper } from 'rsuite';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus, faSearchMinus, faThumbtack, faAngleDown } from '@fortawesome/free-solid-svg-icons';

export default function EstimateTableHeader({pin, onZoom, onTogglePin, estimateType, company, project, projectList,
  collapseIn, collapseOut, collapseAnalytics, onCollapseIn, onCollapseOut, onCollapseAnalytics }) {

    const zoom = (inc) => {
        if(onZoom)
        {
            onZoom(inc);
        }
    }

    const togglePin = () => {
        if(onTogglePin)
        {
            onTogglePin();
        }
    }

    let margin = 0;
    if(projectList != undefined && project != undefined)
    {
      for(var i=0;i<projectList.length;i++)
      {
        if(projectList[i].id == project)
        {
          let companies = projectList[i].companies;
          for(var j=0;j<companies.length;j++)
          {
            if(companies[j].id == company)
            {
              margin = companies[j].margin * 1;
              break;
            }
          }
          break;
        }
      }
    }
    const toggleCollapseIn = () => {
      onCollapseIn(!collapseIn);
    }
    const toggleCollapseOut = () => {
      onCollapseOut(!collapseOut);
    }
    const toggleCollapseAnalytics = () => {
      onCollapseAnalytics(!collapseAnalytics);
    }
    const tooltip = <Tooltip>{margin}</Tooltip>;
    
    return <thead>
    <tr>
      <th>
        <FontAwesomeIcon icon={faSearchPlus} onClick={() => {zoom(1);}} />
        &nbsp;
        <FontAwesomeIcon icon={faSearchMinus} onClick={() => {zoom(-1);}} />
      </th>
      {estimateType == 2 && <th colSpan={collapseOut ? 2 : 11} className="out text-center">Out
      <FontAwesomeIcon icon={faAngleDown} onClick={toggleCollapseOut} className={"collapse-button " + (collapseOut ? "rotate270" : "rotate90")} />
      </th>}
      <th colSpan={collapseIn ? 3 : 13} className="in text-center">In
      <FontAwesomeIcon icon={faAngleDown} onClick={toggleCollapseIn} className={"collapse-button " + (collapseIn ? "rotate270" : "rotate90")} />
      </th>
      <th colSpan={estimateType == 2 ? (collapseAnalytics ? 2 : 11) : (collapseAnalytics ? 1 : 7)} className="analytics text-center">Analytics
      <FontAwesomeIcon icon={faAngleDown} onClick={toggleCollapseAnalytics} className={"collapse-button " + (collapseAnalytics ? "rotate270" : "rotate90")} />
      </th>
    </tr>
    <tr>
      <th>
        <FontAwesomeIcon icon={faThumbtack} onClick={togglePin} className={ pin ? "rotate45" : ""} />
      </th>
      {estimateType == 2 && <>
        {!collapseOut && <>
        <th className="out">&nbsp;</th>
        <th className="out text-start">Код</th>
        </>}
        <th className="out text-start">Наименование</th>
        {!collapseOut && <>
        <th className="out text-start">Кол-во</th>
        <th className="out text-end">Стоимость<br/>без НДС</th>
        </>}
        <th className="out text-end">Сумма<br/>без НДС</th>
        {!collapseOut && <>
        <th className="out text-end">НДС,%</th>
        <th className="out text-end">НДС</th>
        <th className="out text-end">АК</th>
        <th className="out text-end">НДС АК</th>
        <th className="out">&nbsp;</th>
        </>}
      </>}

      <th className="in">&nbsp;</th>
      {!collapseIn && <>
      <th className="in text-start">Inline #</th>
      <th className="in text-start">Код</th>
      </>}
      <th className="in text-start">Наименование</th>
      {!collapseIn && <>
      <th className="in text-start">Кол-во</th>
      <th className="in text-end">Стоимость<br/>без НДС</th>
      </>}
      <th className="in text-end">Сумма<br/>без НДС</th>
      {!collapseIn && <>
      <th className="in text-end">НДС,%</th>
      <th className="in text-end">НДС</th>
      <th className="in text-end">Сумма с НДС</th>
      <th className="in text-start">Дата платежа</th>
      <th className="in text-start">Плательщик</th>
      <th className="in">&nbsp;</th>
      </>}

      {estimateType == 2 && <>
        {!collapseAnalytics && <>
          <th className="analytics text-end">Цена<br/>без НДС, руб</th>
          <th className="analytics text-end">Сумма <br/>без НДС, руб</th>
          <th className="analytics text-end">
            <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltip}>
              <label className="plan-header">ПЛАН,%</label>
            </Whisper>
          </th>
          <th className="analytics text-end">ПЛАН</th>
          <th className="analytics text-end">ФАКТ,%</th>
          <th className="analytics text-end">ФАКТ</th>
          <th className="analytics text-end">Расход</th>
          <th className="analytics text-end">Остаток</th>
          <th className="analytics text-end">Остаток НДС</th>
          <th className="analytics text-end">Расход с НДС</th>
          <th className="analytics text-end">Отчет с НДС</th>
        </>}
        {collapseAnalytics && <>
          <th className="analytics text-end">ПЛАН</th>
          <th className="analytics text-end">ФАКТ</th>
        </>}
      </>}

      {estimateType == 1 && <>
        {!collapseAnalytics && <>
          <th className="analytics text-end">Цена<br/>без НДС, руб</th>
          <th className="analytics text-end">Сумма <br/>без НДС, руб</th>
          <th className="analytics text-end">Расход</th>
          <th className="analytics text-end">Остаток</th>
          <th className="analytics text-end">Остаток НДС</th>
          <th className="analytics text-end">Расход с НДС</th>
          <th className="analytics text-end">Отчет с НДС</th>
        </>}
      </>}

    </tr>
  </thead>;
}