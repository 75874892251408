import * as React from "react";
import { useId, useState } from 'react';
import searchIcon from "../../resources/images/search-icon.svg";
import infoIcon from "../../resources/images/info-icon.svg";
import exclamationIcon from "../../resources/images/exclamation-icon.svg";
import { NumericFormat } from 'react-number-format';

export default function TextInput(props) {
    const id = useId();
    const spanId = id+'span';
    const spanExclamationId = id+'spanexc';
    const itemType = props.type ?? "text";
    const rows = props.rows ?? 5;

    const focus = () => {
        setFocusOnElement(true, spanId);
        setFocusOnElement(true, spanExclamationId);
    };

    const lostFocus = () => {
        setFocusOnElement(false, spanId);
        setFocusOnElement(false, spanExclamationId);
    };

    const setFocusOnElement = (isFocused, elementId) => {
        let el = document.getElementById(elementId);
        if(el!=null)
        {
            el.classList.remove(isFocused ? "lostfocused" : "focused");
            el.classList.add(isFocused ? "focused" : "lostfocused");
        }
    };

    let inputClass = "form-control form-control-input ";
    if(props.search)
    {
        inputClass += " border-start-0";
    }
    if(props.exclamation)
    {
        inputClass += " border-end-0";
    }

    let onChange = (e) => {
        if(props.onChange!=null)
        {
            props.onChange(e);
        }
    }

    const onChangeNumber = (e) => {
        const {formattedValue, value, floatValue} = e;
        let x = {target: {value: floatValue}};
        onChange(x);
    }

    const onNumberLostFocus = (e) => {
        let v = e.target.value;
        if(v == "")
        {
            v = 0;
        } else {
            v = v*1;
        }
        onChange({target: {value: v}});
    }

    return (
        <div className={'text-input '+(props.extraClass ?? '')}>
            <label htmlFor={id} className="form-label input-caption">{props.caption}
            {props.info && <img src={infoIcon} alt="" className="mx-1 mb-2" />}
            {props.mandatory && <span className="text-danger"> *</span>}
            </label>
            {(props.search || props.exclamation) && <div className="input-group">
                {props.search &&
                    <span className="input-group-text bg-transparent border-end-0 lostfocused px-2" id={spanId}><img src={searchIcon} alt="" /></span>
                }
                {itemType === "textarea" && <textarea className={inputClass}
                            disabled={props.disabled}
                            onChange={onChange}
                            onFocus={focus}
                            onBlur={lostFocus}
                            value={(props.value ?? '')}
                             rows={rows}/>
                        }
                {itemType === "text" &&  <input type="text" className={inputClass}
                    id={id} placeholder={props.placeholder} 
                    disabled={props.disabled}
                    onChange={onChange}
                    onFocus={focus}
                    onBlur={lostFocus}
                    value={(props.value ?? '')}
                />
                }
                {itemType === " number" && <NumericFormat value={props.value} className="form-control"
                                displayType="input" disabled={props.disabled} allowNegative onValueChange={onChangeNumber} />}

                {props.exclamation && <span className="input-group-text bg-transparent border-start-0 lostfocused px-2" id={spanExclamationId}><img src={exclamationIcon} alt="" /></span>}

                </div>
            }
            {!(props.search || props.exclamation ) && itemType === "textarea" && <textarea
             className="form-control"
            disabled={props.disabled}
            onChange={onChange}
            onFocus={focus}
            onBlur={lostFocus}
            value={(props.value ?? '')}
                rows={rows}/>
            }

            {!(props.search || props.exclamation ) && itemType === "text" &&
            <input type="text" className="form-control"
                    id={id} placeholder={props.placeholder}
                    disabled={props.disabled}
                    onChange={onChange}
                    value={(props.value ?? '')}
            />}

            {!(props.search || props.exclamation ) && itemType === "number" && 
                <NumericFormat value={props.value}  className="form-control" disabled={props.disabled}
                 displayType="input" allowNegative onValueChange={onChangeNumber} onBlur={onNumberLostFocus} />}

            {((props.hasUnder ?? false) || (props.under ?? "") != "") &&
                <label htmlFor={id} className={"form-label input-under"+(props.underRed ? " text-danger": " text-secondary")}>{props.under}</label>
            }
        </div>
    );
}
