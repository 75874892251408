import { I18n } from 'i18n-js';

const i18n = new I18n({
    ru: {
        'На подпись': 'На подпись',
        'Все': 'Все',
        'документ': 'документ',
        'документа': 'документа',
        'документов': 'документов',
        'Список пуст': 'Список пуст',
        'Поиск': 'Поиск',
        'Новый проект': 'Новый проект',
        'Проекты': 'Проекты',
        'Проект': 'Проект',
        'Всего': 'Всего',
        'Статус': 'Статус',
        'Договор': 'Договор',
        'Договоры': 'Договоры',
        'Новый договор': 'Новый договор',
        'Cметы': 'Cметы',
        'Новая смета': 'Новая смета',
        'Отменить': 'Отменить',
        'Сохранить': 'Сохранить',
        'Удалить': 'Удалить',
        'Редактировать': 'Редактировать',
        'Данные': 'Данные',
        'Файлы': 'Файлы',
        'Подписи': 'Подписи',
        'Цепочка подписей': 'Цепочка подписей',
        'История подписаний': 'История подписаний',
        'Смета': 'Смета',
        'Сметы': 'Сметы',
        'Клиент / Бренд': 'Клиент / Бренд',
        'Дата': 'Дата',
        'Ответственный': 'Ответственный',
        'Валюта': 'Валюта',
        'Агентство сметы': 'Агентство сметы',
        'Курс (рублей за единицу)': 'Курс (рублей за единицу)',
        'Отчетный год': 'Отчетный год',
        'Отчетный месяц': 'Отчетный месяц',
        'НДС': 'НДС',
        'НДС АК': 'НДС АК',
        'Новая клиентска смета': 'Новая клиентска смета',
        'Новая бюджетная смета': 'Новая бюджетная смета',
        "Загрузка": "Загрузка",
        "Загрузить файл": "Загрузить файл",

        'Менеджер проекта': 'Менеджер проекта',
        'Закрытие проекта': 'Закрытие проекта',
        'Комментарий': 'Комментарий',
        'Норма прибыли': 'Норма прибыли',
        'Руководитель агентства': 'Руководитель агентства',
        'Аккаунт-директор': 'Аккаунт-директор',
        'Проектная группа': 'Проектная группа',
        'Справка': 'Справка',
        'Заместитель': 'Заместитель',

        '№': '№',
        'В ожидании': 'В ожидании',
        'Наименование': 'Наименование',
        'Клиент': 'Клиент',
        'Бренд': 'Бренд',
        'Менеджер': 'Менеджер',
        'Срок действия': 'Срок действия',

        'Контрагент': 'Контрагент',
        'Инициатор': 'Инициатор',
    },
    en: {
        'На подпись': 'To sign',
        'Все': 'All',
        'документ': 'document',
        'документа': 'documents',
        'документов': 'documents',
        'Список пуст': 'Empty list',
        'Поиск': 'Search',
        'Новый проект': 'New project',
        'Проекты': 'Projects',
        'Проект': 'Project',
        'Всего': 'Total',
        'Статус': 'Status',
        'Договор': 'Agreement',
        'Договоры': 'Agreements',
        'Новый договор': 'New agreement',
        'Cметы': 'Estimates',
        'Новая смета': 'New estimate',
        'Менеджер проекта': 'Project manager',
        'Клиент': 'Client',
        'Бренд': 'Brand',
        'Комментарий': 'Comment',
        'Отменить': 'Cancel',
        'Сохранить': 'Save',
        'Удалить': 'Delete',
        'Редактировать': 'Edit',
        'Смета': 'Estimate',
        'Сметы': 'Estimates',
        'Клиент / Бренд': 'Client / Brand',
        'Дата': 'Date',
        'Ответственный': 'Responsible',
        'Новая клиентска смета': 'Новая клиентска смета',
        'Новая бюджетная смета': 'Новая бюджетная смета',
        "Загрузка": "Loading",
        "Загрузить файл": "Upload file",
        'Справка': 'Help',
        'Заместитель': 'Заместитель',

        /* To translate */

        'Валюта': 'Валюта',
        'Агентство сметы': 'Агентство сметы',
        'Курс (рублей за единицу)': 'Курс (рублей за единицу)',
        'Отчетный год': 'Отчетный год',
        'Отчетный месяц': 'Отчетный месяц',
        'НДС': 'НДС',
        'НДС АК': 'НДС АК',

        'Цепочка подписей': 'Цепочка подписей',
        'История подписаний': 'История подписаний',

        '№': '№',
        'В ожидании': 'В ожидании',
        'Наименование': 'Наименование',
        'Менеджер': 'Менеджер',
        'Срок действия': 'Срок действия',
        'Закрытие проекта': 'Закрытие проекта',

        'Контрагент': 'Контрагент',
        'Инициатор': 'Инициатор',

        'Норма прибыли': 'Норма прибыли',
        'Руководитель агентства': 'Руководитель агентства',
        'Аккаунт-директор': 'Аккаунт-директор',
        'Проектная группа': 'Проектная группа',

        'Данные': 'Данные',
        'Файлы': 'Файлы',
        'Подписи': 'Подписи',
        /* /To translate */
    }
});

export default i18n;