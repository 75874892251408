import React from "react";
import CustomLink from "../controls/CustomLink";

export default function SubMenuItem(props){
    const txt = props.name.replace('<br>', '\n');
    return (
        <div className={"nav-item"+(props.active ? " active" : "")}>
            <CustomLink to={props.link} className={"nav-link submenu-item" + (props.active ? " text-danger active" : " text-secondary")}
                    extraAction={() => {props.changeMenu(props.menuIndex, props.submenuIndex)}} 
                    dangerouslySetInnerHTML={{__html: txt}}>
            </CustomLink>
        </div>
    );
}
