import * as React from "react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from 'rsuite';

import i18n from "../../utils/Locale";

export default function CustomLink({to, title, className, customAction, extraAction, dangerouslySetInnerHTML, children}) {

  const [needConfirm, setNeedConfirm] = useState(false);
  const navigate = useNavigate();

  const checkChanges = (e) => {
    e.preventDefault();
    if(window.changed)
    {
      setNeedConfirm(true);
    } else {
      if(customAction)
      {
        customAction();
      } else {
        if(extraAction)
        {
          extraAction();
        }
        navigate(to);
      }
    }
  }

  return <>
      {needConfirm && <Modal open={needConfirm} onClose={() => {setNeedConfirm(false);}}>
        <Modal.Header>
          <Modal.Title>Отмена изменений</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        У вас есть несохраненные данные. Отменить все изменения?
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-dark" onClick={() => {
            window.changed = false;
            if(customAction)
            {
              customAction();
            } else {
              if(extraAction)
              {
                extraAction();
              }
              navigate(to);
            }
            setNeedConfirm(false);
            }}>Да</button>&nbsp;&nbsp;&nbsp;
          <button type="button" className="btn btn-outline-dark" onClick={() => {setNeedConfirm(false);}}>Нет</button>
        </Modal.Footer>
    </Modal>}
    <Link to={to} title={title} onClick={checkChanges} className={className} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>{children}</Link>
  </>;
}