import * as React from "react";
import {useState, useRef, memo} from "react";
import CurrencyText from "../../controls/CurrencyText";
import Line from "./Line";
import PercentText from "../../controls/PercentText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { Tooltip, Whisper, Modal } from 'rsuite';
import DeleteElementButton from "./DeleteElementButton";

// function SubGroup(props) {
const SubGroup = memo(function SubGroup(props) {
    const [collapsed, setCollapsed] = useState(props.collapsed ?? false);
    const [askDeleteSubgroup, setAskDeleteSubgroup] = useState(false);
    const item = props.item;

    const confirmRef = useRef();

    const collapseToggle = () => {
        setCollapsed(!collapsed);
    }

    const onChangeData = (field, val) => {
        if(props.onChangeSubGroupData)
        {
            props.onChangeSubGroupData(item, field, val);
        }
    }

    const onAddLine = () => {
        if(props.onAddLine)
        {
            props.onAddLine(item, null);
        }
    }

    const deleteSubgroup = () => {
        if(props.onDeleteSubgroup)
        {
            props.onDeleteSubgroup(props.group, item);
        }
    }

    const tooltipAddLine = <Tooltip>{"Добавить линию"}</Tooltip>;

    React.useEffect(() => {
        if(askDeleteSubgroup)
        {
            confirmRef.current.focus();
        }
    }, [askDeleteSubgroup]);

    return <>
    <Modal open={askDeleteSubgroup} onClose={() => {setAskDeleteSubgroup(false);}}>
        <Modal.Header>
          <Modal.Title>Подтвердить удаление!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Удалить подраздел?
        </Modal.Body>
        <Modal.Footer>
            <button type="button" ref={confirmRef} className="btn btn-dark" onClick={() => {
            deleteSubgroup();
            setAskDeleteSubgroup(false);
            }}>Удалить</button>&nbsp;&nbsp;&nbsp;
          <button type="button" className="btn btn-outline-dark" onClick={() => {setAskDeleteSubgroup(false);}}>Отменить</button>
        </Modal.Footer>
    </Modal>
    <tr className={"subgroup " + item.analyticsStatus}>
        <td>
            {props.editing && props.canDelete && <>
                        <DeleteElementButton canDelete={(item.costRub ?? 0) == 0} onDelete={() => {
                            setAskDeleteSubgroup(true);
                        }} />
                        &nbsp;
                    </>
            }
            <FontAwesomeIcon icon={ collapsed ? faAngleDown : faAngleUp} onClick={collapseToggle} />
        </td>
        <td colSpan={props.collapseOut ? 1 : (props.estimateType != 2 ? (props.collapseIn ? 2 : 3) : 3)}>
            {props.editing && <input type="text" defaultValue={item.name} onChange={(e)=>{onChangeData('name', e.target.value)}}></input>}
            {!props.editing && <>{item.name}</>}
        </td>
        {!props.collapseOut && props.estimateType == 2 && <>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </>}
        {props.estimateType == 2 && <>
            <td className="text-end"><CurrencyText val={item.totalOut} currency={props.outCurrency} /></td>
            {!props.collapseOut && <>
                <td>&nbsp;</td>
                <td className="text-end"><CurrencyText val={item.outVat} currency={props.outCurrency} /></td>
                <td className="text-end"><CurrencyText val={item.outAK} currency={props.outCurrency} /></td>
                <td className="text-end"><CurrencyText val={item.outAKVat} currency={props.outCurrency} /></td>
                <td>&nbsp;</td>
            </>}
        </>}

        {!props.collapseIn && props.estimateType == 2 && <>
            <td colSpan={6}>&nbsp;</td>
        </>}
        {!props.collapseIn && props.estimateType != 2 && <>
            <td colSpan={3}>&nbsp;</td>
        </>}

        {props.collapseIn && props.estimateType == 2 && <>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </>}
        <td className="text-end"><CurrencyText val={item.totalIn} /></td>
        {!props.collapseIn && <>
            <td>&nbsp;</td>
            <td className="text-end"><CurrencyText val={item.inVat} /></td>
            <td className="text-end"><CurrencyText val={item.inTotalWithVAT} /></td>
            <td colSpan={3}>&nbsp;</td>
        </>}

        {props.estimateType == 2 && <>
            {!props.collapseAnalytics && <>
                <td className="analytics">&nbsp;</td>
                <td className="analytics text-end">
                    <CurrencyText val={item.totalIn} />
                </td>
                <td className="analytics text-end">
                    <PercentText val={item.planPrc} />
                </td>
            </>}
            <td className="analytics text-end">
                <CurrencyText val={item.plan} />
            </td>
            {!props.collapseAnalytics && <td className="analytics text-end">
                    <PercentText val={item.factPrc} />
            </td>}
            <td className="analytics text-end">
                <CurrencyText val={item.fact} />
            </td>
            {!props.collapseAnalytics && <>
                <td className="analytics text-end">
                    <CurrencyText val={item.costRub} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.left} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.leftVat} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.costWithVatRub} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.reportsWithVatRub} />
                </td>
            </>}
        </>}
        {props.estimateType != 2 && <>
            {!props.collapseAnalytics && <>
                <td className="analytics">&nbsp;</td>
                <td className="analytics text-end">
                    <CurrencyText val={item.totalIn} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.costRub} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.left} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.leftVat} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.costWithVatRub} />
                </td>
                <td className="analytics text-end">
                    <CurrencyText val={item.reportsWithVatRub} />
                </td>
            </>}
        </>}
    </tr>
    {!collapsed && item.lines.map((line, i) => {
        return <Line key={props.index+"ln"+i} item={line} index={props.index+"ln"+i} agency_rate={props.agency_rate} editing={props.editing} canDelete={item.lines.length!=1} leList={props.leList} onChangeSublineData={props.onChangeSublineData} subgroup={item} onAddLine={props.onAddLine} onAddInSubline={props.onAddInSubline} onAddOutSubline={props.onAddOutSubline} outCurrency={props.outCurrency} estimateType={props.estimateType} analyticsCalced={props.analyticsCalced} collapseIn={props.collapseIn} collapseOut={props.collapseOut} collapseAnalytics={props.collapseAnalytics} onDeleteLine={props.onDeleteLine} leftScroll={props.leftScroll} canMakeExpense={props.canMakeExpense} checkFailed={props.checkFailed} onDeleteInSubline={props.onDeleteInSubline} onDeleteOutSubline={props.onDeleteOutSubline} />
    })}
    {props.editing && item.lines.length == 0 && <tr>
        <td colSpan={36} className="buttons">
            <div style={{left: props.leftScroll}}>
                <Whisper placement="top" delayOpen={1500} controlId="control-id-hover" trigger="hover" speaker={tooltipAddLine}>
                    <FontAwesomeIcon icon={ faPlusSquare} className="line-button" onClick={onAddLine} />
                </Whisper>
            </div>
        </td>
    </tr>}
    </>
    ;
});

export default SubGroup;
