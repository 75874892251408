import * as React from "react";
import {useState, useRef, memo} from "react";
import CurrencyText from "../../controls/CurrencyText";
import OutSubline from "./OutSubline";
import InSubline from "./InSubline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { Tooltip, Whisper, Modal } from 'rsuite';
import PercentText from "../../controls/PercentText";
import DeleteElementButton from "./DeleteElementButton";

const Line = memo(function Line(props) {
    const [askDeleteLine, setAskDeleteLine] = useState(false);
    const [askDeleteInSubline, setAskDeleteInSubline] = useState(false);
    const [askDeleteOutSubline, setAskDeleteOutSubline] = useState(false);
    const [sublineIndex2Delete, setSublineIndex2Delete] = useState(-1);
    const confirmRef = useRef();

    const item = props.item;
    let inCnt = 0;
    let outCnt = 0;
    item.positions.map(i => {
        if(i['out']!=undefined)
        {
            outCnt++;
        }
        if(i['in']!=undefined)
        {
            inCnt++;
        }
    });

    const onAddLine = () => {
        if(props.onAddLine)
        {
            props.onAddLine(props.subgroup, item);
        }
    }

    const onAddInSubline = () => {
        if(props.onAddInSubline)
        {
            props.onAddInSubline(item);
        }
    }

    const onAddOutSubline = () => {
        if(props.onAddOutSubline)
        {
            props.onAddOutSubline(item);
        }
    }

    const deleteLine = () => {
        if(props.onDeleteLine)
        {
            props.onDeleteLine(props.subgroup, item);
        }
    }

    const deleteInSubline = () => {
        if(props.onDeleteLine)
        {
            props.onDeleteInSubline(props.subgroup, item, sublineIndex2Delete);
        }
    }

    const deleteOutSubline = () => {
        if(props.onDeleteLine)
        {
            props.onDeleteOutSubline(props.subgroup, item, sublineIndex2Delete);
        }
    }

    const tooltipAddLine = <Tooltip>{"Добавить линию"}</Tooltip>;
    const tooltipAddInSubline = <Tooltip>{"Добавить In позицию"}</Tooltip>;
    const tooltipAddOutSubline = <Tooltip>{"Добавить Out позицию"}</Tooltip>;

    const notreported = (item.costWithVatRub!=item.reportsWithVatRub) ? " notreported" : "";

    React.useEffect(() => {
        if(askDeleteLine || askDeleteInSubline || askDeleteOutSubline)
        {
            confirmRef.current.focus();
        }
    }, [askDeleteLine, askDeleteInSubline, askDeleteOutSubline]);

    const hideDeleteModal = () => {
        setAskDeleteLine(false);
        setAskDeleteInSubline(false);
        setAskDeleteOutSubline(false);
    }


    const sublineAccuracyList = [];
    for(let i=0;i<=8;i++)
    {
        sublineAccuracyList.push({id: i, name: i});
    }

    return <>

    <Modal open={(askDeleteLine || askDeleteInSubline || askDeleteOutSubline)} onClose={() => {hideDeleteModal()}}>
        <Modal.Header>
          <Modal.Title>Подтвердить удаление!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Удалить {askDeleteLine ? 'линию' : (askDeleteInSubline ? 'In позицию' : 'Out позицию') }?
        </Modal.Body>
        <Modal.Footer>
            <button type="button" ref={confirmRef} className="btn btn-dark" onClick={() => {
                if(askDeleteLine)
                {
                    deleteLine();
                } else {
                    if(askDeleteInSubline)
                    {
                        deleteInSubline();
                    } else {
                        deleteOutSubline();
                    }
                }
                hideDeleteModal();
            }}>Удалить</button>&nbsp;&nbsp;&nbsp;
          <button type="button" className="btn btn-outline-dark" onClick={() => {hideDeleteModal();}}>Отменить</button>
        </Modal.Footer>
    </Modal>

    {item.positions.map((pos, i) => {
        return <tr className={"line " + item.analyticsStatus} key={props.index+"pos"+i}>
            <td>
            {props.editing && (props.canDelete || i>0) && <DeleteElementButton canDelete={(item.costRub ?? 0) == 0}
                    onDelete={() => {
                        if(i==0)
                        {
                            setAskDeleteLine(true)
                        } else {
                            if(pos.out !== undefined)
                            {
                                setAskDeleteOutSubline(true);
                            } else {
                                setAskDeleteInSubline(true);
                            }
                            setSublineIndex2Delete(i);
                        }
                    }} />
            }&nbsp;{pos.out !== undefined && <>{pos.out.index}</>}
            </td>
            {pos.out !== undefined && props.estimateType == 2 && 
                <OutSubline parentLine={item} item={pos.out} index={props.index+"pos"+i} 
                agency_rate={props.agency_rate} editing={props.editing} outCurrency={props.outCurrency} 
                text={pos.out.item} qnt_formula={pos.out.qnt_formula} amount={pos.out.amount} vat={pos.out.vat} 
                analyticsCalced={props.analyticsCalced} amountTotal={pos.out.amountTotal} collapseIn={props.collapseIn} 
                collapseOut={props.collapseOut} collapseAnalytics={props.collapseAnalytics} 
                qnt_multiplicator={pos.out.qnt_multiplicator} checkFailed={props.checkFailed} 
                options={pos.out.options} accuracyList={sublineAccuracyList}
                />}
            {pos.out === undefined && props.estimateType == 2 && <>
                {pos.queue == 0 && <>
                    <td colSpan={props.collapseOut ? 1 : 5} className="out"></td>
                    <td className="out text-end"><CurrencyText val={item.outTotal} currency={props.outCurrency} /></td>
                    {!props.collapseOut && <>
                        <td className="out"></td>
                        <td className="out text-end"><CurrencyText val={item.outVat} currency={props.outCurrency} /></td>
                        <td className="out text-end"><CurrencyText val={item.outAK} currency={props.outCurrency} /></td>
                        <td className="out text-end"><CurrencyText val={item.outAKVat} currency={props.outCurrency} /></td>
                        <td className="out"></td>
                    </>}
                </>}
                {pos.queue != 0 && <>
                    <td colSpan={props.collapseOut ? 2 : 11} className="out"></td>
                </>}
            </>}
            {pos.in !== undefined && 
            <InSubline parentLine={item} item={pos.in} index={props.index+"pos"+i} editing={props.editing} leList={props.leList} 
            outCurrency={props.outCurrency} text={pos.in.item} amount={pos.in.amount} vat={pos.in.vat} line={item} 
            analyticsCalced={props.analyticsCalced} amountTotal={pos.in.amountTotal} collapseIn={props.collapseIn} 
            collapseOut={props.collapseOut} collapseAnalytics={props.collapseAnalytics} qnt_multiplicator={pos.in.qnt_multiplicator}
             canMakeExpense={props.canMakeExpense} inCnt={inCnt} estimateType={props.estimateType} checkFailed={props.checkFailed} 
             paymentdate={pos.in.paymentdate} le_id={pos.in.le_id} le_name={pos.in.le_name} 
             options={pos.in.options} accuracyList={sublineAccuracyList}
             />}
            {pos.in === undefined && <>
                    {pos.queue == 0 && <>
                        <td colSpan={props.collapseIn ? 2: 6} className="in"></td>
                        <td className="in text-end">
                            <CurrencyText val={item.inTotalRub} />
                        </td>

                        {!props.collapseIn && <>
                            <td className="in text-end"></td>
                            <td className="in text-end">
                                <CurrencyText val={item.inVatRub} />
                            </td>
                            <td className="in text-end">
                                <CurrencyText val={(item.inTotalRub ?? 0)+(item.inVatRub ?? 0)} />
                            </td>
                            <td className="in text-end"></td>
                            <td className="in text-end"></td>
                            <td className="in text-end"></td>
                        </>}

                        {props.collapseAnalytics && <>
                        <td className="analytics text-end">
                            <CurrencyText val={item.plan} />
                        </td>
                        <td className="analytics text-end">
                            <CurrencyText val={item.fact} />
                        </td>
                    </>}
                    {!props.collapseAnalytics && <>

                        <td className="analytics text-end">
                        </td>
                        <td className="analytics text-end">
                            <PercentText val={item.inTotalRub} />
                        </td>
                        <td className="analytics text-end">
                            <PercentText val={item.planPrc} />
                        </td>
                        <td className="analytics text-end">
                            <CurrencyText val={item.plan} />
                        </td>
                        <td className="analytics text-end">
                            <PercentText val={item.factPrc} />
                        </td>
                        <td className="analytics text-end">
                            <CurrencyText val={item.fact} />
                        </td>
                        <td className="analytics text-end">
                            <CurrencyText val={(item.costRub ?? 0)} />
                        </td>
                        <td className="analytics text-end">
                            <CurrencyText val={item.left} />
                        </td>
                        <td className="analytics text-end">
                            <CurrencyText val={item.leftVat} />
                        </td>
                        <td className={"analytics text-end" + notreported}>
                            <CurrencyText val={item.costWithVatRub} />
                        </td>
                        <td className={"analytics text-end" + notreported}>
                            <CurrencyText val={item.reportsWithVatRub} />
                        </td>
                    </>}

                    </>}

                    {pos.queue !== 0 && <>
                        <td colSpan={props.collapseIn ? 2: 6} className="in"></td>
                        <td className="in text-end"></td>

                        {!props.collapseIn && <>
                            <td className="in text-end"></td>
                            <td className="in text-end"></td>
                            <td className="in text-end"></td>
                            <td className="in text-end"></td>
                            <td className="in text-end"></td>
                            <td className="in text-end"></td>
                        </>}
                    </>}

                    {pos.in === undefined && pos.queue !=0 && <>
                        <td colSpan={props.collapseAnalytics ? 2: 11} className="analytics text-end"></td>
                    </>}

                    {pos.in !== undefined && <>
                    {props.collapseAnalytics && <>
                        <td className="analytics text-end">
                            <CurrencyText val={item.plan} />
                        </td>
                        <td className="analytics text-end">
                            <CurrencyText val={item.fact} />
                        </td>
                    </>}
                    {!props.collapseAnalytics && <>

                        <td className="analytics text-end">
                        </td>
                        <td className="analytics text-end">
                            <PercentText val={item.inTotalRub} />
                        </td>
                        <td className="analytics text-end">
                            <PercentText val={item.planPrc} />
                        </td>
                        <td className="analytics text-end">
                            <CurrencyText val={item.plan} />
                        </td>
                        <td className="analytics text-end">
                            <PercentText val={item.factPrc} />
                        </td>
                        <td className="analytics text-end">
                            <CurrencyText val={item.fact} />
                        </td>
                        <td className="analytics text-end">
                            <CurrencyText val={(item.costRub ?? 0)} />
                        </td>
                        <td className="analytics text-end">
                            <CurrencyText val={item.left} />
                        </td>
                        <td className="analytics text-end">
                            <CurrencyText val={item.leftVat} />
                        </td>
                        <td className={"analytics text-end" + notreported}>
                            <CurrencyText val={item.costWithVatRub} />
                        </td>
                        <td className={"analytics text-end" + notreported}>
                            <CurrencyText val={item.reportsWithVatRub} />
                        </td>
                    </>}
                    </>}
            </>
            }
            </tr>
    })}
    {props.editing && <tr>
        <td colSpan={36} className="buttons">
            <div style={{left: props.leftScroll}}>
                {inCnt==1 && props.estimateType == 2 && <Whisper delayOpen={1500} placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltipAddOutSubline}>
                    <FontAwesomeIcon icon={ faPlusSquare} className="out-button" onClick={onAddOutSubline} />
                </Whisper>
                }
                {outCnt==1 && props.estimateType == 2 && <Whisper delayOpen={1500} placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltipAddInSubline}>
                    <FontAwesomeIcon icon={ faPlusSquare} className="in-button" onClick={onAddInSubline} />
                </Whisper>}
                <Whisper placement="top" delayOpen={1500} controlId="control-id-hover" trigger="hover" speaker={tooltipAddLine}>
                    <FontAwesomeIcon icon={ faPlusSquare} className="line-button" onClick={onAddLine} />
                </Whisper>
            </div>
        </td>
    </tr>}

    </>
    ;
}
);

export default Line;
