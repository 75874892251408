import * as React from "react";
import {useState, useEffect} from "react";
import Http from "../utils/Http.js";
import i18n from "../utils/Locale.js";
import { useNavigate } from "react-router-dom";
import DocumentHeader from "../components/document/DocumentHeader.js";

export default function MainPage() {
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState({});

  const navigate = useNavigate();

  const http = Http.GetInstance();

  useEffect(() => {
    http.PostRequest("mdg", {command: "einformationList"}, (resp) => {
      if(resp.data.errorcode !== "00")
      {
        alert("Error!" + resp.data.errortext);
      } else {
        setInfo(resp.data.einformationList);
        setLoading(false);
      }
    });

    }, []);

  return <>
    {!loading && <></>}
  </>;
}